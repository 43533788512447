// library imports
import { Flex, Select } from "antd";
import { useEffect, useState } from "react";

export default function SiteSelection(props) {
  const { siteSelected, setSiteSelected, siteList, loading, menuSelectedKey } =
    props;

  const [filteredSiteList, setFilteredSiteList] = useState([]);

  // if route contains 'Features', need to filter siteList based on the site subscription to different features
  useEffect(() => {
    if (window.location.pathname.split("/").includes("Features")) {
      let url_length = window.location.pathname.split("/").length;
      if (window.location.pathname.split("/")[url_length - 1] == "PPABilling") {
        setFilteredSiteList(siteList.filter((site) => site.PpaBilling));
      } else if (
        window.location.pathname.split("/")[url_length - 1] ==
        "CarbonManagement"
      ) {
        setFilteredSiteList(siteList.filter((site) => site.CarbonManagement));
      } else if (
        window.location.pathname.split("/")[url_length - 1] == "AIPVDiagnosis"
      ) {
        setFilteredSiteList(siteList.filter((site) => site.AiPvDiagnosis));
      } else if (
        window.location.pathname.split("/")[url_length - 1] == "AIBESS"
      ) {
        setFilteredSiteList(siteList.filter((site) => site.AiBess));
      }
    } else {
      setFilteredSiteList(siteList);
    }
  }, [menuSelectedKey, siteList]);

  return (
    <Flex align="center">
      <p className="mr-2">Site:</p>
      <Select
        style={{ minWidth: "calc(100vw - 54px)", textAlign: "left" }}
        loading={loading}
        showSearch
        value={siteSelected}
        options={filteredSiteList}
        optionFilterProp="label"
        labelRender={(value) => <>{value.label}</>}
        onChange={(value) => {
          setSiteSelected(value);
        }}
      />
    </Flex>
  );
}

// component import
import DataTable from "../../common/DataTable";

export default function BESSTable({ data }) {
  const bessTableColumns = [
    {
      title: "State of Charge",
      dataIndex: "soc",
      key: "soc",
    },
    {
      title: "State of Health",
      dataIndex: "soh",
      key: "soh",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Battery Operations",
      dataIndex: "batteryoperation",
      key: "batteryoperation",
    },
    {
      title: "Power",
      dataIndex: "power",
      key: "power",
    },
    {
      title: "Chargeable Energy",
      dataIndex: "chargeableenergy",
      key: "chargeableenergy",
    },
    {
      title: "Dischargeable Energy",
      dataIndex: "dischargeableenergy",
      key: "dischargeableenergy",
    },
  ];

  return <DataTable table_data={data} table_column={bessTableColumns} />;
}

// library imports
import { useState, useEffect } from "react";
// hook imports
import useFetch from "../hooks/useFetch";

export default function useSiteSelection() {
  const [fetchWrapper] = useFetch();

  const [getSiteSelectionLoading, setGetSiteSelectionLoading] = useState(false);

  const [siteList, setSiteList] = useState([]);
  const [siteSelected, setSiteSelected] = useState(null);

  /* API to get site selection */
  function getSiteSelection() {
    setGetSiteSelectionLoading(true);
    fetchWrapper({
      endpoint_url: "layout/getSiteSelection",
      method: "GET",
      set_content_type_header: false,
      onSuccess: (response) => {
        setGetSiteSelectionLoading(false);
        setSiteList(response.data.site_list);
        if (response.data.site_list.length > 0) {
          setSiteSelected(response.data.site_list[0].value);
        }
      },
    });
  }

  useEffect(() => {
    getSiteSelection();
  }, []);

  return [siteSelected, setSiteSelected, siteList, getSiteSelectionLoading];
}

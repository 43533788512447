// library imports
import { useEffect, useState, useContext } from "react";
// hook imports
import useFetch from "../../../hooks/useFetch";
// context imports
import { IndexContext } from "../../../context/IndexContext";
// config imports
import { MQTT_REFRESH_RATE } from "../../../configs/configs";
// component import
import BESSContent from "./BESSContent";

function BESS(props) {
  const { devicePhaseNumberList } = props;

  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  // phase number selected for pv system (power meter)
  const [phaseNumberSelected, setPhaseNumberSelected] = useState(null);

  // device list for bess
  const [deviceList, setDeviceList] = useState([]);

  const [selectedDeviceID, setSelectedDeviceID] = useState();
  const [bessSystemLoading, setBessSystemLoading] = useState(true);
  const [bessDeviceLoading, setBessDeviceLoading] = useState(true);
  const [bessSystemData, setBessSystemData] = useState({});
  const [bessTableData, setBessTableData] = useState([]);
  const [deviceSelectionLoading, setDeviceSelectionLoading] = useState(false);

  const [systemLastUpdate, setSystemLastUpdate] = useState("-");
  const [deviceLastUpdate, setDeviceLastUpdate] = useState("-");

  /** API to get device name for the device selection */
  function getDeviceName() {
    setDeviceSelectionLoading(true);
    fetchWrapper({
      endpoint_url: "system/getDeviceName",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        phase_number: phaseNumberSelected,
      },
      onSuccess: (response) => {
        if (response.data === false) {
          notificationApi.warning({
            message: response.message,
          });
        } else {
          setDeviceList(response.data?.BESS ?? []);
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setDeviceSelectionLoading(false);
      },
    });
  }

  /** API to get Bess device data */
  function getBessDeviceData() {
    fetchWrapper({
      endpoint_url: "system/getBessDeviceData",
      method: "GET",
      set_content_type_header: false,
      params: {
        device_id: selectedDeviceID,
      },

      onSuccess: (response) => {
        setBessTableData(response.data.table);
        setDeviceLastUpdate(response.data.last_update);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setBessDeviceLoading(false);
      },
    });
  }

  /** API to get BESS device data */
  function getBessSystemData() {
    fetchWrapper({
      endpoint_url: "system/getSystemBESS",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setBessSystemData(response.data.system);
        setSystemLastUpdate(response.data.last_update);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setBessSystemLoading(false);
      },
    });
  }

  useEffect(() => {
    if (deviceList && deviceList.length > 0) {
      setSelectedDeviceID(deviceList[0].value);
    } else {
      setBessTableData([]);
    }
  }, [deviceList]);

  useEffect(() => {
    getBessSystemData();

    const timer = setInterval(() => {
      if (siteSelected) {
        getBessSystemData();
      }
    }, MQTT_REFRESH_RATE * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [siteSelected]);

  useEffect(() => {
    if (selectedDeviceID) {
      getBessDeviceData();
    }

    const timer = setInterval(() => {
      if (selectedDeviceID) {
        getBessDeviceData();
      }
    }, MQTT_REFRESH_RATE * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [selectedDeviceID]);

  useEffect(() => {
    if (devicePhaseNumberList && devicePhaseNumberList.length > 0) {
      setPhaseNumberSelected(devicePhaseNumberList[0].key);
    }
  }, [devicePhaseNumberList]);

  useEffect(() => {
    if (phaseNumberSelected && siteSelected) {
      getDeviceName();
    }
  }, [phaseNumberSelected, siteSelected]);

  return (
    <>
      <BESSContent
        devicePhaseNumberList={devicePhaseNumberList}
        deviceList={deviceList}
        systemData={bessSystemData}
        tableData={bessTableData}
        handleDeviceChange={(key) => {
          setSelectedDeviceID(key);
          setBessDeviceLoading(true);
        }}
        bessSystemLoading={bessSystemLoading}
        bessDeviceLoading={bessDeviceLoading}
        deviceSelectionLoading={deviceSelectionLoading}
        selectedDeviceID={selectedDeviceID}
        systemLastUpdate={systemLastUpdate}
        deviceLastUpdate={deviceLastUpdate}
        setPhaseNumberSelected={setPhaseNumberSelected}
      />
    </>
  );
}

export default BESS;

// library imports
import { useEffect, useRef, useContext } from "react";
import { Chart } from "@antv/g2";
import { uniqueId } from "@antv/util";
import { theme } from "antd";
// context imports
import { IndexContext } from "../../context/IndexContext";
// config imports
import {
  DARK_BLUE,
  LIGHT_BLUE,
  LIGHT_LIGHT_BLUE,
  DARK_GREEN,
  LIGHT_GREEN,
  ORANGE,
  DARK_THEME_DARK_BLUE,
} from "../../configs/configs";

export default function _OverallPowerDemandChart(props) {
  const { data_line, data_chart } = props;

  const { subscribeAiBess, isLightTheme } = useContext(IndexContext);

  // theme token
  const { token } = theme.useToken();

  const container = uniqueId("powerdemand-");
  const chartRef = useRef(null);

  let chart_domain = ["PV (Self-Consumption)", "PV (Export)", "TNB", "Load"];
  let chart_range = isLightTheme
    ? [DARK_GREEN, LIGHT_GREEN, DARK_BLUE, ORANGE]
    : [DARK_GREEN, LIGHT_GREEN, DARK_THEME_DARK_BLUE, ORANGE];
  if (subscribeAiBess === true) {
    chart_domain = [
      "PV (Self-Consumption)",
      "PV (Export)",
      "BESS Discharge",
      "BESS Charge",
      "TNB",
      "Load",
    ];
    chart_range = isLightTheme
      ? [
          DARK_GREEN,
          LIGHT_GREEN,
          LIGHT_BLUE,
          LIGHT_LIGHT_BLUE,
          DARK_BLUE,
          ORANGE,
        ]
      : [
          DARK_GREEN,
          LIGHT_GREEN,
          LIGHT_BLUE,
          LIGHT_LIGHT_BLUE,
          DARK_THEME_DARK_BLUE,
          ORANGE,
        ];
  }

  useEffect(() => {
    chartRef.current?.clear();
    const chartContainer = document.getElementById(container);
    chartContainer.innerHTML = "";

    const chart = new Chart({
      container: container,
      width: container.offsetWidth,
      height: container.offsetHeight,
      padding: "auto",
      autoFit: true,
    });

    chart
      .interval()
      .data(data_chart)
      .encode("x", "date")
      .encode("y", "value")
      .encode("color", "type")
      .scale("color", {
        domain: chart_domain,
        range: chart_range,
      })
      .transform({ type: "stackY" })
      .interaction("elementHighlight", { background: true })
      .axis("x", {
        line: true,
        lineStroke: token.colorTextBase,
        tickStroke: token.colorTextBase,
        title: "Time",
        titleFill: token.colorTextBase,
        labelFill: token.colorTextBase,
      })
      .axis("y", {
        line: true,
        lineStroke: token.colorTextBase,
        tickStroke: token.colorTextBase,
        title: "Power Demand (kW)",
        titleFill: token.colorTextBase,
        labelFill: token.colorTextBase,
      })
      .legend({
        color: {
          itemLabelFill: token.colorTextBase,
        },
      })
      .interaction("tooltip", {
        render: (event, { title, items }) => {
          return `<div style="width:150px;">
              <h3 style="padding:0;margin:0">${title}</h3>
              <ul style="padding: 0; margin: 0; list-style: none;">
                ${items
                  .map((d) => {
                    // Format the value with commas and 2 decimal places
                    const formattedValue = d.value
                      ? Number(d.value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : d.value === 0
                      ? Number(d.value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : d.value;
                    return `
                      <li style="display: flex; justify-content: space-between; align-items: center; margin: 4px 0;">
                        <span style="display: inline-flex; align-items: center;">
                          <span style="width: 8px; height: 8px; background-color: ${d.color}; border-radius: 50%; margin-right: 8px;"></span>
                          <span>${d.name}</span>
                        </span>
                        <span>${formattedValue}</span>
                      </li>`;
                  })
                  .join("")}
              </ul>
            </div>`;
        },
      });

    chart
      .line()
      .data(data_line)
      .encode("x", "date")
      .encode("y", "Load")
      .encode("color", "type")
      .encode("shape", "smooth")
      .style("stroke", ORANGE)
      .style("lineWidth", 3);

    chart.render();

    chartRef.current = chart;

    return () => {
      chartRef.current?.destroy();
    };
  }, [data_line, data_chart, token]);

  useEffect(() => {
    const chartContainer = document.getElementById(container);
    const resizeObserver = new ResizeObserver(() => {});

    resizeObserver.observe(chartContainer);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return <div style={{ width: "100%", height: "100%" }} id={container} />;
}

// library imports
import { useEffect, useState } from "react";
import { Form, Input, Flex, Button, Skeleton, Select } from "antd";
// config imports
import { FORM_REQUIRED } from "../../../configs/configs";
// hook imports
import useFetch from "../../../hooks/useFetch";

function DeviceAlarmForm(props) {
  const {
    setIsDeviceAlarmFormModalOpen,
    mode,
    addDeviceAlarm,
    updateDeviceAlarm,
    form,
    submitButtonLoading,
    emailDetailsRetrievalLoading,
    checkUniqueName,
    selectedEditDeviceAlarmID,
    siteID,
  } = props;

  const [fetchWrapper] = useFetch();

  // loading states
  const [loadingDeviceList, setLoadingDeviceList] = useState(false);

  // selection for device
  const [deviceSelected, setDeviceSelected] = useState(null);
  const [deviceList, setDeviceList] = useState([]);

  /* API to get device list */
  function getDeviceList() {
    setLoadingDeviceList(true);
    fetchWrapper({
      method: "GET",
      endpoint_url: "device_alarm_management/getDeviceSelection",
      params: {
        site_id: siteID,
      },
      set_content_type_header: false,
      onSuccess: (response) => {
        setLoadingDeviceList(false);
        setDeviceList(response.data);
      },
    });
  }

  useEffect(() => {
    if (siteID) {
      getDeviceList();
    }
  }, [siteID]);

  return !emailDetailsRetrievalLoading ? (
    <Form
      layout="vertical"
      onFinish={(values) => {
        if (mode === "add") {
          addDeviceAlarm(values);
        } else {
          updateDeviceAlarm(values);
        }
      }}
      form={form}
      autoComplete="off"
    >
      <Form.Item
        label="Device"
        name="DeviceID"
        rules={[{ required: FORM_REQUIRED, message: "Please select device." }]}
      >
        <Select
          value={deviceSelected}
          options={deviceList}
          onChange={(value) => {
            setDeviceSelected(value);
          }}
          loading={loadingDeviceList}
        />
      </Form.Item>

      <Form.Item
        label="Alarm Code"
        name="AlarmCode"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Please input alarm code.",
          },
          {
            validator: (_, value, callback) => {
              checkUniqueName(
                siteID,
                value.trim(),
                mode === "add" ? null : selectedEditDeviceAlarmID,
                callback
              ); // Check if alarm code is unique
            },
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name="Description"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Please input description.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Flex justify="center" gap="8px">
        <Button
          onClick={() => {
            setIsDeviceAlarmFormModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={submitButtonLoading}>
          Submit
        </Button>
      </Flex>
    </Form>
  ) : (
    <Skeleton
      active={true}
      style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
      paragraph={{ rows: 5 }}
    />
  );
}

export default DeviceAlarmForm;

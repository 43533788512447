// library imports
import { Table, Typography, Flex, Empty, Skeleton, Button, theme } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Chart } from "@antv/g2";
import { useEffect, useRef, useState, useContext } from "react";
// configs imports
import {
  DARK_BLUE,
  LIGHT_BLUE,
  LIGHT_LIGHT_BLUE,
  ORANGE,
  DARK_THEME_DARK_BLUE,
} from "../../configs/configs";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";

const { Text } = Typography;

function MdChart(props) {
  const { token } = theme.useToken();
  const { isLightTheme } = useContext(IndexContext);

  const { container, data_line, data_chart } = props;
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = new Chart({
      container: container,
      autoFit: true,
    });

    chart
      .interval()
      .data(data_chart)
      .encode("x", "time")
      .encode("y", "power")
      .encode("color", "name")
      .scale("color", {
        domain: ["BESS Discharge", "BESS Charge", "TNB", "Net Load Demand"],
        range: isLightTheme
          ? [LIGHT_BLUE, LIGHT_LIGHT_BLUE, DARK_BLUE, ORANGE]
          : [LIGHT_BLUE, LIGHT_LIGHT_BLUE, DARK_THEME_DARK_BLUE, ORANGE],
      })
      // Set opacity values conditionally based on the container
      .style("opacity", (d) => {
        if (container === "md_chart_before") {
          if (d.name == "Net Load Demand") {
            return 1;
          } else {
            return 0.6;
          }
        } else {
          if (d.name == "TNB") {
            return 1;
          } else {
            return 0.6;
          }
        }
      })
      .transform({ type: "stackY" })
      // .interaction("elementHighlight", { background: true })
      .interaction("legendFilter", true)
      .axis("x", {
        line: true,
        lineStroke: token.colorTextBase,
        tickStroke: token.colorTextBase,
        title: "Time",
        titleFill: token.colorTextBase,
        labelFill: token.colorTextBase,
      })
      .axis("y", {
        line: true,
        lineStroke: token.colorTextBase,
        tickStroke: token.colorTextBase,
        title: "Power (kW)",
        titleFill: token.colorTextBase,
        labelFill: token.colorTextBase,
      })
      .legend({
        color: {
          itemLabelFill: token.colorTextBase,
        },
      });

    chart
      .line()
      .data(data_line)
      .encode("x", "time")
      .encode("y", "power")
      .encode("color", "name")
      .encode("shape", "smooth")
      .style("stroke", ORANGE)
      // Set opacity values conditionally based on the container
      .style("opacity", () => {
        if (container === "md_chart_before") {
          return 1;
        } else {
          return 0.6;
        }
      })
      .style("lineWidth", 3);

    document.getElementById(container).innerHTML = "";
    chartRef.current = chart;

    chart.render();

    window.onresize = () => {
      chart.render();
    };
  }, [token, container]);

  return <div style={{ width: "100%", height: "300px" }} id={container} />;
}

function MD(props) {
  const {
    mdDateAfterBess,
    setMdDateAfterBess,
    startDateOri,
    endDateOri,
    setMdSummaryData,
    mdSummaryData,
    mdBreakdownData,
    setMdBreakdownData,
    mdSaving,
    setMdSaving,
    mdDateBeforeBess,
    setMdDateBeforeBess,
  } = props;

  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  // startDate is the first day of the month based on left and right caret clicks
  const [startDate, setStartDate] = useState(startDateOri);

  const [loading, setLoading] = useState(true);

  const columns_summary = [
    {
      title: "",
      dataIndex: "Name",
    },
    {
      title: "Before BESS Reduction",
      dataIndex: "Before",
    },
    {
      title: "After BESS Reduction",
      dataIndex: "After",
    },
  ];

  const [mdBarBefore, setMdBarBefore] = useState([]);
  const [mdBarAfter, setMdBarAfter] = useState([]);
  const [mdLineBefore, setMdLineBefore] = useState([]);
  const [mdLineAfter, setMdLineAfter] = useState([]);

  const columns_overall = [
    {
      title: "Date",
      dataIndex: "Date",
      width: "20%",
    },
    {
      title: "Before BESS Reduction",
      dataIndex: "Before",
      width: "40%",
    },
    {
      title: "After BESS Reduction",
      dataIndex: "After",
      width: "40%",
    },
  ];

  /** API to get data related to MD */
  function getMdData() {
    setLoading(true);

    fetchWrapper({
      endpoint_url: "saving_report/getMdData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        start_date: startDate,
      },

      onSuccess: (response) => {
        setMdSummaryData(response.data.md_summary_data);
        setMdBreakdownData(response.data.md_breakdown_data);
        setMdSaving(response.data.md_saving);
        setMdDateBeforeBess(response.data.md_date_before_bess);
        setMdDateAfterBess(response.data.md_date_after_bess);
        setMdBarBefore(response.data.md_bar_before_bess);
        setMdBarAfter(response.data.md_bar_after_bess);
        setMdLineBefore(response.data.md_line_before_bess);
        setMdLineAfter(response.data.md_line_after_bess);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  /** Function to get month and year from startDate so that the chart title can be updated */
  const getMdSavingTitle = () => {
    if (startDate) {
      // Create a new Date object from the startDate
      const dateObj = new Date(startDate);

      return dateObj.toLocaleString("en-US", {
        month: "long", // 'long' gives full month name (e.g., September)
        year: "numeric", // 'numeric' gives 4-digit year
      });
    } else {
      return "";
    }
  };

  /** Function to decrement the month when right caret is clicked */
  function decrementMonth() {
    // Create a Date object from startDate
    let dateObj = new Date(startDate);

    // Increment the month
    dateObj.setMonth(dateObj.getMonth() - 1);
    dateObj.setDate(1);

    // Format the new startDate back to 'YYYY-MM-DD'
    let newStartDate = dateObj.toISOString().split("T")[0]; // Splits to get 'YYYY-MM-DD'

    // Update the startDate to the new value
    setStartDate(newStartDate);
  }

  /** Function to increment the month when right caret is clicked */
  function incrementMonth() {
    // Create a Date object from startDate
    let dateObj = new Date(startDate);

    // Increment the month
    dateObj.setMonth(dateObj.getMonth() + 1);
    dateObj.setDate(1);

    // Format the new startDate back to 'YYYY-MM-DD'
    let newStartDate = dateObj.toISOString().split("T")[0]; // Splits to get 'YYYY-MM-DD'

    // Update the startDate to the new value
    setStartDate(newStartDate);
  }

  useEffect(() => {
    if (startDate && siteSelected) {
      getMdData();
    }
  }, [startDate, siteSelected]);

  useEffect(() => {
    if (startDateOri) {
      setStartDate(startDateOri);
    }
  }, [startDateOri]);

  return (
    <div>
      <Flex justify="space-between" align="center">
        <Button
          icon={
            parseInt(endDateOri.substring(5, 7)) ===
              parseInt(startDateOri.substring(5, 7)) ||
            parseInt(startDate.substring(5, 7)) === 1 ||
            parseInt(startDate.substring(5, 7)) ===
              parseInt(startDateOri.substring(5, 7)) ? (
              <></>
            ) : (
              <CaretLeftOutlined />
            )
          }
          type="text"
          disabled={loading}
          onClick={() => {
            decrementMonth();
          }}
        />

        <h1>Maximum Demand Saving {getMdSavingTitle()}</h1>

        <Button
          icon={
            parseInt(endDateOri.substring(5, 7)) ===
              parseInt(startDateOri.substring(5, 7)) ||
            parseInt(startDate.substring(5, 7)) === 12 ||
            parseInt(startDate.substring(5, 7)) ===
              parseInt(endDateOri.substring(5, 7)) ? (
              <></>
            ) : (
              <CaretRightOutlined />
            )
          }
          type="text"
          disabled={loading}
          onClick={() => {
            incrementMonth();
          }}
        />
      </Flex>
      {startDate ? (
        !loading ? (
          <Flex justify="space-between" wrap={true} gap={8}>
            <div style={{ width: "calc(50vw - 50px)" }}>
              <Flex vertical={true}>
                <h2>Summary of Maximum Demand Data</h2>
                <Table
                  style={{ marginBottom: "12px", width: "100%" }}
                  size="small"
                  columns={columns_summary}
                  dataSource={mdSummaryData}
                  pagination={false}
                  bordered
                  summary={() => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>
                            Total Saving
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1} colSpan={2}>
                            <Text>{mdSaving}</Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
                <h2>Breakdown of Maximum Demand Data</h2>
                <Table
                  size="small"
                  style={{ width: "100%" }}
                  bordered
                  columns={columns_overall}
                  dataSource={mdBreakdownData}
                  pagination={{
                    responsive: true,
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: "bottomRight",
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                />
              </Flex>
            </div>
            <div style={{ width: "calc(50vw - 50px)" }}>
              <Flex vertical={true}>
                <h2 style={{ margin: "0px" }}>
                  In the scenario where BESS is not present
                </h2>
                <p style={{ margin: "0px", marginBottom: "4px" }}>
                  The Maximum Demand would be charged based on net load demand
                  {mdDateBeforeBess && mdDateBeforeBess !== "-"
                    ? ` of ${mdDateBeforeBess} profile`
                    : ""}
                </p>

                {mdBarBefore.length !== 0 || mdLineBefore.length !== 0 ? (
                  <div>
                    <MdChart
                      data_chart={mdBarBefore}
                      data_line={mdLineBefore}
                      container={"md_chart_before"}
                    />
                  </div>
                ) : (
                  <div style={{ width: "100%", height: "100%" }}>
                    <Empty />
                  </div>
                )}
                <h2 style={{ margin: "0px" }}>With BESS peak shaving</h2>
                <p style={{ margin: "0px", marginBottom: "4px" }}>
                  The Maximum Demand is charged based on TNB import
                  {mdDateAfterBess && mdDateAfterBess !== "-"
                    ? ` of ${mdDateAfterBess} profile`
                    : ""}
                </p>
                {mdBarAfter.length !== 0 || mdLineAfter.length !== 0 ? (
                  <div>
                    <MdChart
                      data_chart={mdBarAfter}
                      data_line={mdLineAfter}
                      container={"md_chart_after"}
                    />
                  </div>
                ) : (
                  <div style={{ width: "100%", height: "100%" }}>
                    <Empty />
                  </div>
                )}
              </Flex>
            </div>
          </Flex>
        ) : (
          <div style={{ height: "calc(100vh - 500px)" }}>
            <Skeleton active />
          </div>
        )
      ) : (
        <Empty />
      )}
    </div>
  );
}

export default MD;

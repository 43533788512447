// library imports
import { Table, Flex, Tooltip, Button } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { useEffect, useState, useContext } from "react";
// style imports
import "../../styles/SavingReport/EnergySaving.css";
// configs imports
import { DARK_BLUE, LIGHT_BLUE, DARK_GREEN } from "../../configs/configs";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";

const { Column, ColumnGroup } = Table;

export default function EnergySaving(props) {
  const { startDateOri, endDateOri } = props;

  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  // startDate is the first day of the month based on left and right caret clicks
  const [startDate, setStartDate] = useState(startDateOri);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  // remove all characters from the string except for digits and the decimal point
  // ^ inside brackets [] means "not," so [^\d.] matches any character that is not a digit or a decimal point
  // \d matches any digit (0-9)
  // . represents the decimal point.
  // g is the global flag, meaning it will replace all matches in the string, not just the first one
  const extractNumber = (str) => parseFloat(str.replace(/[^\d.]/g, ""));

  /** API to get data related to energy saving */
  function getEnergySavingData() {
    setLoading(true);

    fetchWrapper({
      endpoint_url: "saving_report/getEnergySavingData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        start_date: startDate,
      },

      onSuccess: (response) => {
        setData(response.data.energy_saving_data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  /** Function to get month and year from startDate so that the chart title can be updated */
  const getEnergySavingTitle = () => {
    if (startDate) {
      // Create a new Date object from the startDate
      const dateObj = new Date(startDate);

      return dateObj.toLocaleString("en-US", {
        month: "long", // 'long' gives full month name (e.g., September)
        year: "numeric", // 'numeric' gives 4-digit year
      });
    } else {
      return "";
    }
  };

  /** Function to decrement the month when right caret is clicked */
  function decrementMonth() {
    // Create a Date object from startDate
    let dateObj = new Date(startDate);

    // Increment the month
    dateObj.setMonth(dateObj.getMonth() - 1);
    dateObj.setDate(1);

    // Format the new startDate back to 'YYYY-MM-DD'
    let newStartDate = dateObj.toISOString().split("T")[0]; // Splits to get 'YYYY-MM-DD'

    // Update the startDate to the new value
    setStartDate(newStartDate);
  }

  /** Function to increment the month when right caret is clicked */
  function incrementMonth() {
    // Create a Date object from startDate
    let dateObj = new Date(startDate);

    // Increment the month
    dateObj.setMonth(dateObj.getMonth() + 1);
    dateObj.setDate(1);

    // Format the new startDate back to 'YYYY-MM-DD'
    let newStartDate = dateObj.toISOString().split("T")[0]; // Splits to get 'YYYY-MM-DD'

    // Update the startDate to the new value
    setStartDate(newStartDate);
  }

  useEffect(() => {
    if (startDate && siteSelected) {
      getEnergySavingData();
    }
  }, [startDate, siteSelected]);

  useEffect(() => {
    if (startDateOri) {
      setStartDate(startDateOri);
    }
  }, [startDateOri]);

  return (
    <div className="energy-recharge">
      <Flex justify="space-between" align="center">
        <Button
          icon={
            parseInt(endDateOri.substring(5, 7)) ===
              parseInt(startDateOri.substring(5, 7)) ||
            parseInt(startDate.substring(5, 7)) === 1 ||
            parseInt(startDate.substring(5, 7)) ===
              parseInt(startDateOri.substring(5, 7)) ? (
              <></>
            ) : (
              <CaretLeftOutlined />
            )
          }
          type="text"
          disabled={loading}
          onClick={() => {
            decrementMonth();
          }}
        />
        <h1>Energy Saving {getEnergySavingTitle()}</h1>
        <Button
          icon={
            parseInt(endDateOri.substring(5, 7)) ===
              parseInt(startDateOri.substring(5, 7)) ||
            parseInt(startDate.substring(5, 7)) === 12 ||
            parseInt(startDate.substring(5, 7)) ===
              parseInt(endDateOri.substring(5, 7)) ? (
              <></>
            ) : (
              <CaretRightOutlined />
            )
          }
          type="text"
          disabled={loading}
          onClick={() => {
            incrementMonth();
          }}
        />
      </Flex>

      <div>
        <Table
          loading={loading}
          style={{ width: "100%" }}
          dataSource={data}
          bordered
          size="small"
          pagination={{
            responsive: true,
            showSizeChanger: true,
            position: "bottomRight",
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        >
          <Column title="Date" dataIndex="date" key="date" />
          <ColumnGroup title="Energy Recharged">
            <ColumnGroup title="Breakdown of Recharged Energy Source">
              <Column
                title="Off-Peak Period"
                dataIndex="off_peak_recharge"
                key="off_peak_recharge"
              />
              <Column
                title="Peak Period"
                dataIndex="peak_recharge"
                key="peak_recharge"
              />
              <Column
                title="Excessive Solar PV"
                dataIndex="pv_recharge"
                key="pv_recharge"
              />
            </ColumnGroup>
            <Column
              title="Total Recharged Energy"
              dataIndex="total_recharge"
              key="total_recharge"
            />
          </ColumnGroup>

          <ColumnGroup title="Energy Discharged" className="energy-discharge">
            <ColumnGroup title="Breakdown of Discharged Energy Source">
              <Column
                title="Off-Peak Period"
                dataIndex="off_peak_discharge"
                key="off_peak_discharge"
              />
              <Column
                title="Peak Period"
                dataIndex="peak_discharge"
                key="peak_discharge"
              />
              <Column
                title="Excessive Solar PV"
                dataIndex="pv_discharge"
                key="pv_discharge"
              />
              <Column
                title={
                  <Flex
                    vertical={false}
                    align="center"
                    justify="center"
                    gap="8px"
                  >
                    <Flex
                      vertical={false}
                      align="center"
                      justify="center"
                      gap={4}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: LIGHT_BLUE,
                          color: "white",
                          fontSize: "10px",
                        }}
                        justify="center"
                        align="center"
                      ></div>
                      <p>Off-Peak</p>
                    </Flex>

                    <Flex
                      vertical={false}
                      align="center"
                      justify="center"
                      gap={4}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: DARK_BLUE,
                          color: "white",
                          fontSize: "10px",
                        }}
                        justify="center"
                        align="center"
                      ></div>
                      <p>Peak</p>
                    </Flex>
                    <Flex
                      vertical={false}
                      align="center"
                      justify="center"
                      gap={4}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: DARK_GREEN,
                          color: "white",
                          fontSize: "10px",
                        }}
                        justify="center"
                        align="center"
                      ></div>
                      <p>PV</p>
                    </Flex>
                  </Flex>
                }
                width={"200px"}
                dataIndex="breakdown_chart"
                key="breakdown_chart"
                render={(text, record) => (
                  <>
                    <Flex vertical={false} align="center" justify="center">
                      <Tooltip
                        title={
                          (
                            (extractNumber(record.peak_discharge) /
                              (extractNumber(record.peak_discharge) +
                                extractNumber(record.pv_discharge) +
                                extractNumber(record.off_peak_discharge))) *
                            100
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + " %"
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flex:
                              extractNumber(record.peak_discharge) /
                              (extractNumber(record.peak_discharge) +
                                extractNumber(record.pv_discharge) +
                                extractNumber(record.off_peak_discharge)),
                            height: "40px",
                            backgroundColor: DARK_BLUE,
                          }}
                        ></div>
                      </Tooltip>
                      <Tooltip
                        title={
                          (
                            (extractNumber(record.pv_discharge) /
                              (extractNumber(record.peak_discharge) +
                                extractNumber(record.pv_discharge) +
                                extractNumber(record.off_peak_discharge))) *
                            100
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + " %"
                        }
                      >
                        <div
                          style={{
                            height: "40px",
                            backgroundColor: DARK_GREEN,
                            flex:
                              extractNumber(record.pv_discharge) /
                              (extractNumber(record.peak_discharge) +
                                extractNumber(record.pv_discharge) +
                                extractNumber(record.off_peak_discharge)),
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title={
                          (
                            (extractNumber(record.off_peak_discharge) /
                              (extractNumber(record.peak_discharge) +
                                extractNumber(record.pv_discharge) +
                                extractNumber(record.off_peak_discharge))) *
                            100
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + " %"
                        }
                      >
                        <div
                          style={{
                            height: "40px",
                            backgroundColor: LIGHT_BLUE,
                            color: "white",
                            flex:
                              extractNumber(record.off_peak_discharge) /
                              (extractNumber(record.peak_discharge) +
                                extractNumber(record.pv_discharge) +
                                extractNumber(record.off_peak_discharge)),
                          }}
                        />
                      </Tooltip>
                    </Flex>
                  </>
                )}
              />
            </ColumnGroup>
            <Column
              title="Total Discharged Energy"
              dataIndex="total_discharge"
              key="total_discharge"
            />
          </ColumnGroup>
          <ColumnGroup title="Saving">
            <Column
              title="Excessive Solar PV Saving"
              dataIndex="pv_saving"
              key="pv_saving"
            />
            <Column
              title="Energy Arbitrage Saving "
              dataIndex="ea_saving"
              key="ea_saving"
            />
          </ColumnGroup>
        </Table>
      </div>
    </div>
  );
}

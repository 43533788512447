// style imports
import "../../styles/Dashboard/PowerEnergyCard.css";
// library imports
import { Segmented, Card, Empty, Skeleton, Flex, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useState, useEffect, useContext } from "react";
// component imports
import { HorizontalDateMonthYearFilter } from "../common/DateFilter";
import _OverallEnergyChart from "./_OverallEnergyChart";
import _OverallPowerDemandChart from "./_OverallPowerDemandChart";
import _PvPowerDemandChart from "./_PvPowerDemandChart";
import _PvEnergyChart from "./_PvEnergyChart";
import CustomLineAreaChart from "../common/CustomLineAreaChart";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";
// config imports
import {
  DARK_BLUE,
  LIGHT_BLUE,
  DARK_GREEN,
  ORANGE,
  DARK_THEME_DARK_BLUE,
} from "../../configs/configs";

const systemTypeOptions = [
  { label: "PV System", value: "pv" },
  { label: "Overall", value: "overall" },
];

const powerEnergyDateTypeOptions = [
  { label: "Day", value: "day" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

const powerTrendTypeOptions = [
  { label: "Power", value: "power" },
  { label: "Power Demand", value: "powerDemand" },
];

export default function PowerEnergyCard({
  className,
  powerEnergyDateSelection,
  setPowerEnergyDateSelection,
  powerEnergyDateTypeSelection,
  setPowerEnergyDateType,
}) {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi, isLightThme } =
    useContext(IndexContext);

  const [systemTypeSelection, setSystemTypeSelection] = useState(
    systemTypeOptions[0].value
  );

  const [powerTrendTypeSelection, setPowerTrendTypeSelection] = useState(
    powerTrendTypeOptions[0].value
  );

  // Chart Data
  // energy chart data
  const [energyChartData, setEnergyChartData] = useState([]);
  const [loadEnergyData, setLoadEnergyData] = useState([]);
  const [energyConsumptionLoading, setEnergyConsumptionLoading] =
    useState(false);

  // power chart data
  const [powerChartData, setPowerChartData] = useState([]);
  const [irradianceData, setIrradianceData] = useState([]);
  const [powerTrendLoading, setPowerTrendLoading] = useState(false);

  // power demand chart data
  const [powerDemandChartData, setPowerDemandChartData] = useState([]);
  const [loadPowerDemandData, setLoadPowerDemandData] = useState([]);
  const [powerDemandLoading, setPowerDemandLoading] = useState(false);

  /** API to get energy trending chart data */
  function getEnergyTrendingChartData() {
    setEnergyConsumptionLoading(true);

    fetchWrapper({
      endpoint_url: "dashboard/getEnergyTrendingChartData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        date_selection: powerEnergyDateTypeSelection,
        date_graph: powerEnergyDateSelection
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        system_type: systemTypeSelection,
      },

      onSuccess: (response) => {
        setEnergyChartData(response.data.energy_trending_data_df);
        setLoadEnergyData(response.data.load_energy_df);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setEnergyConsumptionLoading(false);
      },
    });
  }

  /** API to get power demand trending chart data */
  function getPowerDemandTrendingChartData() {
    setPowerDemandLoading(true);

    fetchWrapper({
      endpoint_url: "dashboard/getPowerDemandTrendingChartData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        date_selection: powerEnergyDateTypeSelection,
        date_graph: powerEnergyDateSelection
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        system_type: systemTypeSelection,
      },

      onSuccess: (response) => {
        setPowerDemandChartData(response.data.power_demand_trending_data_df);
        setLoadPowerDemandData(response.data.load_power_demand_df);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setPowerDemandLoading(false);
      },
    });
  }

  /** API to get power chart data */
  function getPowerChartData() {
    setPowerTrendLoading(true);

    fetchWrapper({
      endpoint_url: "dashboard/getPowerChartData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        date_graph: powerEnergyDateSelection
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        system_type: systemTypeSelection,
      },

      onSuccess: (response) => {
        setPowerChartData(response.data.kWT_df);
        setIrradianceData(response.data.irradiance_df);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setPowerTrendLoading(false);
      },
    });
  }

  // Use Effect
  useEffect(() => {
    if (siteSelected && powerEnergyDateSelection) {
      setEnergyChartData([]);
      setLoadEnergyData([]);
      setPowerDemandChartData([]);
      setLoadPowerDemandData([]);
      getEnergyTrendingChartData();
      getPowerDemandTrendingChartData();

      const timer = setInterval(() => {
        setEnergyChartData([]);
        setLoadEnergyData([]);
        setPowerDemandChartData([]);
        setLoadPowerDemandData([]);
        getEnergyTrendingChartData();
        getPowerDemandTrendingChartData();
      }, 30 * 60 * 1000); // refresh every 30 mins

      return () => {
        clearInterval(timer);
      };
    }
  }, [
    siteSelected,
    powerEnergyDateTypeSelection,
    powerEnergyDateSelection,
    systemTypeSelection,
  ]);

  useEffect(() => {
    if (siteSelected && powerEnergyDateSelection) {
      setPowerChartData([]);
      setIrradianceData([]);
      getPowerChartData();
    }
  }, [siteSelected, powerEnergyDateSelection, systemTypeSelection]);

  return (
    <>
      <Card
        size="small"
        className={className}
        title={
          <div className="power-energy-card-title">
            <Segmented
              options={systemTypeOptions}
              onChange={(value) => {
                setSystemTypeSelection(value);
              }}
              value={systemTypeSelection}
            />
            <div style={{ display: "flex", gap: "5px" }}>
              <HorizontalDateMonthYearFilter
                startDateSelection={powerEnergyDateSelection}
                setStartDateSelection={setPowerEnergyDateSelection}
                onFilter={() => {}}
                pickerType={powerEnergyDateTypeSelection}
                showTitle={false}
              />
              <Segmented
                options={powerEnergyDateTypeOptions}
                onChange={(value) => {
                  setPowerEnergyDateType(value);
                }}
                value={powerEnergyDateTypeSelection}
              />
            </div>
          </div>
        }
      >
        <div className="power-energy-content">
          <Flex justify="space-between">
            <span className="energy-title">Energy Consumption</span>
            <span>
              <Tooltip
                title={
                  "The values of these charts are updated every 30 minutes."
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          </Flex>
          <Card
            size="small"
            className="graph-container"
            bordered={false}
            style={{ height: "40%" }}
          >
            {systemTypeSelection == "pv" ? (
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    display: energyConsumptionLoading
                      ? "none"
                      : energyChartData.length == 0
                      ? "none"
                      : "block",
                    height: "100%",
                  }}
                >
                  <_PvEnergyChart data_chart={energyChartData} />
                </div>
                <Skeleton
                  size="small"
                  active
                  style={{
                    display: energyConsumptionLoading ? "table" : "none",
                  }}
                  paragraph={{ rows: 5 }}
                />
                <Empty
                  style={{
                    padding: "40px 0px",
                    display:
                      energyChartData.length == 0
                        ? energyConsumptionLoading
                          ? "none"
                          : "block"
                        : "none",
                  }}
                />
              </div>
            ) : (
              <div style={{ height: "100%" }}>
                <Empty
                  style={{
                    padding: "40px 0px",
                    display:
                      energyChartData.length == 0
                        ? energyConsumptionLoading
                          ? "none"
                          : "block"
                        : "none",
                  }}
                />
                <div
                  style={{
                    display: energyConsumptionLoading
                      ? "none"
                      : energyChartData.length == 0
                      ? "none"
                      : "block",
                    height: "100%",
                  }}
                >
                  <_OverallEnergyChart
                    data_chart={energyChartData}
                    data_line={loadEnergyData}
                  />
                </div>
                <Skeleton
                  size="small"
                  active
                  style={{
                    display: energyConsumptionLoading ? "table" : "none",
                  }}
                  paragraph={{ rows: 5 }}
                />
              </div>
            )}
          </Card>

          <div>
            <span className="power-title">Power Trends</span>
            {powerEnergyDateTypeSelection === "day" ? (
              <Segmented
                block
                options={powerTrendTypeOptions}
                onChange={(value) => {
                  setPowerTrendTypeSelection(value);
                }}
                value={powerTrendTypeSelection}
                style={{ marginBottom: 10, width: "260px" }}
              />
            ) : (
              <></>
            )}
          </div>
          <Card
            size="small"
            className="graph-container"
            bordered={false}
            style={{ height: "40%" }}
          >
            {powerEnergyDateTypeSelection == "day" &&
            powerTrendTypeSelection === "power" ? (
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    display: powerTrendLoading
                      ? "none"
                      : powerChartData.length == 0
                      ? "none"
                      : "block",
                    height: "100%",
                  }}
                >
                  <CustomLineAreaChart
                    dataLine={powerChartData}
                    xLine="date"
                    yLine="value"
                    colorLine="type1"
                    xAxisTitleLine="Time"
                    yAxisTitleLine="Power (kW)"
                    chartDomainLine={
                      systemTypeSelection === "overall"
                        ? [
                            "Power (TNB)",
                            "Power (PV)",
                            "Power (Load)",
                            "Power (BESS)",
                            "Irradiance",
                          ]
                        : ["Power (PV)", "Irradiance"]
                    }
                    chartRangeLine={
                      systemTypeSelection === "overall"
                        ? [
                            isLightThme ? DARK_BLUE : DARK_THEME_DARK_BLUE,
                            DARK_GREEN,
                            ORANGE,
                            LIGHT_BLUE,
                            "gray",
                          ]
                        : [DARK_GREEN, "gray"]
                    }
                    dataArea={irradianceData}
                    xArea="date"
                    yArea="value"
                    colorArea="type"
                    yAxisTitleArea={`Irradiance (W/m\u00B2)`}
                  />
                </div>

                <Skeleton
                  size="small"
                  active
                  style={{ display: powerTrendLoading ? "table" : "none" }}
                  paragraph={{ rows: 5 }}
                />
                <Empty
                  style={{
                    padding: "40px 0px",
                    display:
                      powerChartData.length == 0
                        ? powerTrendLoading
                          ? "none"
                          : "block"
                        : "none",
                  }}
                />
              </div>
            ) : (
              <div style={{ height: "100%" }}>
                {systemTypeSelection === "pv" ? (
                  <div style={{ height: "100%" }}>
                    <Empty
                      style={{
                        padding: "40px 0px",
                        display:
                          powerDemandChartData.length == 0
                            ? powerDemandLoading
                              ? "none"
                              : "block"
                            : "none",
                      }}
                    />
                    <Skeleton
                      size="small"
                      active
                      style={{
                        display: powerDemandLoading ? "table" : "none",
                      }}
                      paragraph={{ rows: 5 }}
                    />
                    <div
                      style={{
                        display: powerDemandLoading
                          ? "none"
                          : powerDemandChartData.length == 0
                          ? "none"
                          : "block",
                        height: "100%",
                      }}
                    >
                      <_PvPowerDemandChart data_chart={powerDemandChartData} />
                    </div>
                  </div>
                ) : (
                  <div style={{ height: "100%" }}>
                    <div
                      style={{
                        display: powerDemandLoading
                          ? "none"
                          : powerDemandChartData.length == 0
                          ? "none"
                          : "block",
                        height: "100%",
                      }}
                    >
                      <_OverallPowerDemandChart
                        data_chart={powerDemandChartData}
                        data_line={loadPowerDemandData}
                      />
                    </div>
                    <Skeleton
                      size="small"
                      active
                      style={{
                        display: powerDemandLoading ? "table" : "none",
                      }}
                      paragraph={{ rows: 5 }}
                    />
                    <Empty
                      style={{
                        padding: "40px 0px",
                        display:
                          powerDemandChartData.length == 0
                            ? powerDemandLoading
                              ? "none"
                              : "block"
                            : "none",
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </Card>
        </div>
      </Card>
    </>
  );
}

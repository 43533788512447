import { PlusOutlined } from "@ant-design/icons";
import { Button, Image, Space, Upload, message } from "antd";
import { insertCss } from "insert-css";
import { useState } from "react";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function ImageUploader({
  fileList,
  onChange = (fileList) => {},
  maxCount = 1,
  size = 102,
  maxFileSizeMB = 3,
}) {
  insertCss(`
    .ant-upload-list-item-container{
      width: ${size}px !important;
      height: ${size}px !important;
    }

    .ant-upload-select{
      width: ${size}px !important;
      height: ${size}px !important;
    }
    `);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      file.status = "error";
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < maxFileSizeMB;
    if (!isLt2M) {
      file.status = "error";
      message.error(`Image must smaller than ${maxFileSizeMB}MB!`);
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = ({ fileList: newFileList }) => {
    onChange(newFileList);
  };

  const uploadButton = (
    <Button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <Space>
        <PlusOutlined />
        Upload
      </Space>
    </Button>
  );
  return (
    <>
      <Upload
        style={{ width: size, height: size }}
        accept="image/png, image/jpeg"
        listType="picture-card"
        fileList={fileList}
        className="upload-container"
        beforeUpload={beforeUpload}
        onPreview={handlePreview}
        onChange={handleChange}
        maxCount={maxCount}
        customRequest={(options) => {
          options.onSuccess("OK");
        }}
      >
        {fileList?.length >= maxCount ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </>
  );
}

// library imports
import { useState } from "react";
// hook imports
import useFetch from "../hooks/useFetch";

export default function useHasInverter() {
  const [fetchWrapper] = useFetch();

  // loading state
  const [getHasInverterLoading, setGetHasInverterLoading] = useState(false);

  // this state is to check whether the site has inverter
  const [hasInverter, setHasInverter] = useState(false);

  /* API to check whether site has inverter that is selected and not deleted */
  function getHasInverter({ siteSelected }) {
    setGetHasInverterLoading(true);
    fetchWrapper({
      endpoint_url: "layout/getHasInverter",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },
      onSuccess: (response) => {
        setGetHasInverterLoading(false);
        setHasInverter(response.data);
      },
    });
  }

  return [hasInverter, getHasInverter, getHasInverterLoading];
}

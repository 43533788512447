import "../../styles/Common/WordDisplay.css";
import { Tag } from "antd";

export function LabelValueDisplayRow({ label, value }) {
  return (
    <div className="label-value-display-row">
      <span>{label}</span>
      {label === "Status:" ? (
        value !== "-" ? (
          <Tag
            style={{ margin: "0px" }}
            color={value === "Active" ? "green" : "red"}
          >
            {value}
          </Tag>
        ) : (
          <span>{value}</span>
        )
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
}

export function LabelValueUnitDisplayRow({ label, value, unit }) {
  return (
    <div className="label-value-unit-display-row">
      <span className="label-value-unit-display-label">{label}</span>
      <span>
        {value !== "-"
          ? value.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : value}{" "}
        {unit}
      </span>
    </div>
  );
}

// library imports
import { useEffect, useState, useContext } from "react";
import { Card, Skeleton } from "antd";
// style imports
import "../../styles/Dashboard/WeatherCard.css";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";
// file imports
import cloudy_video from "../../assets/cloudy.mp4";
// function imports
import { formatToTwoDecimalPlaces } from "../../functions/formatToTwoDecimalPlaces";

export default function WeatherCard({ className }) {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi, hasWeatherStationSelected } =
    useContext(IndexContext);

  const [weatherForecastLoading, setWeatherForecastLoading] = useState(true);

  const time = new Date()
    .toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .toLowerCase();

  const [temp, setTemp] = useState(null);
  const [weather, setWeather] = useState(null);
  const [location, setLocation] = useState(null);
  const [solar, setSolar] = useState(null);
  const [performanceRatio, setPerformanceRatio] = useState(null);

  function getWeatherForecast() {
    fetchWrapper({
      endpoint_url: "dashboard/getWeatherForecast",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setLocation(response.data.city);
        setWeather(response.data.description);
        setTemp(response.data.temp);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setWeatherForecastLoading(false);
      },
    });
  }

  function getPerformanceRatio() {
    fetchWrapper({
      endpoint_url: "dashboard/getPerformanceRatio",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setSolar(response.data.irradiance_value);
        setPerformanceRatio(response.data.performance_ratio);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  useEffect(() => {
    if (siteSelected) {
      getWeatherForecast();

      if (hasWeatherStationSelected) {
        getPerformanceRatio();
      }

      const timer = setInterval(() => {
        getWeatherForecast();
        if (hasWeatherStationSelected) {
          getPerformanceRatio();
        }
      }, 300000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelected, hasWeatherStationSelected]);

  return (
    <>
      {weatherForecastLoading === false ? (
        <Card size="small" className={className}>
          <div className="weather-card-content">
            <video className="weather-background" autoPlay loop muted>
              <source src={cloudy_video} type="video/mp4"></source>
            </video>
            <span className="weather-location">
              {location ?? "Unknown Site"}
            </span>
            <span className="weather-time">{time ?? " "}</span>
            <span className="weather-temperature">
              {temp ? formatToTwoDecimalPlaces(temp) + "°C" : " "}
            </span>
            <span className="weather-weather">{weather ?? " "}</span>
            {hasWeatherStationSelected ? (
              <span className="weather-solar-irradiance">
                Solar Irradiance:{" "}
                {solar ? (
                  solar !== "-" ? (
                    <>
                      {formatToTwoDecimalPlaces(solar)} W/m<sup>2</sup>
                    </>
                  ) : (
                    "-"
                  )
                ) : solar == 0 ? (
                  formatToTwoDecimalPlaces(solar)
                ) : (
                  "-"
                )}
              </span>
            ) : null}
            {hasWeatherStationSelected ? (
              <span className="weather-performance-ratio">
                Performance Ratio:{" "}
                {performanceRatio
                  ? performanceRatio !== "-"
                    ? formatToTwoDecimalPlaces(performanceRatio) + " %"
                    : "-"
                  : performanceRatio == 0
                  ? formatToTwoDecimalPlaces(performanceRatio)
                  : "-"}
              </span>
            ) : null}
          </div>
        </Card>
      ) : (
        <Card className={className}>
          <Skeleton
            active={true}
            style={{ padding: "0.5rem", maxWidth: "70%", margin: "auto" }}
            paragraph={{ rows: 2 }}
          />
        </Card>
      )}
    </>
  );
}

import {
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Modal,
  Tabs,
  Divider,
  Flex,
  Spin,
  Select,
  Popconfirm,
  Empty,
  Image,
} from "antd";
import { useEffect, useState, useContext } from "react";
import DeviceView from "../device/DeviceView";
import FallBack from "../../../assets/fallback.png";
import SiteForm from "./SiteForm";
import SiteInfo from "./SiteInfo";
import "../../../styles/Configuration/SiteView.css";
import { IndexContext } from "../../../context/IndexContext";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { CONFIGS } from "../../../configs/configs";

const { Text, Title, Paragraph } = Typography;
const { Meta } = Card;

const getImage = (src) => {
  let img;
  try {
    img = require(`../../../assets/site/${src}`);
  } catch {
    img = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
  }
  return img;
};

export default function SiteView(props) {
  const navigate = useNavigate();
  const { organisationNameFilterList, organisationNameSelectionList } = props;

  const [isSiteConfigurationModalOpen, setIsSiteConfigurationModalOpen] =
    useState(false);
  const [isAddSiteModalOpen, setAddSiteModalOpen] = useState(false);
  const [isEditSiteModalOpen, setEditSiteModalOpen] = useState(false);
  const [isSiteDetailsModalOpen, setIsSiteDetailsModalOpen] = useState(false);

  // selection for organisation name
  const [organisationNameSelected, setOrganisationNameSelected] =
    useState("All");

  const [fetchWrapper] = useFetch();

  const [firstLoad, setFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  const [siteDetails, setSiteDetails] = useState([]);

  const [selectedSiteDetails, setSelectedSiteDetails] = useState({});

  const [selectedInfoArea, setSelectedInfoArea] = useState(null); // this selected area is the area which its details will be displayed
  const [selectedInfoSeu, setSelectedInfoSeu] = useState(null); // this selected seu is the seu which its details will be displayed

  // load index context
  const { messageApi, authenticationState } = useContext(IndexContext);

  /* API to get site details */
  function getSiteDetails() {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "site_management/getSiteDetails",
      onSuccess: (fetch_output) => {
        setLoading(false);
        setFirstLoad(false);
        if (fetch_output.status === true) {
          setSiteDetails(fetch_output.site_details);
        }
      },
    });
  }

  /* API to delete site */
  function deleteSite(site_id) {
    setLoading(true);

    fetchWrapper({
      endpoint_url: "site_management/deleteSite",
      body: {
        site_id: site_id,
      },
      onSuccess: (fetch_output) => {
        setLoading(false);

        if (fetch_output.status === true) {
          messageApi.open({
            type: "success",
            content: fetch_output.message,
          });
          getSiteDetails();
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
      },
    });
  }

  useEffect(() => {
    getSiteDetails();
  }, []);

  let filteredSiteDetails =
    authenticationState.user_type === 1
      ? organisationNameSelected === "All"
        ? siteDetails
        : siteDetails.filter(
            (site) => site.UserGroupID === organisationNameSelected
          )
      : siteDetails;

  return (
    <>
      {firstLoad ? (
        <Flex justify="center" align="center" style={{ height: "50vh" }}>
          <Spin />
        </Flex>
      ) : (
        <div style={{ height: "100%" }}>
          <Flex className="mb-3" justify="space-between" align="flex-start">
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setAddSiteModalOpen(true);
              }}
            >
              New Site
            </Button>
            {authenticationState.user_type === 1 ? (
              <Flex justify="space-between" align="center" gap={5}>
                <Text>Organisation :</Text>
                <Select
                  style={{ width: "200px" }}
                  placeholder="Select Organisation"
                  value={organisationNameSelected}
                  options={organisationNameFilterList}
                  onChange={(value) => {
                    setOrganisationNameSelected(value);
                  }}
                />
              </Flex>
            ) : (
              <></>
            )}
          </Flex>
          {loading ? (
            <Flex justify="center" align="center" style={{ height: "50vh" }}>
              <Spin />
            </Flex>
          ) : (
            <div>
              {filteredSiteDetails.length ? (
                <Row gutter={[12, 12]}>
                  {filteredSiteDetails.map((site, index) => (
                    <Col
                      style={{ overflow: "hidden" }}
                      key={index}
                      xs={{ flex: "100%" }}
                      sm={{ flex: "100%" }}
                      md={{ flex: "100%" }}
                      lg={{ flex: "50%" }}
                      xl={{ flex: "50%" }}
                      xxl={{ flex: "25%" }}
                    >
                      <div style={{ height: "100%" }}>
                        <Card
                          classNames={{
                            cover: "mt-4 ml-4 mr-4",
                          }}
                          style={{ height: "100%" }}
                          styles={{ body: { height: 142 } }}
                          cover={
                            <Image
                              alt={site.SiteName}
                              style={{ objectFit: "contain" }}
                              src={
                                site.ImagePath != null
                                  ? `${CONFIGS.backend_url}device_management/getDeviceImage?Path=${site.ImagePath}`
                                  : null
                              }
                              height={200}
                              fallback={FallBack}
                              width="100%"
                              preview={site.ImagePath != null}
                            ></Image>
                          }
                          actions={[
                            <div
                              onClick={() => {
                                setEditSiteModalOpen(true);
                                setSelectedSiteDetails(site);
                              }}
                            >
                              <Tooltip title="Edit">
                                <EditOutlined />
                              </Tooltip>
                            </div>,

                            <Popconfirm
                              title="Delete Site"
                              description={"Are you sure to delete this site?"}
                              onConfirm={() => {
                                deleteSite(site.ID);
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <div style={{ width: "100%" }}>
                                <Tooltip title="Delete"></Tooltip>
                                <DeleteOutlined key="delete" />
                              </div>
                            </Popconfirm>,
                            <div
                              onClick={() => {
                                setSelectedSiteDetails(site);
                                setIsSiteDetailsModalOpen(true);
                              }}
                            >
                              <Tooltip title="View Details">
                                <EyeOutlined />
                              </Tooltip>
                            </div>,
                            <div
                              onClick={() => {
                                navigate(
                                  `/Management/Configuration/Site/${site.ID}`
                                );
                              }}
                            >
                              <Tooltip title="Other configuration">
                                <SettingOutlined />
                              </Tooltip>
                            </div>,
                          ]}
                        >
                          <Meta
                            title={
                              <div
                                style={{
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Title level={4} ellipsis className="mt-3">
                                  {site.SiteName}
                                </Title>
                              </div>
                            }
                            description={
                              <Paragraph
                                ellipsis={{ rows: 2 }}
                                style={{
                                  color: "var(--ant-color-text-description)",
                                  marginBottom: 0,
                                }}
                              >
                                {site.Address}
                              </Paragraph>
                            }
                          />
                        </Card>
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Empty className="mt-4" />
              )}
            </div>
          )}
          <Modal
            title="Add Site"
            open={isAddSiteModalOpen}
            footer={null}
            closable={false}
            destroyOnClose={true}
            maskClosable={false}
            width={1800}
            style={{
              maxWidth: "90vw",
            }}
          >
            <Divider className="mb-4 mt-0"></Divider>
            <SiteForm
              mode="add"
              setIsSiteFormModalOpen={setAddSiteModalOpen}
              refreshCard={getSiteDetails}
              organisationNameSelectionList={organisationNameSelectionList}
            />
          </Modal>

          <Modal
            title="Edit Site"
            open={isEditSiteModalOpen}
            footer={null}
            closable={false}
            destroyOnClose={true}
            maskClosable={false}
            width={1800}
            style={{
              maxWidth: "90vw",
            }}
          >
            <Divider className="mb-4 mt-0"></Divider>
            <SiteForm
              mode="edit"
              setIsSiteFormModalOpen={setEditSiteModalOpen}
              initialValues={selectedSiteDetails}
              refreshCard={getSiteDetails}
              organisationNameSelectionList={organisationNameSelectionList}
            />
          </Modal>

          <Modal
            title="Site Details"
            open={isSiteDetailsModalOpen}
            footer={null}
            closable={false}
            maskClosable={false}
            width={1200}
            style={{ width: "1200px", maxWidth: "90vw" }}
          >
            <Divider className="mb-4 mt-0"></Divider>
            <SiteInfo
              setIsModalOpen={setIsSiteDetailsModalOpen}
              data={selectedSiteDetails}
            />
          </Modal>
        </div>
      )}
    </>
  );
}

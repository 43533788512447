// library import
import { Button, Flex, Form, Select, Skeleton, Typography, Image } from "antd";
import { useEffect, useState, useContext } from "react";
// context import
import { IndexContext } from "../../../context/IndexContext";
// component import
import DataReferenceSourceCheckbox from "./DataReferenceSourceCheckbox";
// image import
import image_consumption from "../../../assets/Load.png";
import image_grid from "../../../assets/Grid.png";
import image_pv from "../../../assets/PV.png";
// hook import
import useFetch from "../../../hooks/useFetch";
// style import
import "../../../styles/Configuration/DataReferenceSourceView.css";

const { Title, Text } = Typography;

// data reference source button
const buttonData = [
  { label: "PV meter", key: "pvMeter" },
  { label: "Inverter", key: "inverter" },
  { label: "Utility Meter", key: "utilityMeter" },
  { label: "Weather Station", key: "weatherStation" },
  { label: "BESS", key: "bess" },
];

export default function DataReferenceSourceView(props) {
  const { siteID } = props;

  const {
    notificationApi,
    getHasInverter,
    getHasInverterSelected,
    getHasWeatherStation,
    getHasWeatherStationSelected,
  } = useContext(IndexContext);
  const [fetchWrapper] = useFetch();

  // loading for the data reference source
  const [dataReferenceSourcesLoading, setDataReferenceSourcesLoading] =
    useState(false);
  // loading for the data reference source device
  const [
    isDataReferenceSourcesDeviceLoading,
    setIsDataReferenceSourcesDeviceLoading,
  ] = useState(false);

  // check which data reference source button is currently active
  const [activeCheckbox, setActiveCheckbox] = useState("pvMeter");

  // selected data reference source device
  const [inverterDevice, setInverterDevice] = useState([]);
  const [pvDevice, setPvMeterDevice] = useState([]);
  const [utilityDevice, setUtilityDevice] = useState([]);
  const [weatherStationDevice, setWeatherStationDevice] = useState([]);
  const [bessDevice, setBessDevice] = useState([]);

  // selected data reference source
  const [
    weatherStationDataReferenceSource,
    setWeatherStationDataReferenceSource,
  ] = useState("N/A");
  const [pvSystemDataReferenceSource, setPvSystemDataReferenceSource] =
    useState("N/A");
  const [utilityDataReferenceSource, setUtilityDataReferenceSource] =
    useState("N/A");
  const [bessDataReferenceSource, setBessDataReferenceSource] = useState("N/A");

  // list of device id and device type id selected, e.g. ['2:2'] means device id 2 and device type 2
  const [pvMeterChecked, setPvMeterChecked] = useState([]);
  const [inverterChecked, setInverterChecked] = useState([]);
  const [utilityChecked, setUtilityChecked] = useState([]);
  const [weatherStationChecked, setWeatherStationChecked] = useState([]);
  const [bessChecked, setBessChecked] = useState([]);

  const [form] = Form.useForm();

  // function to render data reference source checkbox component for each type of devices
  const renderCheckbox = () => {
    if (activeCheckbox === "inverter") {
      return (
        <DataReferenceSourceCheckbox
          data={inverterDevice}
          checkedValues={inverterChecked}
          onChange={setInverterChecked}
          formName="Inverter Form"
        />
      );
    } else if (activeCheckbox === "pvMeter") {
      return (
        <DataReferenceSourceCheckbox
          data={pvDevice}
          checkedValues={pvMeterChecked}
          onChange={setPvMeterChecked}
          formName="PV Meter Form"
        />
      );
    } else if (activeCheckbox === "utilityMeter") {
      return (
        <DataReferenceSourceCheckbox
          data={utilityDevice}
          checkedValues={utilityChecked}
          onChange={setUtilityChecked}
          formName="Utility Form"
        />
      );
    } else if (activeCheckbox === "weatherStation") {
      return (
        <DataReferenceSourceCheckbox
          data={weatherStationDevice}
          checkedValues={weatherStationChecked}
          onChange={setWeatherStationChecked}
          form="Weather Station Form"
        />
      );
    } else if (activeCheckbox === "bess") {
      return (
        <DataReferenceSourceCheckbox
          data={bessDevice}
          checkedValues={bessChecked}
          onChange={setBessChecked}
          form="BESS Form"
        />
      );
    }
  };

  // function to update currently selected button
  const handleButtonClick = (data) => {
    setActiveCheckbox(data);
  };

  // function to reset all the devices to empty list
  const handleReset = () => {
    setPvMeterChecked([]);
    setInverterChecked([]);
    setUtilityChecked([]);
    setWeatherStationChecked([]);
  };

  // function to update the device select
  const handleSubmit = () => {
    updateDeviceSelect();
  };

  /** API to get all devices separated by device type */
  function getAllDeviceSeperateByType() {
    setIsDataReferenceSourcesDeviceLoading(true);

    fetchWrapper({
      endpoint_url:
        "data_reference_source_management/getAllDeviceSeperateByType",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteID,
      },
      onSuccess: (response) => {
        setPvMeterDevice(response.data.PV ?? []);
        setInverterDevice(response.data.INVERTER ?? []);
        setUtilityDevice(response.data.INCOMING ?? []);
        setWeatherStationDevice(response.data.WEATHER ?? []);
        setBessDevice(response.data.BESS ?? []);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setIsDataReferenceSourcesDeviceLoading(false);
      },
    });
  }

  /** API to update the device select */
  function updateDeviceSelect() {
    fetchWrapper({
      method: "POST",
      endpoint_url: "data_reference_source_management/updateDeviceSelect",
      body: {
        device_checked: {
          pv_meter: pvMeterChecked,
          inverter: inverterChecked,
          utility: utilityChecked,
          weather_station: weatherStationChecked,
          bess: bessChecked,
        },
        data_reference_source: {
          pv: pvSystemDataReferenceSource,
          utility: utilityDataReferenceSource,
          weather_station: weatherStationDataReferenceSource,
          bess: bessDataReferenceSource,
        },
        site_id: siteID,
      },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        getAllDeviceSeperateByType();
        getDataReferenceSources();
        getHasInverter({ siteSelected: siteID });
        getHasInverterSelected({ siteSelected: siteID });
        getHasWeatherStation({ siteSelected: siteID });
        getHasWeatherStationSelected({ siteSelected: siteID });
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  /** API to get data reference sources */
  function getDataReferenceSources() {
    setDataReferenceSourcesLoading(true);

    fetchWrapper({
      method: "GET",
      endpoint_url: "data_reference_source_management/getDataReferenceSources",
      set_content_type_header: false,
      params: {
        site_id: siteID,
      },
      onSuccess: (response) => {
        setWeatherStationDataReferenceSource(response.data.weather);
        setPvSystemDataReferenceSource(response.data.pv);
        setUtilityDataReferenceSource(response.data.tnb);
        setBessDataReferenceSource(response.data.bess);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setDataReferenceSourcesLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteID) {
      getAllDeviceSeperateByType();
      getDataReferenceSources();
    }
  }, [siteID]);

  // function to map device selected into a list of device id:device type id, e.g. ["4:3", "5:3"]
  const getSelectedDevices = (devices) =>
    devices
      .filter((device) => device.IsSelected)
      .map((device) => `${device.ID}:${device.DeviceTypeID}`);

  // --------------------- set device selected when device list is changed ---------------------
  useEffect(() => {
    if (pvDevice.length > 0) {
      setPvMeterChecked(getSelectedDevices(pvDevice));
    }
  }, [pvDevice]);

  useEffect(() => {
    if (inverterDevice.length > 0) {
      setInverterChecked(getSelectedDevices(inverterDevice));
    }
  }, [inverterDevice]);

  useEffect(() => {
    if (utilityDevice.length > 0) {
      setUtilityChecked(getSelectedDevices(utilityDevice));
    }
  }, [utilityDevice]);

  useEffect(() => {
    if (weatherStationDevice.length > 0) {
      setWeatherStationChecked(getSelectedDevices(weatherStationDevice));
    }
  }, [weatherStationDevice]);

  useEffect(() => {
    if (bessDevice.length > 0) {
      setBessChecked(getSelectedDevices(bessDevice));
    }
  }, [bessDevice]);

  // -------------------------------------------------------------------

  return (
    <div className="data-reference-content">
      <Form form={form} onFinish={handleSubmit}></Form>
      <div className="left-content">
        <div className="data-reference-image-triangle">
          <div className="data-reference-image-top">
            <Image width={125} src={image_consumption} />
          </div>
          <div className="data-reference-image-left">
            <Image width={125} src={image_grid} />
          </div>
          <div className="data-reference-image-right">
            <Image width={125} src={image_pv} />
          </div>
        </div>
        <div>
          <Title level={4}>Select the Data Reference Sources of:</Title>
          <Flex gap="middle" vertical>
            <Flex
              gap="small"
              align="center"
              wrap="wrap"
              justify="space-between"
            >
              <Text className="label-style">Weather station: </Text>
              <Select
                loading={dataReferenceSourcesLoading}
                style={{ width: 150 }}
                onChange={(value) =>
                  setWeatherStationDataReferenceSource(value)
                }
                value={weatherStationDataReferenceSource}
                options={[
                  {
                    value: "weatherStationYes",
                    label: "Weather Station",
                  },
                  {
                    value: "weatherStationNo",
                    label: "N/A",
                  },
                ]}
              />
            </Flex>
            <Flex
              gap="small"
              align="center"
              wrap="wrap"
              justify="space-between"
            >
              <Text className="label-style">Solar PV System: </Text>
              <Select
                loading={dataReferenceSourcesLoading}
                style={{ width: 150 }}
                onChange={(value) => setPvSystemDataReferenceSource(value)}
                value={pvSystemDataReferenceSource}
                options={[
                  {
                    value: "pvMeterYes",
                    label: "Power Meter",
                  },
                  {
                    value: "inverterYes",
                    label: "Inverter",
                  },
                  {
                    value: "pvSystemNo",
                    label: "N/A",
                  },
                ]}
              />
            </Flex>
            <Flex
              gap="small"
              align="center"
              wrap="wrap"
              justify="space-between"
            >
              <Text className="label-style">Utility Incoming: </Text>
              <Select
                loading={dataReferenceSourcesLoading}
                style={{ width: 150 }}
                onChange={(value) => setUtilityDataReferenceSource(value)}
                value={utilityDataReferenceSource}
                options={[
                  {
                    value: "utilityYes",
                    label: "Utility",
                  },
                  {
                    value: "utilityNo",
                    label: "N/A",
                  },
                ]}
              />
            </Flex>
            <Flex
              gap="small"
              align="center"
              wrap="wrap"
              justify="space-between"
            >
              <Text className="label-style">BESS: </Text>
              <Select
                loading={dataReferenceSourcesLoading}
                style={{ width: 150 }}
                onChange={(value) => setBessDataReferenceSource(value)}
                value={bessDataReferenceSource}
                options={[
                  {
                    value: "bessYes",
                    label: "BESS",
                  },
                  {
                    value: "bessNo",
                    label: "N/A",
                  },
                ]}
              />
            </Flex>
            <Flex
              gap="small"
              align="center"
              wrap="wrap"
              justify="space-between"
            >
              <Text className="label-style">Load Consumption: </Text>
              <Text>Estimation based on generation and incoming</Text>
            </Flex>
          </Flex>

          <div style={{ marginTop: "20px" }}>
            <Text style={{ fontSize: "12px" }}>
              The system accuracy may be subject to inaccuracies due to factors
              such as meter accuracy, environmental conditions, maintenance,
              data processing, and manufacturer specifications, and should be
              used for general guidance only.
            </Text>
          </div>
        </div>
      </div>
      <div className="right-content">
        <Title level={3}>Data Reference Sources:</Title>
        <Flex
          gap="small"
          align="center"
          wrap="wrap"
          style={{ marginBottom: "10px" }}
        >
          {buttonData.map(({ label, key }) => {
            return (
              <Button
                key={key}
                type={activeCheckbox === key ? "primary" : "default"}
                onClick={() => handleButtonClick(key)}
              >
                {label}
              </Button>
            );
          })}
        </Flex>
        <Form form={form} onFinish={handleSubmit}>
          <div
            style={{
              display: "flex",
              border: "1px solid lightgrey",
              borderRadius: "10px",
              width: " 100%",
              height: "60vh",
              justifyContent: "space-between",
            }}
          >
            {isDataReferenceSourcesDeviceLoading ? (
              <Skeleton active style={{ margin: "10px" }} />
            ) : (
              renderCheckbox()
            )}
          </div>
          <Form.Item>
            <div className="button-container">
              <Button htmlType="reset" onClick={() => handleReset()}>
                Reset
              </Button>
              <Button
                htmlType="submit"
                style={{ backgroundColor: "#000038", color: "white" }}
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

import { Row, Col } from "antd";
import "../../styles/Common/FigureDisplay.css";

/**
 * Component: Figure to display a number, units and caption
 */
export function FigureDisplay({ figure, unit, caption }) {
  return (
    <div className="summary-box-margin center-alignment-both-axis">
      <Row className="figure-and-text">
        <Col className="figure">{figure}</Col>
        <Col className="text">{unit}</Col>
      </Row>
      <Row className="text center-alignment-main-axis">{caption}</Row>
    </div>
  );
}

export function DoubleFigureDisplay({
  figure1,
  unit1,
  figure2,
  unit2,
  caption,
}) {
  return (
    <div className="summary-box-margin center-alignment-both-axis">
      <Row className="figure-and-text">
        <Col className="figure">{figure1}</Col>
        <Col className="text">{unit1}</Col>
        <Col
          className="figure"
          style={{ marginLeft: "10px", marginRight: "10px" }}
        >
          /
        </Col>
        <Col className="figure">{figure2}</Col>
        <Col className="text">{unit2}</Col>
      </Row>
      <Row className="text center-alignment-main-axis">{caption}</Row>
    </div>
  );
}

import {
  Button,
  Form,
  Input,
  Flex,
  DatePicker,
  InputNumber,
  Select,
  Skeleton,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";
import { CONFIGS, FORM_REQUIRED } from "../../../configs/configs";
import dayjs from "dayjs";
import { useEffect, useState, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";
import ImageUploader from "../../common/ImageUploader";

const layout = {
  labelCol: {
    span: 24,
    padding: 0,
  },
  wrapperCol: {
    span: 24,
  },
};

function SiteForm(props) {
  const {
    mode,
    setIsSiteFormModalOpen,
    initialValues,
    refreshCard,
    organisationNameSelectionList,
  } = props;

  const [fetchWrapper] = useFetch();

  const [siteForm] = Form.useForm();

  const currentDate = dayjs().format("YYYY-MM-DD");

  // loading components
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);
  const [loadingTariffTypeList, setLoadingTariffTypeList] = useState(false);
  const [loadingConnectionTypeList, setLoadingConnectionTypeList] =
    useState(false);
  const [loadingPvSchemeList, setLoadingPvSchemeList] = useState(false);
  const [fileList, setFileList] = useState([]);

  // selection for organisation name
  const [organisationNameSelected, setOrganisationNameSelected] =
    useState(null);

  // selection for billing date
  const [billingDateSelected, setBillingDateSelected] = useState(null);
  const billingDateList = [...Array(31).keys()].map((i) => {
    return { value: i + 1, label: i + 1 };
  });

  // selection for offset hour
  const [offsetHourSelected, setOffsetHourSelected] = useState(0);
  const offsetHourList = [...Array(48).keys()].map((i) => {
    return { value: i / 2, label: i / 2 };
  });

  // selection for tariff type
  const [tariffTypeSelected, setTariffTypeSelected] = useState(null);
  const [tariffTypeList, setTariffTypeList] = useState([]);

  // selection for connection type
  const [connectionTypeSelected, setConnectionTypeSelected] = useState(null);
  const [connectionTypeList, setConnectionTypeList] = useState([]);

  // selection for pv scheme
  const [pvSchemeSelected, setPvSchemeSelected] = useState(null);
  const [pvSchemeList, setPvSchemeList] = useState([]);

  // load index context
  const { messageApi, authenticationState } = useContext(IndexContext);

  function populateFormData(input) {
    const form_data = new FormData();

    if (input.site_id) {
      form_data.append("site_id", input.site_id);
    }

    if (fileList.length > 0 && fileList[0].originFileObj) {
      form_data.append("image", fileList[0].originFileObj);
    }

    form_data.append("site_name", input.SiteName);
    form_data.append("address", input.Address);
    form_data.append("latitude", input.Latitude);
    form_data.append("longitude", input.Longitude);
    form_data.append("connection_type", input.ConnectionTypeID);
    form_data.append("pv_scheme", input.PVSchemeID);
    form_data.append("site_capacity_in_kwp", input.CapacityInkWp);
    form_data.append(
      "site_commissioning_date",
      input.SiteCommissioningDate.format("YYYY-MM-DD")
    );
    form_data.append("billing_date", input.BillingDate);
    form_data.append("offset_hour", input.OffsetHour);
    form_data.append("tariff_category_id", input.TariffCategoryID);
    form_data.append("pic_name", input.PicName);
    form_data.append("contact_number", input.PicContact);
    form_data.append("email", input.PicEmail);
    form_data.append(
      "user_group_id",
      input.UserGroupID ? input.UserGroupID : null
    );

    return form_data;
  }

  /* API to add site */
  function addSite(user_inputs) {
    setLoadingSubmitButton(true);
    fetchWrapper({
      endpoint_url: "site_management/addSite",
      set_content_type_header: false,
      body: populateFormData(user_inputs),
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          messageApi.open({
            type: "success",
            content: fetch_output.message,
          });
          setIsSiteFormModalOpen(false);
          siteForm.resetFields();
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }

        refreshCard();
      },
      onFinish: () => {
        setLoadingSubmitButton(false);
      },
    });
  }

  /* API to edit site */
  function editSite(user_inputs) {
    setLoadingSubmitButton(true);
    fetchWrapper({
      endpoint_url: "site_management/editSite",
      set_content_type_header: false,
      body: populateFormData({
        ...user_inputs,
        site_id: initialValues.ID,
      }),
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          messageApi.open({
            type: "success",
            content: fetch_output.message,
          });
          setIsSiteFormModalOpen(false);
          siteForm.resetFields();
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
        setLoadingSubmitButton(false);
        refreshCard();
      },
    });
  }

  /* API to check whether site name is unique */
  function checkUniqueSiteName(site_name, callback) {
    fetchWrapper({
      endpoint_url: "site_management/checkUniqueSiteName",
      body: {
        site_id: initialValues ? initialValues.ID : null,
        site_name: site_name ? site_name : "",
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          if (fetch_output.is_unique === true) {
            callback(); // No error, so pass an empty callback
          } else {
            callback(
              "Site name already exists. Please input another site name."
            );
          }
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
      },
    });
  }

  /* API to get tariff type list */
  function getTariffTypeList() {
    setLoadingTariffTypeList(true);
    fetchWrapper({
      method: "GET",
      endpoint_url: "site_management/getTariffTypeList",
      set_content_type_header: false,
      onSuccess: (response) => {
        setLoadingTariffTypeList(false);
        setTariffTypeList(response.data);
      },
    });
  }

  /* API to get connection type list */
  function getConnectionTypeList() {
    setLoadingConnectionTypeList(true);
    fetchWrapper({
      method: "GET",
      endpoint_url: "site_management/getConnectionTypeList",
      set_content_type_header: false,
      onSuccess: (response) => {
        setLoadingConnectionTypeList(false);
        setConnectionTypeList(response.data);
      },
    });
  }

  /* API to get pv scheme list */
  function getPvSchemeList() {
    setLoadingPvSchemeList(true);
    fetchWrapper({
      method: "GET",
      endpoint_url: "site_management/getPvSchemeList",
      set_content_type_header: false,
      onSuccess: (response) => {
        setLoadingPvSchemeList(false);
        setPvSchemeList(response.data);
      },
    });
  }

  // this useEffect is to set the default site commissioning date
  // setting defaultValue = {dayjs(currentDate)} inside DatePicker does not really work
  // (when click 'Submit' button, there will be error saying "Please select the date!")
  useEffect(() => {
    if (mode === "add") {
      siteForm.setFieldValue("SiteCommissioningDate", dayjs(currentDate));
      siteForm.setFieldValue("OffsetHour", 0);
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      if (initialValues) {
        initialValues.SiteCommissioningDate = dayjs(
          initialValues.SiteCommissioningDate
        );

        siteForm.setFieldsValue(initialValues);

        if (initialValues.ImagePath) {
          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: `${CONFIGS.backend_url}device_management/getDeviceImage?Path=${initialValues.ImagePath}`,
            },
          ]);
        }
      }
    }
  }, [mode, initialValues]);

  useEffect(() => {
    getTariffTypeList();
    getConnectionTypeList();
    getPvSchemeList();
  }, []);

  return (
    <>
      {organisationNameSelectionList ? (
        <Form
          clearOnDestroy
          form={siteForm}
          name="site-form"
          {...layout}
          onFinish={(values) => {
            if (mode === "add") {
              addSite(values);
            } else {
              editSite(values);
            }
          }}
          autoComplete="off"
        >
          <div
            style={{
              maxHeight: "calc(75vh - 62px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {authenticationState.user_type === 1 ? (
              <Row gutter={[24, 12]}>
                <Col span={24}>
                  <Form.Item
                    label="Organisation"
                    name="UserGroupID"
                    rules={[
                      {
                        required: FORM_REQUIRED,
                        message: "You must select organisation name.",
                      },
                    ]}
                    tooltip={
                      mode === "edit"
                        ? "Organisation name cannot be changed for now."
                        : ""
                    }
                  >
                    <Select
                      value={organisationNameSelected}
                      options={organisationNameSelectionList}
                      onChange={(value) => {
                        setOrganisationNameSelected(value);
                      }}
                      disabled={mode === "edit"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            <Row gutter={[24, 12]}>
              <Col flex="0 1 300px">
                <Form.Item label="Image" className="mb-0">
                  <ImageUploader
                    size={300}
                    maxCount={1}
                    fileList={fileList}
                    onChange={(files) => {
                      setFileList(files);
                    }}
                  ></ImageUploader>
                </Form.Item>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={{ flex: "1 1 300px" }}
                lg={{ flex: "1 1 300px" }}
                xl={{ flex: "1 1 300px" }}
              >
                <Form.Item
                  label="Name"
                  name="SiteName"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Name cannot be empty.",
                    },
                    {
                      validator: (_, value, callback) => {
                        checkUniqueSiteName(value, callback); // Check if site name is unique if mode is "add"
                      },
                    },
                  ]}
                  validateTrigger="onBlur"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Address"
                  name="Address"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Address cannot be empty.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Latitude ( ° )"
                  name="Latitude"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Latitude cannot be empty.",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={-180} max={180} />
                </Form.Item>

                <Form.Item
                  label="Longitude ( ° )"
                  name="Longitude"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Longitude cannot be empty.",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={-180} max={180} />
                </Form.Item>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={{ flex: "1 1 300px" }}
                lg={{ flex: "1 1 300px" }}
                xl={{ flex: "1 1 300px" }}
              >
                <Form.Item
                  label="Connection Type"
                  name="ConnectionTypeID"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Connection type cannot be empty.",
                    },
                  ]}
                >
                  <Select
                    value={connectionTypeSelected}
                    options={connectionTypeList}
                    onChange={(value) => {
                      setConnectionTypeSelected(value);
                    }}
                    loading={loadingConnectionTypeList}
                  />
                </Form.Item>

                <Form.Item
                  label="Commissioning Date"
                  name="SiteCommissioningDate"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Site commissioning date cannot be empty.",
                    },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  label="Site Capacity in kWp"
                  name="CapacityInkWp"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Site capacity in kWp cannot be empty.",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }}></InputNumber>
                </Form.Item>

                <Form.Item
                  label="PV Scheme"
                  name="PVSchemeID"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "PV scheme cannot be empty.",
                    },
                  ]}
                >
                  <Select
                    value={pvSchemeSelected}
                    options={pvSchemeList}
                    onChange={(value) => {
                      setPvSchemeSelected(value);
                    }}
                    loading={loadingPvSchemeList}
                  />
                </Form.Item>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={{ flex: "1 1 300px" }}
                lg={{ flex: "1 1 300px" }}
                xl={{ flex: "1 1 300px" }}
              >
                <Form.Item
                  label="Tariff Type"
                  name="TariffCategoryID"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Tariff type cannot be empty.",
                    },
                  ]}
                >
                  <Select
                    value={tariffTypeSelected}
                    options={tariffTypeList}
                    onChange={(value) => {
                      setTariffTypeSelected(value);
                    }}
                    loading={loadingTariffTypeList}
                  />
                </Form.Item>

                <Form.Item
                  label="Billing Date"
                  name="BillingDate"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Site billing date cannot be empty.",
                    },
                  ]}
                >
                  <Select
                    value={billingDateSelected}
                    options={billingDateList}
                    onChange={(value) => {
                      setBillingDateSelected(value);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Site Offset Hour"
                  name="OffsetHour"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Site offet hour cannot be empty.",
                    },
                  ]}
                  getValueFromEvent={(value) => {
                    return Math.round(value * 2) / 2;
                  }}
                >
                  <InputNumber
                    step={0.5}
                    min={0}
                    max={23.5}
                    style={{ width: "100%" }}
                  ></InputNumber>
                </Form.Item>
              </Col>
            </Row>
            <Typography.Title level={5} className="mt-2">
              Person In Charge
            </Typography.Title>
            <Divider className="mt-2 mb-3"></Divider>
            <Row gutter={[24, 12]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item
                  label="Name"
                  name="PicName"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Site PIC name cannot be empty.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item
                  label="Contact Number"
                  name="PicContact"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Contact number cannot be empty.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item
                  label="Email"
                  name="PicEmail"
                  rules={[
                    {
                      required: FORM_REQUIRED,
                      message: "Email cannot be empty.",
                    },
                    {
                      type: "email",
                      message: "Please input a valid E-mail.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Flex justify="space-between" className="mt-4">
            <Button
              className="mr-2"
              onClick={() => {
                setIsSiteFormModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingSubmitButton}
            >
              Submit
            </Button>
          </Flex>
        </Form>
      ) : (
        <Skeleton />
      )}
    </>
  );
}

export default SiteForm;

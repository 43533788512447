// library imports
import { useEffect, useState, useContext } from "react";
// hook imports
import useFetch from "../../../hooks/useFetch";
// context imports
import { IndexContext } from "../../../context/IndexContext";
// config imports
import { MQTT_REFRESH_RATE } from "../../../configs/configs";
// component import
import InverterContent from "./InverterContent";
import DeviceInformation from "../common/DeviceInformation";

export default function Inverter(props) {
  const {
    devicePhaseNumberList, // phase number list
    pvSystemCard, // state to show table or card view for data
    handleChange, // to set table or card view for data
    openModal, // state to show modal for device information
    setOpenModal, // to set modal open / close for device information
  } = props;

  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  // phase number selected for pv system (inverter)
  const [phaseNumberSelected, setPhaseNumberSelected] = useState(null);

  // device list for pv system (inverter)
  const [deviceList, setDeviceList] = useState([]);

  // currently selected device ID (to view the table / card data)
  const [selectedDeviceID, setSelectedDeviceID] = useState();

  // loading states
  const [pvSystemLoading, setPvSystemLoading] = useState(true);
  const [pvDeviceLoading, setPvDeviceLoading] = useState(true);
  const [deviceSelectionLoading, setDeviceSelectionLoading] = useState(false);

  // table, card and system data
  const [pvTableData, setPvTableData] = useState([]);
  const [pvCardData, setPVCardData] = useState([]);
  const [systemData, setSystemData] = useState({});

  // last update date and time
  const [systemLastUpdate, setSystemLastUpdate] = useState("-");
  const [deviceLastUpdate, setDeviceLastUpdate] = useState("-");

  /** API to get device name for the device selection */
  function getDeviceName() {
    setDeviceSelectionLoading(true);
    fetchWrapper({
      endpoint_url: "system/getDeviceName",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        phase_number: phaseNumberSelected,
      },
      onSuccess: (response) => {
        if (response.data === false) {
          notificationApi.warning({
            message: response.message,
          });
        } else {
          setDeviceList(response.data?.INVERTER ?? []);
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setDeviceSelectionLoading(false);
      },
    });
  }

  /** API to get PV device data */
  function getPvDeviceData() {
    fetchWrapper({
      endpoint_url: "system/getDeviceData",
      method: "GET",
      set_content_type_header: false,
      params: {
        device_id: selectedDeviceID ? selectedDeviceID : 0,
      },

      onSuccess: (response) => {
        setPvTableData(response.data.table);
        setPVCardData(response.data.card);
        setDeviceLastUpdate(response.data.last_update);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setPvDeviceLoading(false);
      },
    });
  }

  /** API to get PV system data */
  function getSystemPV() {
    fetchWrapper({
      endpoint_url: "system/getSystemPV",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        pv_system: "inverter",
      },

      onSuccess: (response) => {
        setSystemData(response.data.system_data);
        setSystemLastUpdate(response.data.last_update);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setPvSystemLoading(false);
      },
    });
  }

  // use effect to set phase number selected
  useEffect(() => {
    if (devicePhaseNumberList && devicePhaseNumberList.length > 0) {
      setPhaseNumberSelected(devicePhaseNumberList[0].key);
    }
  }, [devicePhaseNumberList]);

  // use effect to get device name when phase number selected and site changes
  useEffect(() => {
    if (phaseNumberSelected && siteSelected) {
      getDeviceName();
    }
  }, [phaseNumberSelected, siteSelected]);

  // use effect to get system data
  // and set card, table and system data to empty list when site changes
  useEffect(() => {
    setPVCardData([]);
    setPvTableData([]);
    setSystemData({});

    if (siteSelected) {
      getSystemPV();

      const timer = setInterval(() => {
        getSystemPV();
      }, MQTT_REFRESH_RATE * 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelected]);

  // use effect to get device data
  useEffect(() => {
    const timer = setInterval(() => {
      getPvDeviceData();
    }, MQTT_REFRESH_RATE * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [selectedDeviceID]);

  // use effect to set selected device id if device list is not empty
  // else set table and card data to empty list
  useEffect(() => {
    if (deviceList && deviceList.length > 0) {
      setSelectedDeviceID(deviceList[0].value);
    } else {
      setPvTableData([]);
      setPVCardData([]);
    }
  }, [deviceList]);

  return (
    <>
      <InverterContent
        devicePhaseNumberList={devicePhaseNumberList}
        deviceList={deviceList}
        pvSystemCard={pvSystemCard}
        handleChange={handleChange}
        setOpenModal={setOpenModal}
        handleDeviceChange={(key) => {
          setSelectedDeviceID(key);
          setPvDeviceLoading(true);
        }}
        pvSystemLoading={pvSystemLoading}
        pvDeviceLoading={pvDeviceLoading}
        deviceSelectionLoading={deviceSelectionLoading}
        selectedDeviceID={selectedDeviceID}
        systemData={systemData}
        pvCardData={pvCardData}
        tableData={pvTableData}
        systemLastUpdate={systemLastUpdate}
        deviceLastUpdate={deviceLastUpdate}
        setPhaseNumberSelected={setPhaseNumberSelected}
      />
      <DeviceInformation
        deviceCardData={pvCardData}
        openModal={openModal}
        setOpenModal={setOpenModal}
        title="Pv System Card"
      />
    </>
  );
}

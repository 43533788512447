import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Typography,
  Flex,
  Modal,
  Popconfirm,
  Form,
  message,
  Table,
} from "antd";
import { useEffect, useState, useContext } from "react";
import { IndexContext } from "../../../context/IndexContext";
import useFetch from "../../../hooks/useFetch";
import EmailForm from "./EmailForm";
import "../../../styles/Configuration/SiteView.css";

const { Title } = Typography;

export default function EmailView(props) {
  const { siteID } = props;
  const [fetchWrapper] = useFetch();
  const { notificationApi } = useContext(IndexContext);

  const [alarmEmailConfigForm] = Form.useForm();

  // modal states
  const [isAddEmailFormModalOpen, setIsAddEmailFormModalOpen] = useState(false);
  const [isEditEmailFormModalOpen, setIsEditEmailFormModalOpen] =
    useState(false);

  const [alarmEmailTableData, setAlarmEmailTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editingAlarmEmailID, setEditingAlarmEmailID] = useState(null);

  // loading states
  const [alarmEmailTableDataLoading, setAlarmEmailTableDataLoading] =
    useState(false);
  const [saveSelectedEmailsLoading, setSaveSelectedEmailsLoading] =
    useState(false);
  const [submitEmailDetailsButtonLoading, setSubmitEmailDetailsButtonLoading] =
    useState(false);
  const [emailDetailsRetrievalLoading, setEmailDetailsRetrievalLoading] =
    useState(false);

  const columns = [
    {
      title: "PIC",
      dataIndex: "PicName",
      key: "PicName",
      width: "40%",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      width: "50%",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "10%",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-start" }}
        >
          <Button
            type="text"
            icon={<EditOutlined style={{ color: "blue" }} />}
            onClick={() => {
              setIsEditEmailFormModalOpen(true);
              getEditingEmail(record.ID);
              setEditingAlarmEmailID(record.ID);
            }}
          />
          <Popconfirm
            title="Delete Email"
            description={"Are you sure to delete this email?"}
            onConfirm={() => {
              deleteEmail(record.ID);
            }}
            onCancel={() => {
              message.success("Action is cancelled");
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  /** API to get all email data*/
  function getAllEmail() {
    setAlarmEmailTableDataLoading(true);

    fetchWrapper({
      endpoint_url: "email_management/getAllEmail",
      method: "GET",
      params: {
        site_id: siteID,
      },
      set_content_type_header: false,
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setSelectedRowKeys(response.data.row_key_list);
        setAlarmEmailTableData(response.data.email_details);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFetchError: (error) => {},
      onFinish: () => {
        setAlarmEmailTableDataLoading(false);
      },
    });
  }

  /** API to add email data*/
  function addEmail(value) {
    setSubmitEmailDetailsButtonLoading(true);

    fetchWrapper({
      endpoint_url: "email_management/addEmail",
      body: {
        site_id: siteID,
        email: value.Email,
        pic: value.PicName,
      },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        alarmEmailConfigForm.resetFields();
        setIsAddEmailFormModalOpen(false);
        getAllEmail();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setSubmitEmailDetailsButtonLoading(false);
      },
    });
  }

  /** API to get email that user choose to edit */
  function getEditingEmail(alarm_email_id) {
    setEmailDetailsRetrievalLoading(true);

    fetchWrapper({
      endpoint_url: "email_management/getEditingEmail",
      method: "GET",
      params: {
        alarm_email_id: alarm_email_id,
      },
      set_content_type_header: false,

      onSuccess: (response) => {
        alarmEmailConfigForm.setFieldsValue(response.data);
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFetchError: (error) => {},
      onFinish: () => {
        setEmailDetailsRetrievalLoading(false);
      },
    });
  }

  /** API to update email that user choose to edit */
  function updateEmail(value) {
    setSubmitEmailDetailsButtonLoading(true);

    fetchWrapper({
      endpoint_url: "email_management/updateEmail",
      body: {
        email_id: editingAlarmEmailID,
        email: value.Email,
        pic: value.PicName,
      },

      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setIsEditEmailFormModalOpen(false);
        alarmEmailConfigForm.resetFields();
        getAllEmail();
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFetchError: (error) => {},
      onFinish: () => {
        setSubmitEmailDetailsButtonLoading(false);
      },
    });
  }

  /** API to delete email that user choose to delete */
  function deleteEmail(deletingAlarmEmailID) {
    fetchWrapper({
      endpoint_url: "email_management/deleteEmail",
      method: "DELETE",
      set_content_type_header: false,
      params: { alarm_email_id: deletingAlarmEmailID },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        getAllEmail();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  /** API to save email that user selects */
  function saveSelectedEmail() {
    setSaveSelectedEmailsLoading(true);

    fetchWrapper({
      endpoint_url: "email_management/saveSelectedEmail",
      body: {
        selected_alarm_email_ids_list: selectedRowKeys,
        site_id: siteID,
      },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        getAllEmail();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => setSaveSelectedEmailsLoading(false),
    });
  }

  /* API to check whether email name is unique */
  function checkUniqueName(SiteID, Name, ID, callback) {
    fetchWrapper({
      endpoint_url: "email_management/checkUniqueEmailName",
      body: {
        site_id: SiteID,
        name: Name,
        email_id: ID,
      },
      onSuccess: (response) => {
        if (!response.data) {
          callback(response.message);
        } else {
          callback();
        }
      },
    });
  }

  useEffect(() => {
    if (siteID) {
      getAllEmail();
    }
  }, [siteID]);

  return (
    <>
      <div>
        <Title level={3} className="mt-0">
          Email
        </Title>
        <Flex justify="flex-start" align="center" wrap={true}>
          <Button
            icon={<PlusOutlined />}
            className="mb-5 mr-2"
            onClick={() => setIsAddEmailFormModalOpen(true)}
          >
            New Email
          </Button>

          <Button
            icon={<SaveOutlined />}
            className="mb-5 mr-2"
            onClick={() => {
              saveSelectedEmail();
            }}
            loading={saveSelectedEmailsLoading}
            emailDetailsRetrievalLoading={emailDetailsRetrievalLoading}
          >
            Save Selected Email
          </Button>
        </Flex>

        <Table
          loading={alarmEmailTableDataLoading}
          columns={columns}
          dataSource={alarmEmailTableData}
          pagination={{
            responsive: true,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: [3, 5, 10, 20, 50, 100],

            position: "bottomRight",
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          size="small"
          rowSelection={{
            ...rowSelection,
            checkStrictly: false,
          }}
        />

        <Modal
          centered
          title="Add New Email "
          open={isAddEmailFormModalOpen}
          footer={null}
          closable={false}
          destroyOnClose={true}
          maskClosable={false}
        >
          <EmailForm
            setIsEmailFormModalOpen={setIsAddEmailFormModalOpen}
            mode="add"
            addEmail={addEmail}
            form={alarmEmailConfigForm}
            submitButtonLoading={submitEmailDetailsButtonLoading}
            checkUniqueName={checkUniqueName}
            siteID={siteID}
          />
        </Modal>

        <Modal
          centered
          title="Edit Email "
          open={isEditEmailFormModalOpen}
          footer={null}
          closable={false}
          destroyOnClose={true}
          maskClosable={false}
        >
          <EmailForm
            setIsEmailFormModalOpen={setIsEditEmailFormModalOpen}
            mode="edit"
            updateEmail={updateEmail}
            form={alarmEmailConfigForm}
            submitButtonLoading={submitEmailDetailsButtonLoading}
            emailDetailsRetrievalLoading={emailDetailsRetrievalLoading}
            checkUniqueName={checkUniqueName}
            selectedEditEmailID={editingAlarmEmailID}
            siteID={siteID}
          />
        </Modal>
      </div>
    </>
  );
}

// library imports
import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
  useReducer,
} from "react";
import {
  theme,
  Button,
  DatePicker,
  Flex,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  Modal,
} from "antd";
import {
  DownloadOutlined,
  SearchOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
// hook imports
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";
// config import
import { MQTT_REFRESH_RATE } from "../../configs/configs";
// component imp
import AcknowledgeAlarmForm from "../../components/common/AcknowledgeAlarmForm";
import AlarmDetailForm from "../../components/alarm/AlarmDetailForm";
// style imports
import "../../styles/Alarm/AlarmPage.css";

const { Title, Text } = Typography;
const { useToken } = theme;

function reducer(state, action) {
  switch (action.type) {
    case "setStartDate":
      return { ...state, startDate: action.date };
    case "setEndDate":
      return { ...state, endDate: action.date };
    case "setDeviceSelected":
      return {
        ...state,
        deviceSelected: action.device,
      };
    default:
      return "Unrecognized command";
  }
}

export default function AlarmPage() {
  const { token } = useToken();
  const tableWrapperViewportHeight = 1;

  const { siteSelected, notificationApi, messageApi, modalApi } =
    useContext(IndexContext);
  const [fetchWrapper] = useFetch();
  const [form] = useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedAlarmView, setSelectedAlarmView] = useState("Active");

  const [deviceList, setDeviceList] = useState([]);

  const [activeAlarmFilter, setActiveAlarmFilter] = useState([]);
  const [historicalAlarmFilter, setHistoricalAlarmFilter] = useState([]);

  // site commissioning date
  const [siteCommissioningDate, setSiteCommissioningDate] = useState(dayjs());

  const initialState = {
    startDate: dayjs(
      Math.max(
        dayjs().hour(0).minute(0).second(0).add(-14, "day").valueOf(),
        dayjs(siteCommissioningDate, "YYYY-MM-DD").valueOf()
      )
    ),
    endDate: dayjs().hour(0).minute(0).second(0).add(1, "day"),
    deviceSelected: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const [firstLoadActiveAlarm, setFirstLoadActiveAlarm] = useState(true);
  const [firstLoadHistoricalAlarm, setFirstLoadHistoricalAlarm] =
    useState(true);

  // data for active alarm table
  const [activeAlarmData, setActiveAlarmData] = useState([]);
  // data for historical alarm table
  const [historicalAlarmData, setHistoricalAlarmData] = useState([]);

  // to open / close acknowledgement modal
  const [isAcknowledgementModalOpen, setIsAcknowledgementModalOpen] =
    useState(false);

  // alarm id if user clicks on the acknowledge button without checking the checkbox
  // (if user clicks the checkbox then acknowledge button, the alarm id(s) will be the selectedRowKeys)
  const [selectedAlarmID, setSelectedAlarmID] = useState(null);
  const [acknowledgeMode, setAcknowledgeMode] = useState("single");

  // for resizeable table
  const [tableHeight, setTableHeight] = useState(600);
  const filterRef = useRef();

  function resizeTable() {
    const node = filterRef.current;
    if (!node) {
      return;
    }
    const { height } = node.getBoundingClientRect();

    setTableHeight(
      window.innerHeight * tableWrapperViewportHeight - height - 285
    );
  }

  function handleStartDate(date) {
    dispatch({ type: "setStartDate", date: date });
  }
  function handleEndDate(date) {
    dispatch({ type: "setEndDate", date: date });
  }
  function handleDeviceChange(device) {
    dispatch({ type: "setDeviceSelected", device: device });
  }

  function acknowledgeAlarm(id, remark, modal) {
    fetchWrapper({
      endpoint_url: "alarm/acknowledgeAlarm",
      body: {
        alarm_ids: id,
        acknowledge_mark: remark,
      },
      onSuccess: (response) => {
        setSelectedRowKeys([]);
        if (selectedAlarmView === "Active") {
          getSelectedSiteActiveAlarm();
        } else {
          getSelectedSiteHistoricalAlarm();
        }
        modal.destroy();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  function onRowClicked(row) {
    const modal = modalApi.confirm();

    modal.update({
      icon: null,
      title: "Alarm Detail",
      width: 600,
      style: { maxWidth: "90vw" },
      centered: true,
      content: <AlarmDetailForm alarm={row} form={form} />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          {row.acknowledged ? (
            <Button
              form="myForm"
              onClick={() =>
                acknowledgeAlarm(row.ID, form.getFieldValue("remark"), modal)
              }
              type="primary"
            >
              Save
            </Button>
          ) : (
            <Button
              form="myForm"
              onClick={() =>
                acknowledgeAlarm(row.ID, form.getFieldValue("remark"), modal)
              }
              type="primary"
            >
              Acknowledge
            </Button>
          )}
        </>
      ),
    });
  }

  /* API to get site commissioning date */
  function getSiteCommissioningDate() {
    fetchWrapper({
      endpoint_url: "common/getSiteCommissioningDate",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setSiteCommissioningDate(response.data.site_commissioning_date);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  /* API to get device list*/
  function getDeviceList() {
    fetchWrapper({
      endpoint_url: "alarm/getDeviceList",
      method: "GET",
      set_content_type_header: false,
      params: { site_id: siteSelected },
      onSuccess: (response) => {
        setDeviceList(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
        setDeviceList([]);
      },
    });
  }

  /* API to get active alarm */
  function getSelectedSiteActiveAlarm() {
    fetchWrapper({
      endpoint_url: "alarm/getSelectedSiteActiveAlarm",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        device_id_list:
          activeAlarmFilter.length === 0
            ? deviceList.map((item) => item.value)
            : activeAlarmFilter,
      },
      onSuccess: (response) => {
        setActiveAlarmData(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setFirstLoadActiveAlarm(false);
      },
    });
  }

  /* API to get historical alarm */
  function getSelectedSiteHistoricalAlarm() {
    fetchWrapper({
      endpoint_url: "alarm/getSelectedSiteHistoricalAlarm",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        device_id_list:
          historicalAlarmFilter.length !== 0
            ? historicalAlarmFilter.device_id_list.length === 0
              ? deviceList.map((item) => item.value)
              : historicalAlarmFilter.device_id_list
            : deviceList.map((item) => item.value),
        start_date:
          historicalAlarmFilter.length !== 0
            ? historicalAlarmFilter.start_date
            : state.startDate.format("YYYY-MM-DD HH:mm:ss"), // this condition is catered for the initial calling of the API where search button is not clicked yet
        end_date:
          historicalAlarmFilter.length !== 0
            ? historicalAlarmFilter.end_date
            : state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      },
      onSuccess: (response) => {
        setHistoricalAlarmData(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setFirstLoadHistoricalAlarm(false);
      },
    });
  }

  // function to download the file
  function downloadFile() {
    return new Promise((resolve, reject) => {
      fetchWrapper({
        endpoint_url: "alarm/downloadFile",
        method: "POST",
        json_output: false,
        body: {
          data:
            selectedAlarmView === "Active"
              ? activeAlarmData
              : historicalAlarmData,
          sheet_name:
            selectedAlarmView === "Active"
              ? "Active Alarm"
              : "Historical Alarm",
        },
        json_output: false,
        onSuccess: (response) => {
          // returned a json response.
          if (response.headers.get("content-type") === "application/json") {
            response.json().then((data) => {
              if (Object.keys(data).includes("error")) {
                // has issue with the download file
                messageApi.open({
                  type: "error",
                  content: "Failed to download file",
                });
                resolve(false);
              } else {
                // returned something else.
                messageApi.open({
                  type: "error",
                  content: "Failed to download file.",
                });
                resolve(false);
              }
            });
          } else {
            // no issue with the response. returned a spreadsheet
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download =
                selectedAlarmView === "Active"
                  ? `Active_Alarm_${dayjs().format("YYYY-MM-DDTHH:mm:ss")}.xlsx`
                  : `Historical_Alarm_${dayjs().format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}.xlsx`;
              document.body.appendChild(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
              resolve(true);
            });
          }
        },
        onResponseError: (err) => {
          messageApi.open({
            type: "error",
            content: "Failed to download file",
          });
          resolve(false);
        },
      });
    });
  }

  useEffect(() => {
    if (siteSelected) {
      getDeviceList();
      getSiteCommissioningDate();
    }
  }, [siteSelected]);

  useEffect(() => {
    handleStartDate(dayjs(siteCommissioningDate, "YYYY-MM-DD"));
  }, [siteCommissioningDate]);

  useEffect(() => {
    // call the API only when device list has been fetched
    if (deviceList.length > 0) {
      if (selectedAlarmView === "Active") {
        if (siteSelected) {
          getSelectedSiteActiveAlarm();

          // refresh data every configurable second
          const timer = setInterval(() => {
            getSelectedSiteActiveAlarm();
          }, MQTT_REFRESH_RATE * 1000);

          return () => {
            clearInterval(timer);
          };
        }
      }
    }
  }, [deviceList, siteSelected, selectedAlarmView, activeAlarmFilter]);

  useEffect(() => {
    // call the API only when device list has been fetched
    if (deviceList.length > 0) {
      if (selectedAlarmView === "Historical") {
        if (siteSelected) {
          getSelectedSiteHistoricalAlarm();
        }
      }
    }
  }, [deviceList, siteSelected, selectedAlarmView, historicalAlarmFilter]);

  useLayoutEffect(() => {
    resizeTable();
    window.addEventListener("resize", resizeTable);

    return () => {
      window.removeEventListener("resize", resizeTable);
    };
  }, [filterRef]);

  useEffect(() => {
    resizeTable();
  }, [selectedAlarmView]);

  const columns = [
    {
      title: "Start",
      dataIndex: "start",
      ellipsis: true,
      width: 130,
      fixed: "left",
      sorter: (a, b) => {
        // Convert date strings to Date objects
        const dateA = new Date(a.start);
        const dateB = new Date(b.start);
        return dateA - dateB;
      },
    },

    {
      title: "End",
      dataIndex: "end",
      ellipsis: true,
      hidden: selectedAlarmView == "Active",
      width: 130,
      render: (end, record) => (end === "-" ? "-" : end),
    },
    {
      title: "Device",
      dataIndex: "device_name",
      width: 130,
    },
    {
      title: "Parameter",
      dataIndex: "parameter",
      width: 220,
      ellipsis: true,
      render: (_, record) =>
        record["parameter_group"] !== "-" && !record["parameter"] !== "-"
          ? `${record["parameter_group"]} (${record["parameter"]})`
          : "-",
    },
    {
      title: "Value",
      dataIndex: "value",
      width: 80,
    },
    {
      title: "Level",
      dataIndex: "level",
      ellipsis: true,
      width: 100,
      filters: [
        {
          text: "Warning",
          value: "Warning",
        },
        {
          text: "Error",
          value: "Error",
        },
      ],
      onFilter: (value, record) =>
        record?.level?.toUpperCase().indexOf(value?.toUpperCase()) === 0,
      render: (_, record) => {
        return (
          <Tag
            color={
              _?.toUpperCase() == "ERROR"
                ? token.colorError
                : _?.toUpperCase() == "WARNING"
                ? token.colorWarning
                : token.colorPrimary
            }
          >
            <Text style={{ color: "white" }}>{_}</Text>
          </Tag>
        );
      },
    },
    { title: "Message", dataIndex: "message", ellipsis: true },
    { title: "Acknowledged By", dataIndex: "acknowledged_by", width: 160 },
    {
      title: "Acknowledged On",
      dataIndex: "acknowledged_datetime",
      width: 160,
    },
    {
      title: (
        <Button
          size="small"
          type="link"
          disabled={selectedRowKeys?.length == 0}
          onClick={() => {
            setAcknowledgeMode("multi");
            setIsAcknowledgementModalOpen(true);
          }}
        >
          Acknowledge ({selectedRowKeys?.length})
        </Button>
      ),
      key: "action",
      width: 150,
      render: (_, record) => (
        <Button
          size="small"
          type="link"
          disabled={record.acknowledged}
          onClick={() => {
            setAcknowledgeMode("single");
            setSelectedAlarmID(record.ID);
            setIsAcknowledgementModalOpen(true);
          }}
        >
          Acknowledge
        </Button>
      ),
    },
  ];

  return (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Flex justify="space-between" align="center" className="mb-2">
        <Title level={5} className="m-0">
          {selectedAlarmView} Alarm
        </Title>
        <Segmented
          options={["Active", "Historical"]}
          defaultValue={selectedAlarmView}
          onChange={(value) => {
            setSelectedAlarmView(value);
            setFirstLoadActiveAlarm(true);
            setFirstLoadHistoricalAlarm(true);
          }}
        ></Segmented>
      </Flex>

      <div ref={filterRef}>
        {selectedAlarmView == "Historical" ? (
          <Flex
            className="mb-4"
            gap={8}
            wrap={true}
            align="flex-start"
            justify="space-between"
          >
            <Space.Compact block style={{ width: "fit-content" }}>
              <Button disabled>Device</Button>
              <Select
                placeholder={
                  <Tag color={token.colorPrimary} className="pl-3 pr-3">
                    <Text style={{ color: "white" }}>All</Text>
                  </Tag>
                }
                className="historical-alarm-device-selection w-100"
                value={state.deviceSelected}
                options={deviceList}
                mode={"multiple"}
                allowClear
                tagRender={({ label, onClose, closable }) => (
                  <Tag
                    color={token.colorPrimary}
                    closable={closable}
                    onClose={onClose}
                    style={{
                      marginRight: 3,
                    }}
                  >
                    <Text style={{ color: "white" }}>{label}</Text>
                  </Tag>
                )}
                onChange={(value) => {
                  handleDeviceChange(value);
                }}
              />
            </Space.Compact>

            <Space.Compact block style={{ width: "fit-content" }}>
              <Button disabled>Start</Button>
              <DatePicker
                showTime
                showHour
                showMinute
                showSecond={false}
                allowClear={false}
                value={state.startDate}
                placement="bottomLeft"
                defaultValue={state.startDate}
                needConfirm={false}
                onChange={(date) => {
                  if (date > state.endDate) {
                    handleStartDate(state.endDate);
                    handleEndDate(date);
                  } else {
                    handleStartDate(date);
                  }
                }}
                maxDate={state.endDate}
                minDate={dayjs(siteCommissioningDate, "YYYY-MM-DD")}
              />
              <Button disabled>End</Button>
              <DatePicker
                showTime
                showHour
                showMinute
                showSecond={false}
                allowClear={false}
                value={state.endDate}
                defaultValue={state.endDate}
                needConfirm={false}
                onChange={(date) => {
                  if (date < state.startDate) {
                    handleEndDate(state.startDate);
                    handleStartDate(date);
                  } else {
                    handleEndDate(date);
                  }
                }}
                minDate={state.startDate}
                placement="topLeft"
              />
            </Space.Compact>

            <Flex gap={8}>
              <Tooltip title="Reset to default setting">
                <Button
                  icon={<UndoOutlined />}
                  onClick={() => {
                    handleStartDate(
                      dayjs(
                        Math.max(
                          dayjs()
                            .hour(0)
                            .minute(0)
                            .second(0)
                            .add(-14, "day")
                            .valueOf(),
                          dayjs(siteCommissioningDate, "YYYY-MM-DD").valueOf()
                        )
                      )
                    );
                    handleEndDate(
                      dayjs().hour(0).minute(0).second(0).add(1, "day")
                    );
                    handleDeviceChange([]);
                    setHistoricalAlarmFilter({
                      start_date: dayjs()
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .add(-14, "day")
                        .format("YYYY-MM-DD HH:mm:ss"),
                      end_date: dayjs()
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .add(1, "day")
                        .format("YYYY-MM-DD HH:mm:ss"),
                      device_id_list: [],
                    });
                  }}
                />
              </Tooltip>
              <Tooltip title="Search">
                <Button
                  icon={<SearchOutlined />}
                  onClick={() => {
                    setHistoricalAlarmFilter({
                      start_date: state.startDate.format("YYYY-MM-DD HH:mm:ss"),
                      end_date: state.endDate.format("YYYY-MM-DD HH:mm:ss"),
                      device_id_list: state.deviceSelected,
                    });
                  }}
                />
              </Tooltip>

              <Tooltip
                title={
                  historicalAlarmData.length === 0 ? "No data to download" : ""
                }
              >
                <Button
                  disabled={historicalAlarmData.length === 0}
                  icon={<DownloadOutlined />}
                  onClick={downloadFile}
                />
              </Tooltip>
            </Flex>
          </Flex>
        ) : (
          <Flex
            className="mb-4"
            gap={8}
            wrap={true}
            align="flex-start"
            justify="space-between"
          >
            <Space.Compact block style={{ width: "fit-content" }}>
              <Button disabled>Device</Button>
              <Select
                placeholder={
                  <Tag color={token.colorPrimary} className="pl-3 pr-3">
                    <Text style={{ color: "white" }}>All</Text>
                  </Tag>
                }
                style={{ width: "calc(100vw - 176px)" }}
                value={state.deviceSelected}
                options={deviceList}
                mode={"multiple"}
                allowClear
                tagRender={({ label, onClose, closable }) => (
                  <Tag
                    color={token.colorPrimary}
                    closable={closable}
                    onClose={onClose}
                    style={{
                      marginRight: 3,
                    }}
                  >
                    <Text style={{ color: "white" }}>{label}</Text>
                  </Tag>
                )}
                onChange={(value) => {
                  handleDeviceChange(value);
                }}
              />
            </Space.Compact>

            <Flex gap={8}>
              <Tooltip title="Search">
                <Button
                  icon={<SearchOutlined />}
                  onClick={() => {
                    setActiveAlarmFilter(state.deviceSelected);
                  }}
                />
              </Tooltip>

              <Tooltip
                title={
                  activeAlarmData.length === 0 ? "No data to download" : ""
                }
              >
                <Button
                  disabled={activeAlarmData.length === 0}
                  icon={<DownloadOutlined />}
                  onClick={downloadFile}
                />
              </Tooltip>
            </Flex>
          </Flex>
        )}
      </div>
      <Table
        onRow={(record, index) => {
          return {
            onDoubleClick: (evt) => {
              onRowClicked(record);
            },
          };
        }}
        loading={
          selectedAlarmView === "Active"
            ? firstLoadActiveAlarm
            : selectedAlarmView === "Historical"
            ? firstLoadHistoricalAlarm
            : false
        }
        sticky="true"
        size="small"
        showHeader
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) =>
            setSelectedRowKeys(selectedRowKeys),
          getCheckboxProps: (record) => ({
            disabled: record.acknowledged,
          }),
        }}
        scroll={{ scrollToFirstRowOnChange: true, x: true, y: tableHeight }}
        pagination={{
          responsive: true,
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 50, 100],
          position: ["bottomRight"],
          size: "small",
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        columns={columns}
        dataSource={
          selectedAlarmView === "Historical"
            ? historicalAlarmData
            : activeAlarmData
        }
      ></Table>

      <Modal
        centered
        open={isAcknowledgementModalOpen}
        title="Acknowledge Alarm"
        footer={false}
        style={{ width: "500px", maxWidth: "90vw" }}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => setIsAcknowledgementModalOpen(false)}
      >
        <div>
          <AcknowledgeAlarmForm
            selectedAlarmIDs={
              acknowledgeMode == "single"
                ? selectedAlarmID
                : selectedRowKeys.length === 0
                ? selectedAlarmID
                : selectedRowKeys
            }
            setIsAcknowledgementModalOpen={setIsAcknowledgementModalOpen}
            setSelectedAlarmIDs={setSelectedRowKeys}
            selectedAlarmView={selectedAlarmView}
            getSelectedDeviceActiveAlarm={getSelectedSiteActiveAlarm}
            getSelectedDeviceHistoricalAlarm={getSelectedSiteHistoricalAlarm}
          />
        </div>
      </Modal>
    </div>
  );
}

// library imports
import { useState } from "react";
// hook imports
import useFetch from "./useFetch";

export default function useHasWeatherStationSelected() {
  const [fetchWrapper] = useFetch();

  // loading state
  const [
    getHasWeatherStationSelectedLoading,
    setGetHasWeatherStationSelectedLoading,
  ] = useState(false);

  // this state is to check whether the site has weather station
  const [hasWeatherStationSelected, setHasWeatherStationSelected] =
    useState(false);

  /* API to check whether site has weather station that is selected and not deleted */
  function getHasWeatherStationSelected({ siteSelected }) {
    setGetHasWeatherStationSelectedLoading(true);
    fetchWrapper({
      endpoint_url: "layout/getHasWeatherStationSelected",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },
      onSuccess: (response) => {
        setGetHasWeatherStationSelectedLoading(false);
        setHasWeatherStationSelected(response.data);
      },
    });
  }

  return [
    hasWeatherStationSelected,
    getHasWeatherStationSelected,
    getHasWeatherStationSelectedLoading,
  ];
}

import { useEffect, useState, useContext } from "react";
import ImageUploader from "../../common/ImageUploader";
import {
  Flex,
  Form,
  Steps,
  Table,
  Typography,
  Button,
  Skeleton,
  Col,
  Row,
  Input,
  InputNumber,
  Select,
  Space,
  Tooltip,
} from "antd";
import { IndexContext } from "../../../context/IndexContext";
import useFetch from "../../../hooks/useFetch";
import { CONFIGS, FORM_REQUIRED } from "../../../configs/configs";
import "../../../styles/Configuration/NewDeviceForm.css";

const columns = [
  {
    title: "Name",
    dataIndex: "name",

    key: "name",
    onCell: (record, index) => {
      if (record.children) return { colSpan: 2 };
      else return { colSpan: 1 };
    },
  },
  {
    title: "Tag Key",
    dataIndex: "key",
    key: "key",
    editable: true,
    onCell: (record, index) => {
      if (record.children) return { colSpan: 0 };
      else return { colSpan: 1 };
    },
    render: (text, record) => {
      // if the record is a parent, don't display the tag key
      return record.children ? "" : text;
    },
  },
];

const layout = {
  labelCol: {
    span: 24,
    padding: 0,
  },
  wrapperCol: {
    span: 24,
  },
};

const { Text } = Typography;

export default function DeviceForm(props) {
  const {
    siteID,
    mode,
    setIsDeviceFormModalOpen,
    refreshTable,
    initialValues = null,
  } = props;
  const [deviceID, setDeviceID] = useState(null);
  const [fileList, setFileList] = useState([]);

  const [step, setStep] = useState(0);
  const [form] = Form.useForm();
  const [parameters, setParameters] = useState([]);
  const [parameterSelection, setParameterSelection] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // loading components
  const [loading, setLoading] = useState(false);
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);
  const [loadingProtocolTypeList, setLoadingProtocolTypeList] = useState(false);

  const { messageApi, notificationApi } = useContext(IndexContext);
  const [fetchWrapper] = useFetch();

  // selection for device type
  const [deviceTypeList, setMeterTypeList] = useState([]);
  const [deviceTypeSelected, setDeviceTypeSelected] = useState(null);

  // selection for device brand
  const [deviceBrandList, setDeviceBrandList] = useState([]);
  const [deviceBrandSelected, setDeviceBrandSelected] = useState(null);

  // selection for protocol type
  const [protocolTypeSelected, setProtocolTypeSelected] = useState(null);
  const [protocolTypeList, setProtocolTypeList] = useState([]);

  const [deviceModelList, setDeviceModelList] = useState([]);

  // table scrollable height
  const [tableHeight, setTableHeight] = useState(622);
  const [stepHeight, setStepHeight] = useState(32);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setParameters(selectedRows?.filter((row) => "id" in row));
    },
  };

  /* API to get device type selection */
  function getDeviceType() {
    setLoading(true);
    fetchWrapper({
      method: "GET",
      endpoint_url: "device_management/getDeviceType",
      set_content_type_header: false,
      onSuccess: (response) => {
        setMeterTypeList(response.data);
        setLoading(false);
      },
    });
  }

  /* API to get device brand selection */
  function getDeviceBrand() {
    setLoading(true);
    fetchWrapper({
      method: "GET",
      endpoint_url: "device_management/getDeviceBrand",
      set_content_type_header: false,
      onSuccess: (response) => {
        setDeviceBrandList(response.data);
        setLoading(false);
      },
    });
  }

  /* API to get device model selection */
  function getDeviceModel() {
    setDeviceModelList([]);
    fetchWrapper({
      method: "GET",
      endpoint_url: "device_management/getDeviceModel",
      set_content_type_header: false,
      params: {
        brand_id: deviceBrandSelected,
      },
      onSuccess: (response) => {
        setDeviceModelList(response.data);
      },
    });
  }

  /* API to get protocol type list */
  function getProtocolTypeList() {
    setLoadingProtocolTypeList(true);
    fetchWrapper({
      method: "GET",
      endpoint_url: "device_management/getDeviceProtocolTypeSelection",
      set_content_type_header: false,
      onSuccess: (response) => {
        setLoadingProtocolTypeList(false);
        setProtocolTypeList(response.data);
      },
    });
  }

  /* API to get device parameter details */
  function getDeviceParameterDetails(device_id) {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "device_management/getDeviceParameterDetails",
      body: {
        device_id: device_id,
      },
      onSuccess: (fetch_output) => {
        setLoading(false);
        if (fetch_output.status === true) {
          setSelectedRowKeys(fetch_output.row_key_list);
          setParameters(fetch_output.parameter_list);
        }
      },
    });
  }

  /* API to add device data */
  function addDevice() {
    const value = form.getFieldsValue([
      "Name",
      "DeviceTypeID",
      "BrandID",
      "ModelID",
      "RatingInkWp",
      "ProtocolTypeID",
      "PhaseNumber",
    ]);
    setLoadingSubmitButton(true);
    let form_data = new FormData();
    Object.keys(value).forEach((key) => {
      // if (value[key] ?? null != null) {
      form_data.append(key, value[key] ?? null);
      // }
    });

    if (fileList.length > 0 && fileList[0].originFileObj) {
      form_data.append("image", fileList[0].originFileObj);
    }

    form_data.append("SiteID", siteID);

    if (deviceID != null) {
      form_data.append("DeviceID", deviceID);
    }

    fetchWrapper({
      endpoint_url: "device_management/addDevice",
      set_content_type_header: false,
      body: form_data,
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setDeviceID(response.data?.ID);
        addDeviceParameter(response.data?.ID);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
        setLoadingSubmitButton(false);
      },
      onFetchError: (error) => {
        setLoadingSubmitButton(false);
      },
    });
  }

  function addDeviceParameter(device_id) {
    setLoadingSubmitButton(true);
    fetchWrapper({
      endpoint_url: "device_management/addDeviceParameter",
      body: { DeviceID: device_id, Parameters: parameters },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setIsDeviceFormModalOpen(false);
        refreshTable();
      },
      onFinish: () => {
        setLoadingSubmitButton(false);
      },
    });
  }

  /* API to check whether device name is unique */
  function checkUniqueDeviceName(device_name, callback) {
    fetchWrapper({
      endpoint_url: "device_management/checkUniqueDeviceName",
      body: {
        site_id: siteID,
        device_id: initialValues ? initialValues.ID : null,
        device_name: device_name ? device_name : "",
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          if (fetch_output.is_unique === true) {
            callback(); // No error, so pass an empty callback
          } else {
            callback(
              "Device name already exists. Please input another device name."
            );
          }
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
      },
    });
  }

  /* API to get device's parameter selection */
  function getParameterSelection(device_model_id) {
    setParameterSelection([]);
    fetchWrapper({
      endpoint_url: "parameter_setting/getParameterSelection",
      method: "GET",
      set_content_type_header: false,
      params: { device_model_id: device_model_id },

      onSuccess: (response) => {
        setParameterSelection(response.data);
      },

      oonResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  function nextStep() {
    setStep(1);
  }

  function handleSubmit() {
    addDevice();
  }

  const clearForm = () => {
    form.resetFields();
    setDeviceBrandSelected(null);
    setFileList(null);
  };

  useEffect(() => {}, [fileList]);

  useEffect(() => {
    getDeviceType();
    getDeviceBrand();
    getProtocolTypeList();
  }, []);

  useEffect(() => {
    if (deviceBrandSelected) {
      getDeviceModel();
    }
  }, [deviceBrandSelected]);

  useEffect(() => {
    if (mode === "edit") {
      if (initialValues) {
        getParameterSelection(initialValues.ModelID);
        getDeviceParameterDetails(initialValues.ID);

        form.setFieldsValue(initialValues);

        setDeviceBrandSelected(initialValues.BrandID); // set brand selected so that meter model list is updated
        setDeviceTypeSelected(initialValues.DeviceTypeID); // set device type so that different form item is rendered correctly

        setDeviceID(initialValues.ID);

        if (initialValues.ImagePath) {
          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: `${CONFIGS.backend_url}device_management/getDeviceImage?Path=${initialValues.ImagePath}`,
            },
          ]);
        }
      }
    }
  }, [mode, initialValues]);

  useEffect(() => {
    const stepResizeObserver = new ResizeObserver(() => {
      const stepElement = document.getElementById("Step");
      setStepHeight(stepElement?.offsetHeight);
    });
    const stepElement = document.getElementById("Step");
    stepResizeObserver.observe(stepElement);

    const FormAreaResizeObserver = new ResizeObserver(() => {
      const FormArea = document.getElementById("FormArea");
      setTableHeight(FormArea?.offsetHeight - 80 - 38 - 38);
    });
    const FormArea = document.getElementById("FormArea");
    FormAreaResizeObserver.observe(FormArea);

    return () => {
      stepResizeObserver.disconnect();
      FormAreaResizeObserver.disconnect();
    };
  }, []);

  return (
    <div className="p-4" style={{ height: "100%" }}>
      <div id="Step">
        <Steps
          current={step}
          className="mb-4"
          items={[
            {
              title: "General",
            },
            {
              title: "Parameter Setting",
            },
          ]}
        />
      </div>
      <div
        id="FormArea"
        style={{
          height: `calc(100% - ${stepHeight}px - 54px)`,
          overflowY: "auto",
          overflowX: "hidden",
          padding: "16px",
        }}
      >
        {step == 0 && (
          <>
            {loading ? (
              <Skeleton />
            ) : (
              <Form
                form={form}
                {...layout}
                onFinish={nextStep}
                scrollToFirstError
                autoComplete="off"
                style={{
                  height: "100%",
                  overflowY: "hidden",
                  overflowX: "hidden",
                }}
              >
                <Row
                  gutter={[24, 12]}
                  style={{
                    height: "calc(100% - 48px)",
                    overflow: "auto",
                  }}
                >
                  <Col flex="250px">
                    <Form.Item label="Image" className="mb-0">
                      <ImageUploader
                        size={250}
                        maxCount={1}
                        fileList={fileList}
                        onChange={(files) => {
                          setFileList(files);
                        }}
                      ></ImageUploader>
                    </Form.Item>
                  </Col>
                  <Col flex="auto">
                    <Form.Item
                      name={"Name"}
                      label={"Name"}
                      validateDebounce={100}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message: "Please enter device name.",
                        },
                        {
                          validator: (_, value, callback) => {
                            checkUniqueDeviceName(value, callback);
                          },
                        },
                      ]}
                    >
                      <Input maxLength={50}></Input>
                    </Form.Item>
                    <Form.Item
                      name={"DeviceTypeID"}
                      label={"Device Type"}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message: "Please select device type.",
                        },
                      ]}
                    >
                      <Select
                        options={deviceTypeList}
                        onChange={(value) => {
                          setDeviceTypeSelected(value);
                        }}
                      ></Select>
                    </Form.Item>
                    <Form.Item
                      name={"BrandID"}
                      label={"Brand"}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message: "Please select device brand.",
                        },
                      ]}
                    >
                      <Select
                        options={deviceBrandList}
                        onChange={(value) => {
                          setDeviceBrandSelected(value);
                          form.setFieldValue("ModelID", null);
                        }}
                      ></Select>
                    </Form.Item>
                    <Form.Item
                      name={"ModelID"}
                      label={"Model"}
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message: "Please select device model.",
                        },
                      ]}
                    >
                      <Select
                        options={deviceModelList}
                        disabled={
                          deviceBrandSelected === null ||
                          deviceModelList.length === 0
                        }
                        onChange={(e) => {
                          getParameterSelection(e);
                        }}
                      ></Select>
                    </Form.Item>

                    {/* device type = 3 means inverter */}
                    {deviceTypeSelected == 3 && (
                      <Form.Item
                        label="Rating in kWp"
                        name="RatingInkWp"
                        rules={[
                          {
                            required: FORM_REQUIRED,
                            message: "Please input rating.",
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    )}

                    <Form.Item
                      label="Protocol Type"
                      name="ProtocolTypeID"
                      rules={[
                        {
                          required: FORM_REQUIRED,
                          message: "Please select protocol type.",
                        },
                      ]}
                    >
                      <Select
                        value={protocolTypeSelected}
                        options={protocolTypeList}
                        onChange={(value) => {
                          setProtocolTypeSelected(value);
                        }}
                        loading={loadingProtocolTypeList}
                      />
                    </Form.Item>

                    {/* device type = 2 -> PV power meter 
                        device type = 3 -> inverter 
                        device type = 5 -> weather station
                        device type = 6 -> BESS  */}
                    {[2, 3, 5, 6].includes(deviceTypeSelected) && (
                      <Form.Item
                        label="Phase Number"
                        name="PhaseNumber"
                        rules={[
                          {
                            required: FORM_REQUIRED,
                            message: "Please input phase number.",
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Flex
                  justify="end"
                  align="center"
                  gap="middle"
                  className="mt-4"
                >
                  <Button type="default" onClick={() => clearForm()}>
                    Clear
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Next
                  </Button>
                </Flex>
              </Form>
            )}
          </>
        )}
        {step == 1 && (
          <>
            <div style={{ height: tableHeight + 76 }}>
              <Table
                columns={columns}
                dataSource={parameterSelection}
                pagination={false}
                scroll={{ y: tableHeight, x: true }}
                size="small"
                rowSelection={{
                  ...rowSelection,
                  checkStrictly: false,
                }}
                footer={() => (
                  <Text strong justify="right">
                    Selected Parameters - {parameters?.length}
                  </Text>
                )}
              ></Table>
            </div>
            <Flex
              justify="space-between"
              align="center"
              gap="middle"
              className="mt-4"
            >
              <Button
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                Previous
              </Button>
              <Button
                type="primary"
                loading={loadingSubmitButton}
                onClick={() => {
                  // if (mode === "add") {
                  //   addDeviceData();
                  // } else {
                  //   editDeviceData();
                  // }
                  handleSubmit();
                }}
              >
                Submit
              </Button>
            </Flex>
          </>
        )}
      </div>
    </div>
  );
}

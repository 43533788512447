import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, Modal, Divider, Flex, Popconfirm, Spin } from "antd";
import { useTableSearch } from "../../../functions/tableSearch";
import UserForm from "./UserForm";
import { useEffect, useState, useContext } from "react";
import { IndexContext } from "../../../context/IndexContext";
import useFetch from "../../../hooks/useFetch";
import DataTable from "../../../components/common/DataTable";

export default function UserView(props) {
  const { organisationNameSelectionList } = props;

  const { getColumnSearchProps } = useTableSearch();

  const [fetchWrapper] = useFetch();
  const [userDetails, setUserDetails] = useState([]);

  const [userTableLoading, setUserTableLoading] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});

  // load index context
  const { messageApi } = useContext(IndexContext);

  const userTableColumns = [
    {
      title: "Username",
      dataIndex: "Username",
      key: "Username",
      width: "10%",
      fixed: "left",
      ...getColumnSearchProps("Username"),
    },
    {
      title: "Organisation",
      dataIndex: "OrganisationName",
      key: "OrganisationName",
      width: "15%",
      ...getColumnSearchProps("OrganisationName"),
    },
    {
      title: "PIC Full Name",
      dataIndex: "FullName",
      key: "FullName",
      width: "14%",
    },
    {
      title: "Contact Number",
      dataIndex: "ContactNumber",
      key: "ContactNumber",
      width: "15%",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      width: "14%",
      ...getColumnSearchProps("Email"),
    },
    {
      title: "Role",
      dataIndex: "RoleName",
      key: "RoleName",
      filters: [
        {
          text: "Admin",
          value: "Admin",
        },
        {
          text: "User",
          value: "User",
        },
      ],
      onFilter: (value, record) => record.RoleName.startsWith(value),
      width: "7.5%",
    },
    // {
    //   title: "Created At",
    //   dataIndex: "InsertDateTime",
    //   key: "InsertDateTime",
    //   width: "10%",
    // },
    {
      title: "Access",
      align: "center",
      dataIndex: "AccessValidity",
      key: "AccessValidity",
      render: (_, record) => (
        <Flex justify="center" align="center">
          {record.AccessValidity === false ? (
            <StopOutlined style={{ color: "red" }} />
          ) : (
            <CheckOutlined style={{ color: "green" }} />
          )}
        </Flex>
      ),
      width: "11%",
    },
    {
      title: "",
      dataIndex: "Action",
      key: "Action",
      width: "11%",
      render: (_, record) => (
        <Flex justify="flex-start" align="center" gap={"5px"} wrap={true}>
          <Tooltip title="Edit">
            <Button
              type="text"
              icon={<EditOutlined style={{ color: "blue" }} />}
              onClick={() => {
                getUserFormData(record);
                setIsEditUserModalOpen(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Delete User"
              description={"Are you sure to delete this user?"}
              onConfirm={() => {
                deleteUser(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="text"
                icon={<DeleteOutlined style={{ color: "red" }} />}
              />
            </Popconfirm>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  function getUserFormData(row_record) {
    fetchWrapper({
      endpoint_url: "user_management/getUserFormData",
      body: {
        user_account_id: row_record.ID,
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          setFormInitialValues(fetch_output.user_details);
        }
      },
    });
  }

  /* API to get user details */
  function getUserDetails() {
    setUserTableLoading(true);
    fetchWrapper({
      endpoint_url: "user_management/getUserDetails",
      onSuccess: (fetch_output) => {
        setUserTableLoading(false);
        if (fetch_output.status === true) {
          setUserDetails(fetch_output.user_details);
        }
      },
    });
  }

  function deleteUser(row_record) {
    setUserTableLoading(true);
    fetchWrapper({
      endpoint_url: "user_management/deleteUser",
      body: {
        user_account_id: row_record.ID,
      },
      onSuccess: (fetch_output) => {
        setUserTableLoading(false);
        if (fetch_output.status === true) {
          messageApi.open({
            type: "success",
            content: fetch_output.message,
          });
          getUserDetails();
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
      },
    });
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      <Flex>
        <Button
          icon={<PlusOutlined />}
          className="mb-5"
          onClick={() => setIsAddUserModalOpen(true)}
        >
          New User
        </Button>
      </Flex>

      {userTableLoading === true ? (
        <Flex justify="center" align="center" style={{ height: "50vh" }}>
          <Spin />
        </Flex>
      ) : (
        <DataTable
          table_column={userTableColumns}
          table_data={userDetails}
          scroll_width={1500}
          expandable={{
            expandedRowRender: (record) => (
              <p
                style={{
                  margin: 0,
                }}
              >
                {record.description}
              </p>
            ),
          }}
        />
      )}

      <Modal
        title="Add User"
        open={isAddUserModalOpen}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <Divider className="mb-4 mt-0"></Divider>
        <UserForm
          mode="add"
          setIsUserFormModalOpen={setIsAddUserModalOpen}
          isUserGroupForm={false}
          refreshTable={getUserDetails}
          organisationNameSelectionList={organisationNameSelectionList}
        />
      </Modal>

      <Modal
        title="Edit User"
        open={isEditUserModalOpen}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <Divider className="mb-4 mt-0"></Divider>
        <UserForm
          mode="edit"
          setIsUserFormModalOpen={setIsEditUserModalOpen}
          isUserGroupForm={false}
          refreshTable={getUserDetails}
          initialValues={formInitialValues}
          organisationNameSelectionList={organisationNameSelectionList}
        />
      </Modal>
    </>
  );
}

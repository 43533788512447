// library imports
import React, { useEffect, useState, useContext } from "react";
import { Card, DatePicker, Flex, Table, Button, Divider } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
// style imports
import "../../styles/SavingReport/SavingReport.css";
// component imports
import MD from "../../components/saving_report/MD";
import EnergySaving from "../../components/saving_report/EnergySaving";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";

const { RangePicker } = DatePicker;

export default function SavingReportPage() {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  const [loading, setLoading] = useState(true);
  const [downloadButtonLoading, setDownloadButtonLoading] = useState(false);

  const [dates, setDates] = useState([dayjs(), dayjs()]); // value of RangePicker when either one of the start date and end date has been selected
  const [value, setValue] = useState([dayjs(), dayjs()]); // value of RangePicker if both the start date and end date has been selected

  const [tableData, setTableData] = useState([]);
  const [tableColumn, setTableColumn] = useState([
    {
      title: "Saving",
      dataIndex: "Saving",
      key: "Saving",
      width: 200,
    },
  ]);

  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );

  const [mdSummaryData, setMdSummaryData] = useState([]);
  const [mdBreakdownData, setMdBreakdownData] = useState([]);
  const [mdSaving, setMdSaving] = useState(null);
  const [mdDateBeforeBess, setMdDateBeforeBess] = useState(null);
  const [mdDateAfterBess, setMdDateAfterBess] = useState(null);

  // Disable dates that are not in the same year as the selected start date
  const disabledDate = (current) => {
    if (!dates || !dates[0]) {
      // No start date selected, so allow all dates
      return false;
    }

    const startYear = dates[0].year();

    // Disable dates that are not in the same year as the selected start date
    return current.year() !== startYear;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]); // Reset dates to [null, null] when the picker is opened
    } else {
      setDates(null); // Clear the state completely when the picker is closed
    }
  };

  const onCalendarChange = (val) => {
    if (val[1] && !val[0]) {
      // If end date is selected first, set the start date to the Jan of the year
      const sameMonthStart = val[1].clone().startOf("month");
      setDates([sameMonthStart, val[1]]);
    } else {
      setDates(val); // Otherwise, just set the selected dates
    }
  };

  /** API to get saving report summary */
  function getSavingReportSummary() {
    setLoading(true);

    fetchWrapper({
      endpoint_url: "saving_report/getSavingReportSummary",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        start_date: startDate,
        end_date: endDate,
      },

      onSuccess: (response) => {
        setTableColumn(response.data.table_column);
        setTableData(response.data.table_data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  /** API to download saving report */
  function downloadSavingReport() {
    setDownloadButtonLoading(true);

    fetchWrapper({
      endpoint_url: "saving_report/downloadSavingReport",
      method: "POST",
      json_output: false,
      body: {
        site_id: siteSelected,
        start_date: startDate,
        end_date: endDate,
        saving_report_summary: JSON.stringify(tableData),
      },

      onSuccess: (response) => {
        if (response.headers.get("content-type") !== "application/json") {
          // no issue with the response. returned a spreadsheet
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `SavingReport_${dayjs().format(
              "YYYYMMDD_HHmmss"
            )}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          });
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: "Failed to download file",
        });
      },

      onFinish: () => {
        setDownloadButtonLoading(false);
      },
    });
  }

  useEffect(() => {
    if (startDate && endDate && siteSelected) {
      getSavingReportSummary();
    }
  }, [startDate, endDate, siteSelected]);

  return (
    <div
      style={{
        margin: "10px",
        width: "100%",
        overflowY: "auto",
      }}
      className="saving-report"
    >
      <Card
        title={
          <Flex justify="space-between" align="center" wrap={true} gap={8}>
            <div>Saving Report</div>

            <Flex gap={4}>
              <RangePicker
                picker="month"
                defaultValue={dates}
                value={dates || value}
                disabledDate={disabledDate} // Apply the disabledDate function
                onCalendarChange={onCalendarChange} // Update selected dates
                onChange={(val) => {
                  setValue(val);
                  setStartDate(val[0].startOf("month").format("YYYY-MM-DD"));
                  setEndDate(val[1].startOf("month").format("YYYY-MM-DD"));
                }} // Update final value, start month and end month
                onOpenChange={onOpenChange} // Reset when the picker opens
              />

              <Button
                disabled={loading}
                loading={downloadButtonLoading}
                icon={<DownloadOutlined />}
                onClick={() => {
                  downloadSavingReport();
                }}
              />
            </Flex>
          </Flex>
        }
      >
        <Table
          style={{ marginBottom: "12px" }}
          columns={tableColumn}
          dataSource={tableData}
          pagination={false}
          loading={loading}
          size="small"
        />
        <Divider style={{ borderColor: "lightgray" }} />
        <MD
          mdDateAfterBess={mdDateAfterBess}
          setMdDateAfterBess={setMdDateAfterBess}
          mdDateBeforeBess={mdDateBeforeBess}
          setMdDateBeforeBess={setMdDateBeforeBess}
          mdSaving={mdSaving}
          setMdSaving={setMdSaving}
          mdSummaryData={mdSummaryData}
          setMdSummaryData={setMdSummaryData}
          mdBreakdownData={mdBreakdownData}
          setMdBreakdownData={setMdBreakdownData}
          startDateOri={startDate}
          endDateOri={endDate}
        />

        <Divider style={{ borderColor: "lightgray" }} />
        <EnergySaving startDateOri={startDate} endDateOri={endDate} />
      </Card>
    </div>
  );
}

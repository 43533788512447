// library imports
import { Card, Row, Col } from "antd";
// style imports
import "../../styles/Analytics/Analytics.css";

export default function Analytics({
  title,
  contentComponent,
  filterComponent,
  isTrendingDataLoading,
  isParameterDataLoading,
}) {
  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} sm={24} md={24} lg={8} xl={4} xxl={4}>
        <Card
        className="analytics-filter-card"
          title={"Filter"}
          loading={isParameterDataLoading}
        >
          {filterComponent}
        </Card>
      </Col>

      <Col xs={24} sm={24} md={24} lg={16} xl={20} xxl={20}>
        <Card
        className="analytics-content-card"
          title={
            <div className="analytics-content-card-title">
              <span>{title}</span>
            </div>
          }
          loading={isTrendingDataLoading}
          
        >
          {contentComponent}
        </Card>
      </Col>
    </Row>
  );
}

// library imports
import { useEffect, useState, useContext } from "react";
import { Card, Col, Row, Switch, Popconfirm, message, Skeleton } from "antd";
// config imports
import { MQTT_REFRESH_RATE } from "../../configs/configs";
// context imports
import { IndexContext } from "../../context/IndexContext";
// hook imports
import useFetch from "../../hooks/useFetch";
// styles imports
import "../../styles/Features/AIBESSPage.css";

function AIBESSPage() {
  const [fetchWrapper] = useFetch();
  const { notificationApi, siteSelected } = useContext(IndexContext);

  const [aiBessStatus, setAIBESSStatus] = useState(null); // true means AI-BESS is on and vice versa
  const [aiBessStatusFirstLoad, setAIBESSStatusFirstLoad] = useState(true);
  const [aiBessSwitchLoading, setAIBESSSwitchLoading] = useState(false);

  const cancel = () => {
    message.success("Action is cancelled");
  };

  /** API to get AI-BESS status */
  function getAIBESSStatus() {
    fetchWrapper({
      endpoint_url: "ai_bess/getAIBESSStatus",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },
      onSuccess: (response) => {
        setAIBESSStatus(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setAIBESSStatusFirstLoad(false);
      },
    });
  }

  /** API to update AI-BESS status */
  function updateAIBESSStatus(ai_bess_status_input) {
    fetchWrapper({
      endpoint_url: "ai_bess/updateAIBESSStatus",
      method: "POST",
      body: {
        site_id: siteSelected,
        ai_bess_status: ai_bess_status_input,
      },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setAIBESSSwitchLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteSelected) {
      getAIBESSStatus();

      const timer = setInterval(() => {
        getAIBESSStatus();
      }, MQTT_REFRESH_RATE * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelected]);

  return (
    <div
      style={{
        margin: "10px",
        width: "100%",
        height: "90vh",
        overflowY: "auto",
      }}
      className="ai-bess"
    >
      {aiBessStatusFirstLoad ? (
        <Card title={"AI-BESS Control"}>
          <Skeleton
            active={true}
            style={{ padding: "1rem", maxWidth: "90%", margin: "auto" }}
            paragraph={{ rows: 5 }}
          />
        </Card>
      ) : (
        <Card title={"AI-BESS Control"}>
          <Row style={{ width: "100%" }}>
            <Col span={12} style={{ textAlign: "right", padding: "0px 8px" }}>
              AI-BESS Status:
            </Col>
            <Col
              span={12}
              style={{
                color: aiBessStatus === true ? "green" : "red",
                fontWeight: "bold",
                padding: "0px 8px",
              }}
            >
              {aiBessStatus === true ? "ON" : "OFF"}
            </Col>
          </Row>
          <Row style={{ width: "100%", marginTop: "24px" }}>
            <Col span={12} style={{ textAlign: "right", padding: "0px 8px" }}>
              AI-BESS Switch:
            </Col>
            <Col span={12} style={{ padding: "0px 8px" }}>
              <Popconfirm
                title="AI BESS Status"
                description={
                  aiBessStatus
                    ? "Are you sure to turn off AI-BESS mode?"
                    : "Are you sure to turn on AI-BESS mode?"
                }
                onConfirm={() => {
                  updateAIBESSStatus(!aiBessStatus);
                  setAIBESSStatus(!aiBessStatus);
                }}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Switch
                  checked={aiBessStatus === true}
                  disabled={aiBessSwitchLoading}
                />
              </Popconfirm>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
}

export default AIBESSPage;

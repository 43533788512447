import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Typography,
  Flex,
  Modal,
  Popconfirm,
  Form,
  message,
  Table,
} from "antd";
import { useEffect, useState, useContext } from "react";
import { IndexContext } from "../../../context/IndexContext";
import useFetch from "../../../hooks/useFetch";
import TelegramForm from "./TelegramForm";
import "../../../styles/Configuration/SiteView.css";

const { Title } = Typography;

export default function TelegramView(props) {
  const { siteID } = props;
  const [fetchWrapper] = useFetch();
  const { notificationApi } = useContext(IndexContext);

  const [alarmTelegramConfigForm] = Form.useForm();

  // modal states
  const [isAddTelegramFormModalOpen, setIsAddTelegramFormModalOpen] =
    useState(false);
  const [isEditTelegramFormModalOpen, setIsEditTelegramFormModalOpen] =
    useState(false);

  const [alarmTelegramTableData, setAlarmTelegramTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editingAlarmTelegramID, setEditingAlarmTelegramID] = useState(null);

  // loading states
  const [alarmTelegramTableDataLoading, setAlarmTelegramTableDataLoading] =
    useState(false);
  const [saveSelectedTelegramsLoading, setSaveSelectedTelegramsLoading] =
    useState(false);
  const [
    submitTelegramDetailsButtonLoading,
    setSubmitTelegramDetailsButtonLoading,
  ] = useState(false);
  const [telegramDetailsRetrievalLoading, setTelegramDetailsRetrievalLoading] =
    useState(false);

  const columns = [
    {
      title: "Group Name",
      dataIndex: "Name",
      key: "Name",
      width: "50%",
    },
    {
      title: "Chat ID",
      dataIndex: "ChatID",
      key: "ChatID",
      width: "40%",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "10%",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-start" }}
        >
          <Button
            type="text"
            icon={<EditOutlined style={{ color: "blue" }} />}
            onClick={() => {
              setIsEditTelegramFormModalOpen(true);
              getEditingTelegramGroup(record.ID);
              setEditingAlarmTelegramID(record.ID);
            }}
          />
          <Popconfirm
            title="Delete Telegram"
            description={"Are you sure to delete this telegram?"}
            onConfirm={() => {
              deleteTelegramGroup(record.ID);
            }}
            onCancel={() => {
              message.success("Action is cancelled");
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  /** API to get all telegram data*/
  function getAllTelegramGroup() {
    setAlarmTelegramTableDataLoading(true);

    fetchWrapper({
      endpoint_url: "telegram_group_management/getAllTelegramGroup",
      method: "GET",
      params: {
        site_id: siteID,
      },
      set_content_type_header: false,
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setSelectedRowKeys(response.data.row_key_list);
        setAlarmTelegramTableData(response.data.telegram_group_details);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFetchError: (error) => {},
      onFinish: () => {
        setAlarmTelegramTableDataLoading(false);
      },
    });
  }

  /** API to add telegram data*/
  function addTelegramGroup(value) {
    setSubmitTelegramDetailsButtonLoading(true);

    fetchWrapper({
      endpoint_url: "telegram_group_management/addTelegramGroup",
      body: {
        site_id: siteID,
        telegram_group_name: value.Name,
        telegram_chat_id: value.ChatID,
      },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        alarmTelegramConfigForm.resetFields();
        setIsAddTelegramFormModalOpen(false);
        getAllTelegramGroup();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setSubmitTelegramDetailsButtonLoading(false);
      },
    });
  }

  /** API to get telegram that user choose to edit */
  function getEditingTelegramGroup(alarm_telegram_id) {
    setTelegramDetailsRetrievalLoading(true);

    fetchWrapper({
      endpoint_url: "telegram_group_management/getEditingTelegramGroup",
      method: "GET",
      params: {
        alarm_telegram_id: alarm_telegram_id,
      },
      set_content_type_header: false,

      onSuccess: (response) => {
        alarmTelegramConfigForm.setFieldsValue(response.data);
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFetchError: (error) => {},
      onFinish: () => {
        setTelegramDetailsRetrievalLoading(false);
      },
    });
  }

  /** API to update telegram that user choose to edit */
  function updateTelegramGroup(value) {
    setSubmitTelegramDetailsButtonLoading(true);

    fetchWrapper({
      endpoint_url: "telegram_group_management/updateTelegramGroup",
      body: {
        telegram_group_id: editingAlarmTelegramID,
        telegram_group_name: value.Name,
        telegram_chat_id: value.ChatID,
      },

      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setIsEditTelegramFormModalOpen(false);
        alarmTelegramConfigForm.resetFields();
        getAllTelegramGroup();
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFetchError: (error) => {},
      onFinish: () => {
        setSubmitTelegramDetailsButtonLoading(false);
      },
    });
  }

  /** API to delete telegram that user choose to delete */
  function deleteTelegramGroup(deletingAlarmTelegramID) {
    fetchWrapper({
      endpoint_url: "telegram_group_management/deleteTelegramGroup",
      method: "DELETE",
      set_content_type_header: false,
      params: { alarm_telegram_id: deletingAlarmTelegramID },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        getAllTelegramGroup();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  /** API to save telegram that user selects */
  function saveSelectedTelegramGroup() {
    setSaveSelectedTelegramsLoading(true);

    fetchWrapper({
      endpoint_url: "telegram_group_management/saveSelectedTelegramGroup",
      body: {
        selected_alarm_telegram_ids_list: selectedRowKeys,
        site_id: siteID,
      },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        getAllTelegramGroup();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => setSaveSelectedTelegramsLoading(false),
    });
  }

  /* API to check whether telegram group name is unique */
  function checkUniqueName(SiteID, Name, ID, callback) {
    fetchWrapper({
      endpoint_url: "telegram_group_management/checkUniqueTelegramGroupName",
      body: {
        site_id: SiteID,
        name: Name,
        telegram_group_id: ID,
      },
      onSuccess: (response) => {
        if (!response.data) {
          callback(response.message);
        } else {
          callback();
        }
      },
    });
  }

  useEffect(() => {
    if (siteID) {
      getAllTelegramGroup();
    }
  }, [siteID]);

  return (
    <>
      <div>
        <Title level={3} className="mt-0">
          Telegram Group
        </Title>
        <Flex justify="flex-start" align="center" wrap={true}>
          <Button
            icon={<PlusOutlined />}
            className="mb-5 mr-2"
            onClick={() => setIsAddTelegramFormModalOpen(true)}
          >
            New Telegram Group
          </Button>

          <Button
            icon={<SaveOutlined />}
            className="mb-5 mr-2"
            onClick={() => {
              saveSelectedTelegramGroup();
            }}
            loading={saveSelectedTelegramsLoading}
            telegramDetailsRetrievalLoading={telegramDetailsRetrievalLoading}
          >
            Save Selected Telegram Group
          </Button>
        </Flex>

        <Table
          loading={alarmTelegramTableDataLoading}
          columns={columns}
          dataSource={alarmTelegramTableData}
          pagination={{
            responsive: true,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: [3, 5, 10, 20, 50, 100],

            position: "bottomRight",
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          size="small"
          rowSelection={{
            ...rowSelection,
            checkStrictly: false,
          }}
        />

        <Modal
          centered
          title="Add New Telegram Group"
          open={isAddTelegramFormModalOpen}
          footer={null}
          closable={false}
          destroyOnClose={true}
          maskClosable={false}
        >
          <TelegramForm
            setIsTelegramFormModalOpen={setIsAddTelegramFormModalOpen}
            mode="add"
            addTelegramGroup={addTelegramGroup}
            form={alarmTelegramConfigForm}
            submitButtonLoading={submitTelegramDetailsButtonLoading}
            checkUniqueName={checkUniqueName}
            siteID={siteID}
          />
        </Modal>

        <Modal
          centered
          title="Edit Telegram Group"
          open={isEditTelegramFormModalOpen}
          footer={null}
          closable={false}
          destroyOnClose={true}
          maskClosable={false}
        >
          <TelegramForm
            setIsTelegramFormModalOpen={setIsEditTelegramFormModalOpen}
            mode="edit"
            updateTelegramGroup={updateTelegramGroup}
            form={alarmTelegramConfigForm}
            submitButtonLoading={submitTelegramDetailsButtonLoading}
            telegramDetailsRetrievalLoading={telegramDetailsRetrievalLoading}
            checkUniqueName={checkUniqueName}
            selectedEditTelegramGroupID={editingAlarmTelegramID}
            siteID={siteID}
          />
        </Modal>
      </div>
    </>
  );
}

import { ExclamationCircleOutlined, StopOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Select, Space, Tag, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";

const { Text } = Typography;

export default function AlarmDetailForm({ alarm, form }) {
  return (
    <>
      <Form form={form} layout="vertical" initialValues={alarm} clearOnDestroy>
        <Row gutter={[18, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item name="start" label="Start Datetime">
              <Input readOnly></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item name="end" label="End Datetime">
              <Input readOnly placeholder="-"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="parameter-name"
              initialValue={`${alarm.parameter_group} - ${alarm.parameter}`}
              label="Parameter"
            >
              <Input readOnly></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item name="level" label="Level">
              <Select
                readOnly
                labelRender={({ value, label }) => {
                  if (value == "Warning")
                    return (
                      <Tag color="var(--ant-color-warning)">
                        <ExclamationCircleOutlined></ExclamationCircleOutlined>
                        <Text style={{ color: "white" }}>{value}</Text>
                      </Tag>
                    );
                  else if (value == "Error")
                    return (
                      <Tag color="var(--ant-color-error)">
                        <StopOutlined></StopOutlined>
                        <Text style={{ color: "white" }}>{value}</Text>
                      </Tag>
                    );
                  else return <>{value}</>;
                }}
                dropdownRender={() => null}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item name="value" label="Value">
              <Input readOnly></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Set Point">
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item name="operator" noStyle>
                  <Input readOnly></Input>
                </Form.Item>
                <Form.Item name="setpoint" noStyle>
                  <Input readOnly></Input>
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="message" label="Message">
              <TextArea
                readOnly
                autoSize={{ minRows: 2, maxRows: 3 }}
              ></TextArea>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item name="acknowledged_by" label="Ackonwledged By">
              <Input readOnly placeholder="-"></Input>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item name="acknowledged_datetime" label="Ackonwledged On">
              <Input readOnly placeholder="-"></Input>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="remark" label="Remark" disabled={false}>
              <TextArea
                maxLength={150}
                autoSize={{ minRows: 3, maxRows: 5 }}
              ></TextArea>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

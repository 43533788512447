// library imports
import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider, message, notification } from "antd";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// style imports
import "./styles/index.css";
// config imports
import { CONFIGS } from "./configs/configs";
// context imports
import { IndexContext } from "./context/IndexContext";
// hook imports
import useThemeSwitcher from "./hooks/useThemeSwitcher";
// component/page imports
import MainLayout from "./layout/MainLayout";
import DashboardPage from "./pages/dashboard/DashboardPage";
import LoginPage from "./pages/login/LoginPage";
import UserProfilePage from "./pages/account/UserProfilePage";
import ChangePassword from "./pages/account/ChangePassword";
import System from "./pages/system/System";
import ConfigurationPage from "./pages/configuration/ConfigurationPage";
import ConfigurationSitePage from "./pages/configuration/site/ConfigurationSitePage";
import AIBESSPage from "./pages/features/AIBESSPage";
import SavingReportPage from "./pages/saving_report/SavingReportPage";
import SystemReportPage from "./pages/system_report/SystemReportPage";
import EnergyDemandPage from "./pages/analytics/EnergyDemandPage";
import PowerDemandPage from "./pages/analytics/PowerDemandPage";
import ParameterTrendingPage from "./pages/analytics/ParameterTrendingPage";
import AlarmPage from "./pages/alarm/AlarmPage";
import SiteListPage from "./pages/site_list/SiteListPage";
import STReportPage from "./pages/st_report/STReportPage";

const App = () => {
  // load theme switcher
  const [appTheme, updateTheme, isLightTheme] = useThemeSwitcher();

  // for triggering message
  const [messageApi, messageApiContext] = message.useMessage();

  // for triggering notification
  const [notificationApi, notificationApiContext] =
    notification.useNotification();

  return (
    <ConfigProvider theme={{ ...appTheme }}>
      {messageApiContext}
      {notificationApiContext}
      <IndexContext.Provider
        value={{
          messageApi: messageApi,
          notificationApi: notificationApi,
          isLightTheme: isLightTheme,
          updateTheme: updateTheme,
        }}
      >
        <BrowserRouter basename={CONFIGS.route_basename}>
          <Routes>
            {/* Login Route */}
            <Route path="login" element={<LoginPage />}></Route>
            {/* All content pages */}
            <Route element={<MainLayout updateTheme={updateTheme} />}>
              {/* Route for Site List */}
              <Route path="SiteList" element={<SiteListPage />}></Route>

              {/* Route for Dashboard */}
              <Route path="Dashboard" element={<DashboardPage />}></Route>

              {/* Sub route for Analytics */}
              <Route path="Analytics">
                <Route path="PowerDemand" element={<PowerDemandPage />}></Route>
                <Route
                  path="EnergyDemand"
                  element={<EnergyDemandPage />}
                ></Route>
                <Route
                  path="Parameter"
                  element={<ParameterTrendingPage />}
                ></Route>
              </Route>

              {/* Route for System */}
              <Route path="System" element={<System />}></Route>

              {/* Sub route for Alarms */}
              <Route path="Alarm">
                <Route path="AlarmRecord" element={<AlarmPage />}></Route>
              </Route>

              {/* Sub route for Reports */}
              <Route path="Reports">
                <Route
                  path="SystemReport"
                  element={<SystemReportPage />}
                ></Route>
                <Route path="STReport" element={<STReportPage />}></Route>
                <Route
                  path="SavingReport"
                  element={<SavingReportPage />}
                ></Route>
              </Route>

              {/* Sub route for Management */}
              <Route path="Management">
                {/* <Route
                  path="Configuration"
                  element={<ConfigurationPage />}
                ></Route> */}
                {/* Sub route for Configuration */}
                <Route path="Configuration">
                  <Route path="" element={<ConfigurationPage />}></Route>
                  <Route
                    path="Site/:siteID"
                    element={<ConfigurationSitePage />}
                  ></Route>
                </Route>
              </Route>

              {/* Sub route for Features */}
              <Route path="Features">
                <Route path="AIBESS" element={<AIBESSPage />}></Route>
              </Route>

              {/* Sub route for Account */}
              <Route path="Account">
                <Route path="UserProfile" element={<UserProfilePage />}></Route>
                <Route
                  path="ChangePassword"
                  element={<ChangePassword />}
                ></Route>
              </Route>
            </Route>
            {/* Default all unknown route to login page */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </BrowserRouter>
      </IndexContext.Provider>
    </ConfigProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);

import {
  DeleteOutlined,
  EditOutlined,
  ExceptionOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Flex,
  Modal,
  Typography,
  Tooltip,
  Popconfirm,
  Skeleton,
  Tag,
  Badge,
  Space,
  message,
} from "antd";
import { useMemo, useEffect, useState, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";
import DataTable from "../../common/DataTable";
import { useTableSearch } from "../../../functions/tableSearch";
import DeviceAlarmSettingsForm from "./DeviceAlarmSettingsForm";

const { Title } = Typography;

export default function DeviceAlarmSettingsView(props) {
  const { siteID } = props;

  const { messageApi, notificationApi } = useContext(IndexContext);
  const [fetchWrapper] = useFetch();

  const [loading, setLoading] = useState(false);

  const [newDeviceAlarmModalOpen, setNewDeviceAlarmModal] = useState(false);
  const [editDeviceAlarmModalOpen, setEditDeviceAlarmModalOpen] =
    useState(false);

  const [deviceAlarms, setDeviceAlarms] = useState([]);
  const [selectedDeviceAlarm, setSelectedDeviceAlarm] = useState(null);

  const { getColumnSearchProps } = useTableSearch();

  const columns = [
    {
      title: "Device",
      dataIndex: "Device",
      key: "Device",
      ...getColumnSearchProps("Device"),
    },
    {
      title: "Parameter",
      dataIndex: "Parameter",
      key: "Parameter",
      render: (text, record) => {
        return (
          <>
            {record.ParameterGroupName} - {text}
          </>
        );
      },
    },
    {
      title: "Level",
      dataIndex: "Level",
      key: "Level",
      render: (value, record) => {
        if (value == 1)
          return (
            <Tag
              color="var(--ant-color-warning)"
              icon={<ExclamationCircleOutlined />}
            >
              Warning
            </Tag>
          );
        else if (value == 2)
          return (
            <Tag color="var(--ant-color-error)" icon={<StopOutlined />}>
              Error
            </Tag>
          );
        else return <Tag>Unknown</Tag>;
      },
    },
    {
      title: "Setpoint",
      dataIndex: "SetPoint",
      key: "SetPoint",
      render: (text, record) => {
        return (
          <>
            {record.Operator} {record.SetPoint}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "ID",
      key: "ID",
      width: 100,
      render: (_, record) => (
        <Flex gap={8}>
          <div className="mr-2">
            <Tooltip title="Edit">
              <EditOutlined
                style={{ color: "blue" }}
                onClick={() => {
                  setEditDeviceAlarmModalOpen(true);
                  setSelectedDeviceAlarm(record);
                }}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete">
              <Popconfirm
                icon={<DeleteOutlined style={{ color: "red" }} />}
                title="Delete"
                description={"Are you sure to delete this device alarm?"}
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  deleteDeviceAlarm(record.ID);
                }}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
            </Tooltip>
          </div>
        </Flex>
      ),
    },
  ];

  function getDeviceAlarms() {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "device_alarm_settings_management/getDeviceAlarms",
      method: "GET",
      set_content_type_header: false,
      params: { SiteID: siteID },
      onSuccess: (response) => {
        setDeviceAlarms(response.data);
      },
      onResponseError: () => {},
      onFinish: () => {
        setLoading(false);
      },
    });
  }

  /* API to delete power meter data */
  function deleteDeviceAlarm(ID) {
    fetchWrapper({
      endpoint_url: "device_alarm_settings_management/deleteDeviceAlarm",
      method: "DELETE",
      params: {
        ID: ID,
      },
      set_content_type_header: false,
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        getDeviceAlarms();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  useEffect(() => {
    if (siteID) {
      getDeviceAlarms();
    }
  }, [siteID]);

  return (
    <>
      <div>
        <Title level={3} className="mt-0">
          Device Alarm Settings
        </Title>
        <Flex justify="flex-start" align="center" wrap={true}>
          <Button
            icon={<PlusOutlined />}
            className="mb-5 mr-2"
            onClick={() => setNewDeviceAlarmModal(true)}
          >
            New Device Alarm Settings
          </Button>
        </Flex>
        {loading ? (
          <Skeleton />
        ) : (
          <DataTable
            table_column={columns}
            table_data={deviceAlarms}
            scroll_width={700}
          />
        )}

        <Modal
          centered
          open={newDeviceAlarmModalOpen}
          title="New Device Alarm Settings"
          footer={false}
          width={"90vw"}
          destroyOnClose={true}
          maskClosable={false}
          closable={false}
          style={{ height: "70vh", maxWidth: "1000px" }}
        >
          <DeviceAlarmSettingsForm
            siteID={siteID}
            onCancel={() => setNewDeviceAlarmModal(false)}
            onSuccessSubmit={() => {
              getDeviceAlarms();
              setNewDeviceAlarmModal(false);
            }}
          />
        </Modal>
        <Modal
          centered
          open={editDeviceAlarmModalOpen}
          title="Edit Device Alarm Settings"
          footer={false}
          width={"90vw"}
          destroyOnClose={true}
          maskClosable={false}
          closable={false}
          style={{ height: "70vh", maxWidth: "1000px" }}
        >
          <DeviceAlarmSettingsForm
            siteID={siteID}
            ID={selectedDeviceAlarm?.ID}
            defaultValue={{ ...selectedDeviceAlarm }}
            onCancel={() => setEditDeviceAlarmModalOpen(false)}
            onSuccessSubmit={() => {
              getDeviceAlarms();
              setEditDeviceAlarmModalOpen(false);
            }}
          />
        </Modal>
      </div>
    </>
  );
}

// library imports
import { Card, DatePicker, Button } from "antd";
import { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
// style imports
import "../../styles/STReport/STReportPage.css";
// context imports
import { IndexContext } from "../../context/IndexContext";
// component imports
import { _STReportRow } from "./_STReportRow";
import { _CompactInput } from "./_CompactInput";
// hook imports
import useFetch from "../../hooks/useFetch";
import { formatToTwoDecimalPlaces } from "../../functions/formatToTwoDecimalPlaces";

const BULLET_POINT = "\xa0\xa0•\xa0\xa0";

export default function STReportPage() {
  const [fetchWrapper] = useFetch();
  const { notificationApi, siteSelected } = useContext(IndexContext);

  const [isLoading, setIsLoading] = useState(false);
  const [tariffRateInput, setTariffRateInput] = useState(false);
  const [displacedCostInput, setDisplaceCostInput] = useState(false);
  const [energyImportedInput, setEnergyImportedInput] = useState(false);
  const [energyExportedInput, setEnergyExportedInput] = useState(false);

  const [monthSelection, setMonthSelection] = useState(dayjs());
  const [siteReportData, setSiteReportData] = useState({
    energy_imported: "-",
    energy_consumed: "-",
    energy_exported: "-",
    total_yield: "-",
    tariff_type: "-",
    tariff_rate: "-",
    displace_cost: "-",
    savings_energy_sold: "-",
    savings_energy_consumed: "-",
    savings_total: "-",
    maximum_demand: "-",
  });

  const changeEnergyImported = (value) =>
    setSiteReportData((prev) => ({ ...prev, energy_imported: value }));
  const changeEnergyExported = (value) =>
    setSiteReportData((prev) => ({ ...prev, energy_exported: value }));
  const changeTariffRate = (value) =>
    setSiteReportData((prev) => ({ ...prev, tariff_rate: value }));
  const changeDisplaceCost = (value) =>
    setSiteReportData((prev) => ({ ...prev, displace_cost: value }));

  const disabledDate = (current) => {
    return current && current.isAfter(dayjs().endOf("month"));
  };

  const onTariffRateEdit = () => {
    setTariffRateInput(!tariffRateInput);
  };

  const onDisplaceCostInput = () => {
    setDisplaceCostInput(!displacedCostInput);
  };

  const onEnergyImportedInput = () => {
    setEnergyImportedInput(!energyImportedInput);
  };

  const onEnergyExportedInput = () => {
    setEnergyExportedInput(!energyExportedInput);
  };

  const onSubmitClick = () => {
    getSTReportData();
  };

  const onResetClick = () => {
    setSiteReportData({
      energy_imported: "-",
      energy_consumed: "-",
      energy_exported: "-",
      total_yield: "-",
      tariff_type: "-",
      tariff_rate: "-",
      displace_cost: "-",
      savings_energy_sold: "-",
      savings_energy_consumed: "-",
      savings_total: "-",
      maximum_demand: "-",
    });
  };

  function getSTReportData() {
    setIsLoading(true);
    fetchWrapper({
      endpoint_url: "st_report/getSTReportData",
      method: "POST",
      body: {
        site_id: siteSelected,
        start_date: monthSelection
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        site_report_data: siteReportData,
      },
      onSuccess: (response) => {
        setSiteReportData(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setIsLoading(false);
      },
    });
  }

  useEffect(() => {
    onResetClick();
  }, [monthSelection, siteSelected]);

  return (
    <Card
      className="st-report-card"
      title={
        <div className="st-report-title">
          <span>Suruhanjaya Tenaga Monthly Report</span>
          <DatePicker
            format="YYYY-MM"
            value={monthSelection}
            allowClear={false}
            picker="month"
            onChange={(date) => setMonthSelection(date)}
            disabledDate={disabledDate}
          />
        </div>
      }
      loading={isLoading}
    >
      <div className="st-report-card-content">
        <_STReportRow
          title={"Billing Period"}
          value={
            <span>
              {monthSelection.startOf("month").format("YYYY-MM-DD")}
              {`\xa0to\xa0`}
              {monthSelection.endOf("month").format("YYYY-MM-DD")}
            </span>
          }
        />
        <_STReportRow
          title={"Energy imported from TNB/SESB"}
          value={
            energyImportedInput ? (
              <_CompactInput
                value={siteReportData.energy_imported}
                setValue={changeEnergyImported}
                unit={"kWh"}
              />
            ) : (
              `${formatToTwoDecimalPlaces(siteReportData.energy_imported)} kWh`
            )
          }
          additional={
            <Button size="small" onClick={() => onEnergyImportedInput()}>
              {energyImportedInput ? "Complete" : "Edit"}
            </Button>
          }
        />
        <_STReportRow
          title={"Energy consumed from solar PV (NEM)"}
          value={`${formatToTwoDecimalPlaces(
            siteReportData.energy_consumed
          )} kWh`}
        />
        <_STReportRow
          title={"Energy exported/sold to TNB/SESB"}
          value={
            energyExportedInput ? (
              <_CompactInput
                value={siteReportData.energy_exported}
                setValue={changeEnergyExported}
                unit={"kWh"}
              />
            ) : (
              `${formatToTwoDecimalPlaces(siteReportData.energy_exported)} kWh`
            )
          }
          additional={
            <Button size="small" onClick={() => onEnergyExportedInput()}>
              {energyExportedInput ? "Complete" : "Edit"}
            </Button>
          }
        />
        <_STReportRow
          title={"Total Yield from Solar PV (NEM)"}
          value={`${formatToTwoDecimalPlaces(siteReportData.total_yield)} kWh`}
        />
        <_STReportRow
          title={"Tariff Type for TNB"}
          value={siteReportData.tariff_type}
        />
        <_STReportRow
          title={"Tariff Rate"}
          value={
            tariffRateInput ? (
              <_CompactInput
                value={siteReportData.tariff_rate}
                setValue={changeTariffRate}
                unit={"RM/kWh"}
              />
            ) : (
              `${siteReportData.tariff_rate} RM/kWh`
            )
          }
          additional={
            <Button size="small" onClick={() => onTariffRateEdit()}>
              {tariffRateInput ? "Complete" : "Edit"}
            </Button>
          }
        />
        <_STReportRow
          title={"Displaced Cost"}
          value={
            displacedCostInput ? (
              <_CompactInput
                value={siteReportData.displace_cost}
                setValue={changeDisplaceCost}
                unit={"RM/kWh"}
              />
            ) : (
              `${siteReportData.displace_cost} RM/kWh`
            )
          }
          additional={
            <Button size="small" onClick={() => onDisplaceCostInput()}>
              {displacedCostInput ? "Complete" : "Edit"}
            </Button>
          }
        />
        <_STReportRow title={"Savings Calculations"} value={false} />
        <_STReportRow
          title={`${BULLET_POINT} Energy sold TNB/SESB`}
          value={`RM ${formatToTwoDecimalPlaces(
            siteReportData.savings_energy_sold
          )}`}
        />
        <_STReportRow
          title={`${BULLET_POINT} Energy consumed from solar PV`}
          value={`RM ${formatToTwoDecimalPlaces(
            siteReportData.savings_energy_consumed
          )}`}
        />

        <_STReportRow
          title={`${BULLET_POINT} Total Savings`}
          value={`RM ${formatToTwoDecimalPlaces(siteReportData.savings_total)}`}
        />
        <_STReportRow
          title={"Maximum Demand"}
          value={`${formatToTwoDecimalPlaces(
            siteReportData.maximum_demand
          )} kW`}
          lastRow={true}
        />
      </div>
      <div className="st-report-reset-button-container">
        <div>
          <Button
            className="st-report-submit-button"
            onClick={onSubmitClick}
            disabled={
              tariffRateInput ||
              displacedCostInput ||
              energyImportedInput ||
              energyExportedInput
            }
          >
            Calculate
          </Button>
          <Button className="st-report-reset-button" onClick={onResetClick}>
            Reset
          </Button>
        </div>
      </div>
    </Card>
  );
}

// library imports
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Tooltip,
  Modal,
  Divider,
  Flex,
  Popconfirm,
  Spin,
  DatePicker,
} from "antd";
import { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
// function imports
import { useTableSearch } from "../../../functions/tableSearch";
// component imports
import DataTable from "../../../components/common/DataTable";
import SmpForm from "./SmpForm";
// context imports
import { IndexContext } from "../../../context/IndexContext";
// hook imports
import useFetch from "../../../hooks/useFetch";

export default function SmpView() {
  const [fetchWrapper] = useFetch();

  const { getColumnSearchProps } = useTableSearch();

  // index context
  const { notificationApi } = useContext(IndexContext);

  // loading states
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false); // loading for table

  // modal states
  const [isAddSmpModalOpen, setIsAddSmpModalOpen] = useState(false);
  const [isEditSmpModalOpen, setIsEditSmpModalOpen] = useState(false);
  const [selectedSmp, setSelectedSmp] = useState({});

  // table data
  const [smpDetails, setSmpDetails] = useState([]);

  // selection for year
  const [selectedYear, setSelectedYear] = useState(dayjs());

  const smpTableColumns = [
    {
      title: "PV Scheme",
      dataIndex: "PVScheme",
      key: "PVScheme",
      width: "25%",
      ...getColumnSearchProps("PVScheme"),
    },
    {
      title: "Month",
      dataIndex: "MonthYearTable",
      key: "MonthYearTable",
      width: "25%",
    },
    {
      title: "SMP Value",
      dataIndex: "Value",
      key: "Value",
      width: "25%",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "25%",
      render: (_, record) => (
        <Flex justify="flex-start" align="center" gap={"5px"} wrap={true}>
          <Tooltip title="Edit">
            <Button
              type="text"
              icon={<EditOutlined style={{ color: "blue" }} />}
              onClick={() => {
                setIsEditSmpModalOpen(true);
                setSelectedSmp(record);
              }}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              title="Delete SMP"
              description={"Are you sure to delete this SMP?"}
              onConfirm={() => {
                deleteSmp(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="text"
                icon={<DeleteOutlined style={{ color: "red" }} />}
              />
            </Popconfirm>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  /* API to get smp details */
  function getSmpDetails() {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "smp_management/getSmpDetails",
      method: "GET",
      set_content_type_header: false,
      params: {
        selected_year: selectedYear.startOf("year").format("YYYY-MM-DD"),
      },

      onSuccess: (response) => {
        setSmpDetails(response.data);
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
        setFirstLoad(false);
      },
    });
  }

  function deleteSmp(row_record) {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "smp_management/deleteSmp",
      body: {
        smp_id: row_record.ID,
      },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        getSmpDetails();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    getSmpDetails();
  }, [selectedYear]);

  return (
    <>
      {firstLoad ? (
        <Flex justify="center" align="center" style={{ height: "50vh" }}>
          <Spin />
        </Flex>
      ) : (
        <>
          <Flex>
            <DatePicker
              className="mb-5 mr-3"
              picker="year"
              value={selectedYear}
              onChange={(date) => {
                setSelectedYear(date);
              }}
            />

            <Button
              icon={<PlusOutlined />}
              className="mb-5"
              onClick={() => setIsAddSmpModalOpen(true)}
            >
              New SMP
            </Button>
          </Flex>

          {loading ? (
            <Flex justify="center" align="center" style={{ height: "50vh" }}>
              <Spin />
            </Flex>
          ) : (
            <DataTable table_column={smpTableColumns} table_data={smpDetails} />
          )}
        </>
      )}

      <Modal
        title="Add SMP"
        open={isAddSmpModalOpen}
        footer={null}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Divider className="mb-4 mt-0"></Divider>
        <SmpForm
          mode="add"
          setIsSmpFormModalOpen={setIsAddSmpModalOpen}
          refreshTable={getSmpDetails}
          selectedYear={selectedYear}
        />
      </Modal>

      <Modal
        title="Edit SMP"
        open={isEditSmpModalOpen}
        footer={null}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Divider className="mb-4 mt-0"></Divider>
        <SmpForm
          mode="edit"
          setIsSmpFormModalOpen={setIsEditSmpModalOpen}
          isSmpForm={false}
          refreshTable={getSmpDetails}
          initialValues={selectedSmp}
          selectedYear={selectedYear}
        />
      </Modal>
    </>
  );
}

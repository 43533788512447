// library imports
import { useEffect, useState, useContext } from "react";
// hook imports
import useFetch from "../../../hooks/useFetch";
// context imports
import { IndexContext } from "../../../context/IndexContext";
// config imports
import { MQTT_REFRESH_RATE } from "../../../configs/configs";
// component import
import LoadUsageContent from "./LoadUsageContent";

export default function LoadUsage() {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  const [systemData, setSystemData] = useState({});
  const [firstLoad, setFirstLoad] = useState(true);

  const [systemLastUpdate, setSystemLastUpdate] = useState("-");

  /** API to get PV system data */
  function getSystemData() {
    fetchWrapper({
      endpoint_url: "system/getSystemLoad",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        if (response.data === false) {
          notificationApi.warning({
            message: response.message,
          });
        } else {
          setSystemData(response.data.system_data);
          setSystemLastUpdate(response.data.last_update);
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setFirstLoad(false);
      },
    });
  }

  useEffect(() => {
    getSystemData();

    const timer = setInterval(() => {
      getSystemData();
    }, MQTT_REFRESH_RATE * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [siteSelected]);

  return (
    <LoadUsageContent
      systemData={systemData}
      firstLoad={firstLoad}
      systemLastUpdate={systemLastUpdate}
    />
  );
}

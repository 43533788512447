// library imports
import { Table, Button } from "antd";
import { useState, useEffect, useContext } from "react";
import {
  LoadingOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
// style imports
import "../../styles/SystemReport/DailyReportContent.css";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";
// component imports
import { HorizontalDateMonthYearFilter } from "../common/DateFilter";

const { Column, ColumnGroup } = Table;

function DailyReportContent() {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [dateSelection, setDateSelection] = useState(dayjs());
  const [data, setData] = useState([]);

  const formatToTwoDecimalPlaces = (value) => {
    if (value === undefined || value === null || value === "-") {
      return value;
    }
    return value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  /** API to get daily report */
  function getDailyReportData() {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "daily_report/getDailyReportData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        date_graph: dateSelection.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },

      onSuccess: (response) => {
        setData(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setLoading(false);
      },
    });
  }

  /** API to download daily report */
  function downloadDailyReport() {
    setSaveLoading(true);

    fetchWrapper({
      endpoint_url: "daily_report/downloadDailyReport",
      method: "POST",
      json_output: false,
      body: {
        table_data: data,
      },

      onSuccess: (response) => {
        if (response.headers.get("content-type") !== "application/json") {
          // no issue with the response. returned a spreadsheet
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `DailyReport_${dayjs().format(
              "YYYYMMDD_HHmmss"
            )}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          });
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: "Failed to download file",
        });
      },

      onFinish: () => {
        setSaveLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteSelected) {
      getDailyReportData();
    }
  }, [siteSelected, dateSelection]);

  return (
    <>
      <div className="daily-report-date-selection">
        <Button
          className="daily-report-download-button"
          onClick={() => {
            downloadDailyReport();
          }}
          disabled={loading}
        >
          {saveLoading ? <LoadingOutlined /> : <VerticalAlignBottomOutlined />}
        </Button>
        <HorizontalDateMonthYearFilter
          startDateSelection={dateSelection}
          setStartDateSelection={setDateSelection}
          onFilter={() => {}}
          pickerType={"month"}
          showTitle={false}
        />
      </div>
      <Table bordered dataSource={data} scroll={{ x: 800 }} loading={loading}>
        <Column title="Date" dataIndex="date" key="date" />
        <Column
          title="Energy Generation (kWh)"
          dataIndex="energyGeneration"
          key="energyGeneration"
          render={(value) => formatToTwoDecimalPlaces(value)}
        />
        <ColumnGroup title="Energy Utilization">
          <Column
            title="PV Self Consumption (kWh)"
            dataIndex="pvSelfConsumption"
            key="pvSelfConsumption"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
          <Column
            title="Self Consumption Ratio"
            dataIndex="selfConsumptionRatio"
            key="selfConsumptionRatio"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
          <Column
            title="PV Export (kWh)"
            dataIndex="pvExport"
            key="pvExport"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
          <Column
            title="PV Energy Export Ratio"
            dataIndex="pvEnergyExportRatio"
            key="pvEnergyExportRatio"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
          <Column
            title="Import From Grid (kWh)"
            dataIndex="importFromGrid"
            key="importFromGrid"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
        </ColumnGroup>
      </Table>
    </>
  );
}

export default DailyReportContent;

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IndexContext } from "../context/IndexContext";
import { CONFIGS } from "../configs/configs";
import getBrowserFingerprint from "get-browser-fingerprint";

export default function useFetch() {
  // load index context
  const { notificationApi } = useContext(IndexContext);

  let navigate = useNavigate(); //for navigating

  // the common fetch wrapper function for fetching data
  /**
   * fetchWrapper for handling most fetch operation. Wrap around default fetch to execute any necessary operation for ASPL web application.
   * auto append backend url. require endpoint route only
   *
   * @param {{ endpoint_url: any; method?: string; content_type?: string; params?: {}; additional_request_options?: {}; onSuccess?: () => void; onResponseError?: () => void; onFetchError?: () => void;  onFinish?: () => void; show_error_notification?: boolean; json_output?: boolean; try_refresh_token?: boolean; set_content_type_header?: boolean; body?: {}; }} param0
   * @param {*} param0.endpoint_url the endpoint of the url for post
   * @param {string} [param0.method='POST'] the fetch method
   * @param {{}} [param0.params={}] the param for this input. if POST is body, GET is query params.
   * @param {{}} [param0.content_type={}] the content type for this fetch.
   * @param {{}} [param0.additional_request_options={}] only additional request options. not replacing any options that are generated by this function.
   * @param {() => void} [param0.onSuccess=() => { }] function called when fetch called success with OK response
   * @param {() => void} [param0.onResponseError=() => { }] function called when fetch called success but with not OK response
   * @param {() => void} [param0.onFetchError=() => { }]  function called when fetch failed
   * @param {() => void} [param0.onFinish=() => { }]  function called when end
   * @param {boolean} [param0.show_error_notification=false]  Whether to show notification when there is response error and fetch error
   * @param {boolean} [param0.json_output=true] Whether need to convert response output to json before executing onSuccess
   * @param {boolean} [param0.try_refresh_token=true] Whether want to refresh token if 401 is detected.
   * @param {boolean} [param0.set_content_type_header=true] for whether setting content_type_header, typically used when upload/downloading files
   * @param {{}} [param0.body=null] special parameter for when need to specify the body of fetch
   * @returns
   */
  function fetchWrapper({
    endpoint_url,
    method = "POST",
    params = {},
    content_type = "application/json",
    additional_request_options = {},
    onSuccess = () => {},
    onResponseError = () => {},
    onFetchError = () => {},
    onFinish = () => {},
    show_error_notification = false,
    json_output = true,
    try_refresh_token = true,
    set_content_type_header = true,
    body = null,
  }) {
    // prepare the fetch_url
    let fetch_url = CONFIGS.backend_url + endpoint_url;

    // prepare the header
    let headers = new Headers();
    if (set_content_type_header) {
      headers.append("Content-Type", content_type);
    }

    // append the CSRF token into the header based on availability in cookies
    // append access token if available, else refresh token. If both doesnt exists, append nothing
    let csrf_token = null;
    // if refreshing user, always get the refresh token csrf
    if (endpoint_url === CONFIGS.refresh_token_url) {
      csrf_token = getCookie("csrf_refresh_token");
    }
    // else, get the access token csrf
    else if (getCookie("csrf_access_token") !== undefined) {
      csrf_token = getCookie("csrf_access_token");
    }

    if (csrf_token !== null) {
      headers.append("X-CSRF-TOKEN", csrf_token);
    }

    // prepare the request options
    let request_options = {
      method: method,
      headers: headers,
      credentials: "include", // for cross-site cookies
      ...additional_request_options,
    };
    if (
      (method === "PUT" || method === "GET" || method === "DELETE") &&
      Object.keys(params).length > 0
    ) {
      // append the params as query params
      let query_params = [];
      Object.keys(params).forEach((key) => {
        const value = params[key];
        query_params.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        );
      });
      fetch_url = fetch_url + "?" + query_params.join("&");
    } else if (method === "POST") {
      if (body === null) {
        request_options["body"] = JSON.stringify(params);
      } else {
        if (content_type === "application/json" && set_content_type_header) {
          request_options["body"] = JSON.stringify(body);
        } else {
          request_options["body"] = body;
        }
      }
    }

    // call the fetch
    fetch(fetch_url, request_options)
      .then((response) => {
        if (response.ok) {
          // check if needs to get only the json output.
          if (json_output) {
            // call onSuccess after getting the json data (default behaviour)
            response.json().then((data) => {
              // success after json.
              onSuccess(data);
            });
          } else {
            // call onSuccess passing the whole response directly.
            onSuccess(response);
          }
        } else {
          // check if 401.
          if (response.status === 401) {
            // try call refresh if refresh token exists
            if (try_refresh_token) {
              // try refresh token.
              refreshToken().then((refresh_status) => {
                if (refresh_status) {
                  // refresh success. call fetchWrapper again
                  fetchWrapper({
                    endpoint_url: endpoint_url,
                    method: method,
                    params: params,
                    additional_request_options: additional_request_options,
                    onSuccess: onSuccess,
                    onResponseError: onResponseError,
                    onFetchError: onFetchError,
                    show_error_notification: show_error_notification,
                    json_output: json_output,
                    try_refresh_token: try_refresh_token,
                  });
                } else {
                  onResponseError(response);
                  onFetchError({ status: refresh_status });
                  // refresh failed. considered as unauthorized. redirect to login
                  navigate("/login", { replace: true }); // navigate to login page.
                }
              });
            } else {
              // dont refresh token and unauthorized. redirect to login
              navigate("/login", { replace: true }); // navigate to login page.
              onResponseError(response);
            }
          } else {
            // other response code incorrect.
            response.json().then((error) => {
              onResponseError(error);
            });
            console.log(`Error ${response.status} (${response.statusText}).`);

            if (show_error_notification) {
              notificationApi["error"]({
                message: "Fetch Response Error",
                description: `Error ${response.status} (${response.statusText})`,
              });
            }
          }
        }
      })
      .catch((error) => {
        // something error during the fetch
        onFetchError(error);
        console.log(error);

        if (show_error_notification) {
          notificationApi["error"]({
            message: "Fetch Fatal Error",
            description: `${error.message}`,
          });
        }
      })
      .finally(() => {
        onFinish();
      });
  }

  // function to refresh token
  function refreshToken() {
    // browser fingerprint
    const fingerprint = getBrowserFingerprint({
      hardwareOnly: true,
      enableScreen: false,
    });

    return new Promise((resolve, reject) => {
      fetchWrapper({
        endpoint_url: CONFIGS.refresh_token_url,
        params: {
          fingerprint: fingerprint,
        },
        onSuccess: (refresh_status) => {
          if (refresh_status.status) {
            // refresh success
            resolve(true);
          } else {
            resolve(false);
          }
        },
        onFetchError: () => {
          // failed to refresh
          resolve(false);
        },
        onResponseError: () => {
          // failed to refresh
          resolve(false);
        },
        try_refresh_token: false, // disable this to prevent indefinite loop
      });
    });
  }

  return [fetchWrapper];
}

// for getting specific key in cookies
function getCookie(key) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

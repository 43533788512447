import {
  Button,
  Row,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from "antd";
import { lazy, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import {
  ExclamationCircleOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { IndexContext } from "../../../context/IndexContext";

export default function DeviceAlarmSettingsForm({
  siteID,
  ID = null,
  defaultValue = null,
  onCancel = () => {},
  onSuccessSubmit = () => {},
}) {
  const { notificationApi } = useContext(IndexContext);
  const [fetchWrapper] = useFetch();
  const [form] = Form.useForm();
  const [deviceSelection, setDeviceSelection] = useState(null);
  const [parameterSelection, setParameterSelection] = useState(null);
  const [operatorSelection, setOperatorSelection] = useState(null);
  const [levelSelection, setLevelSelection] = useState(null);
  const [loading, setLoading] = useState(false);

  function onSubmit(value) {
    setLoading(true);
    if (ID == null) {
      fetchWrapper({
        endpoint_url: "device_alarm_settings_management/addDeviceAlarm",
        body: {
          SiteID: siteID,
          ...value,
        },
        method: "POST",
        onSuccess: (response) => {
          notificationApi.success({
            message: response.message,
          });
          onSuccessSubmit();
        },
        onResponseError: (response) => {
          notificationApi.error({
            message: response.message,
          });
        },
        onFinish: () => {
          setLoading(false);
        },
      });
    } else {
      fetchWrapper({
        endpoint_url: "device_alarm_settings_management/editDeviceAlarm",
        body: {
          ID: ID,
          ...value,
        },
        method: "POST",
        // set_content_type_header: false,
        onSuccess: (response) => {
          notificationApi.success({
            message: response.message,
          });
          onSuccessSubmit();
        },
        onResponseError: (response) => {
          notificationApi.error({
            message: response.message,
          });
        },
        onFinish: () => {
          setLoading(false);
        },
      });
    }
  }

  function getOperatorSelection() {
    fetchWrapper({
      endpoint_url: "device_alarm_settings_management/getOperatorSelection",
      method: "GET",
      set_content_type_header: false,
      onSuccess: (response) => {
        setOperatorSelection(response.data.operator);
        setLevelSelection(response.data.level);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
        setOperatorSelection(null);
      },
    });
  }

  function getDeviceSelection() {
    fetchWrapper({
      endpoint_url: "device_alarm_settings_management/getDeviceSelection",
      method: "GET",
      params: { SiteID: siteID },
      set_content_type_header: false,
      onSuccess: (response) => {
        setDeviceSelection(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
        setDeviceSelection(null);
      },
    });
  }

  function getParameterSelection(deviceID) {
    fetchWrapper({
      endpoint_url:
        "device_alarm_settings_management/getDeviceParameterSelection",
      method: "GET",
      set_content_type_header: false,
      params: { DeviceID: deviceID },
      onSuccess: (response) => {
        setParameterSelection(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
        setParameterSelection(null);
      },
    });
  }

  useEffect(() => {
    if (defaultValue !== null) {
      getParameterSelection(defaultValue?.DeviceID);
      form.setFieldsValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    getDeviceSelection();
  }, [siteID]);

  useEffect(() => {
    getOperatorSelection();
  }, []);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        clearOnDestroy
        className={"mt-5"}
      >
        <Divider className="mb-4 mt-0"></Divider>
        <Row gutter={[12, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              name={"DeviceID"}
              label={"Device"}
              rules={[{ required: true, message: "Device is required" }]}
            >
              <Select
                showSearch
                options={deviceSelection}
                optionFilterProp="label"
                labelRender={(value) => <>{value.label}</>}
                onChange={(value) => {
                  form.setFieldValue("DeviceParameterID", null);
                  getParameterSelection(value);
                }}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              name={"DeviceParameterID"}
              label={"Parameter"}
              rules={[{ required: true, message: "Parameter is required" }]}
            >
              <Select
                showSearch
                options={parameterSelection}
                labelRender={(value) => {
                  return (
                    <>
                      {value.title} - {value.label}
                    </>
                  );
                }}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Space.Compact block>
              <Form.Item
                name={"Operator"}
                label={"Operator"}
                style={{ width: "280px", maxWidth: "60%" }}
                rules={[{ required: true, message: "Operator is required" }]}
              >
                <Select options={operatorSelection}></Select>
              </Form.Item>
              <Form.Item
                name={"SetPoint"}
                label={"Set Point"}
                style={{
                  width: "calc(100% - 250px)",
                  minWidth: "40%",
                }}
                rules={[{ required: true, message: "Set point is required" }]}
              >
                <InputNumber
                  style={{ width: "100%", borderLeft: 0 }}
                ></InputNumber>
              </Form.Item>
            </Space.Compact>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              name={"Level"}
              label={"Level"}
              rules={[{ required: true, message: "Level is required" }]}
            >
              <Select
                options={levelSelection}
                labelRender={({ value, label }) => {
                  if (label == "Warning")
                    return (
                      <Space style={{ color: "var(--ant-color-warning)" }}>
                        <ExclamationCircleOutlined></ExclamationCircleOutlined>
                        {label}
                      </Space>
                    );
                  else if (label == "Error")
                    return (
                      <Space style={{ color: "var(--ant-color-error)" }}>
                        <StopOutlined></StopOutlined>
                        {label}
                      </Space>
                    );
                  else return <>{label}</>;
                }}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={"CustomMessage"} label={"Custom Message"}>
              <Input.TextArea
                autoSize={{ minRows: 5, maxRows: 10 }}
              ></Input.TextArea>
            </Form.Item>
          </Col>
        </Row>

        <div style={{ width: "100%" }}></div>

        <Flex justify="end" align="center" gap="middle">
          <Button disabled={loading} type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" loading={loading} type="primary">
            Submit
          </Button>
        </Flex>
      </Form>
    </>
  );
}

// library imports
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
// component import
import DataTable from "../../common/DataTable";
// function imports
import { useTableSearch } from "../../../functions/tableSearch";

export default function PowerMeterTable({ data }) {
  const { getColumnSearchProps } = useTableSearch();
  const powerMeterTableColumns = [
    {
      title: "Phase Name",
      dataIndex: "phasename",
      key: "phasename",
      ...getColumnSearchProps("phasename"),
    },
    {
      title: "Voltage",
      dataIndex: "voltage",
      key: "voltage",
    },
    {
      title: "Current",
      dataIndex: "current",
      key: "current",
    },
    {
      title: "Power Factor",
      dataIndex: "powerfactor",
      key: "powerfactor",
    },
    {
      title: "Active Power",
      dataIndex: "activepower",
      key: "activepower",
    },
    {
      title: "Reactive Power",
      dataIndex: "reactivepower",
      key: "reactivepower",
    },
  ];

  return <DataTable table_data={data} table_column={powerMeterTableColumns} />;
}

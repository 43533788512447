//libary imports
import { Card, Segmented, Flex, Select, Typography } from "antd";
import { useEffect, useState, useContext } from "react";
// style imports
import "../../styles/System/SystemPage.css";
// component imports
import PowerMeter from "../../components/system/power_meter/PowerMeter";
import Inverter from "../../components/system/inverter/Inverter";
import BESS from "../../components/system/bess/BESS";
import LoadUsage from "../../components/system/load_usage/LoadUsage";
import TNB from "../../components/system/tnb/TNB";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";

const { Text } = Typography;

function SystemPage() {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi, hasInverter } =
    useContext(IndexContext);

  // for system selection
  const systemOptions = [
    { label: "PV System", value: "pvsystem" },
    { label: "Load Usage", value: "loadusage" },
    { label: "TNB", value: "utility" },
    { label: "BESS", value: "bess" },
  ];
  const [systemSelection, setSystemSelection] = useState(
    systemOptions[0].value
  );

  // device phase number list
  const [pvPowerMeterPhaseNumberList, setPvPowerMeterPhaseNumberList] =
    useState([]);
  const [inverterDevicePhaseNumberList, setInverterDevicePhaseNumberList] =
    useState([]);
  const [bessDevicePhaseNumberList, setBessDevicePhaseNumberList] = useState(
    []
  );

  // for pv system, user can choose to view either power meter or inverter
  const pvDeviceSelectList = [
    {
      value: "powerMeter",
      label: "Power Meter",
    },
    {
      value: "inverter",
      label: "Inverter List",
    },
  ];
  const [pvDeviceSelected, setPvDeviceSelected] = useState(
    pvDeviceSelectList[0].value
  );

  // to determine whether to show card or table view for the data
  const [pvSystemCard, setPvSystemCard] = useState(false);
  const handlePvSystemCardChange = () => {
    setPvSystemCard(!pvSystemCard);
  };

  const [inverterCard, setInverterCard] = useState(false);
  const handleInverterCardChange = () => {
    setInverterCard(!inverterCard);
  };

  const [utilityCard, setUtilityCard] = useState(false);
  const handleUtilityCardChange = () => {
    setUtilityCard(!utilityCard);
  };

  const [bessCard, setBessCard] = useState(false);
  const handleBessCardChange = () => {
    setBessCard(!bessCard);
  };

  // open / close state for device information modal
  const [openModal, setOpenModal] = useState(false);

  /** API to get phase number for the device phase number tab */
  function getDevicePhaseNumber() {
    fetchWrapper({
      endpoint_url: "system/getDevicePhaseNumber",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        if (response.data === false) {
          notificationApi.warning({
            message: response.message,
          });
        } else {
          setInverterDevicePhaseNumberList(response.data?.INVERTER ?? []);
          setPvPowerMeterPhaseNumberList(response.data?.PV ?? []);
          setBessDevicePhaseNumberList(response.data?.BESS ?? []);
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  useEffect(() => {
    setInverterDevicePhaseNumberList([]);
    setPvPowerMeterPhaseNumberList([]);
    setBessDevicePhaseNumberList([]);

    if (siteSelected) {
      getDevicePhaseNumber();
    }
  }, [siteSelected]);

  const contentList = {
    powerMeter: (
      <PowerMeter
        devicePhaseNumberList={pvPowerMeterPhaseNumberList}
        pvSystemCard={pvSystemCard}
        handleChange={handlePvSystemCardChange}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    ),
    inverter: (
      <Inverter
        devicePhaseNumberList={inverterDevicePhaseNumberList}
        pvSystemCard={inverterCard}
        handleChange={handleInverterCardChange}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    ),
  };

  return (
    <>
      <Card
        className="system-card"
        title={
          <div className="system-segmented-container">
            <Flex
              className="system-segmented-container-flex"
              justify="space-between"
              align="center"
              gap="small"
            >
              <Segmented
                className="system-segmented"
                size="large"
                options={systemOptions}
                onChange={(value) => {
                  setSystemSelection(value);
                }}
                value={systemSelection}
              />
              {systemSelection == "pvsystem" && hasInverter == true ? (
                <Flex
                  align="center"
                  gap="middle"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  wrap="wrap"
                >
                  <Text>Data Select: </Text>
                  <Select
                    style={{ width: 125 }}
                    options={pvDeviceSelectList}
                    value={pvDeviceSelected}
                    onChange={(value) => setPvDeviceSelected(value)}
                  />
                </Flex>
              ) : (
                <></>
              )}
            </Flex>
          </div>
        }
        style={{ overflowY: "hidden" }}
      >
        {systemSelection == "pvsystem" ? (
          contentList[pvDeviceSelected]
        ) : systemSelection == "loadusage" ? (
          <LoadUsage />
        ) : systemSelection == "utility" ? (
          <TNB
            utilityCard={utilityCard}
            handleChange={handleUtilityCardChange}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        ) : systemSelection == "bess" ? (
          <BESS devicePhaseNumberList={bessDevicePhaseNumberList} />
        ) : (
          <></>
        )}
      </Card>
    </>
  );
}

export default SystemPage;

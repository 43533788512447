// library imports
import { Image } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
// style imports
import "../../styles/SiteList/SiteListPage.css";
// component imports
import { _SiteTypeSummary } from "./_SiteTypeSummary";
// context imports
import { IndexContext } from "../../context/IndexContext";
// constant import
import { PLACEHOLDER_IMAGE } from "../../configs/configs";

const STATUS_MENU = [
  {
    value: "All",
    label: "All",
    color: "black",
  },
  {
    value: "Normal",
    label: "Normal",
    color: "green",
  },
  {
    value: "Warning",
    label: "Warning",
    color: "yellow",
  },
  {
    value: "Error",
    label: "Error",
    color: "red",
  },
  {
    value: "Offline",
    label: "Offline",
    color: "grey",
  },
];

/**
 * Component: Display info of 1 site
 * display:
 *  left column: Image
 *  right column:
 *      - Site Long Name
 *      - Site Capacity
 *      - Site Generated Power
 *      - Site Status
 */
export function _SiteInfo({ id, name, capacity, power, status }) {
  const navigate = useNavigate();
  const { setSiteSelected } = useContext(IndexContext);

  const onDivClick = () => {
    setSiteSelected(id);
    navigate("/dashboard");
  };

  const status_object = STATUS_MENU.find((item) => item.value === status);

  return (
    <div className="flexbox-container-row site-box">
      <Image
        width={150}
        height={150}
        src="error"
        fallback={PLACEHOLDER_IMAGE}
        style={{ borderRadius: "10px", border: "1px solid #ccc" }}
      />
      <div className="flexbox-container-col" onClick={onDivClick}>
        <span style={{ fontWeight: "bold" }}>{name}</span>
        <span>{capacity} kWp</span>
        <span>{power} kWh</span>
        {status_object ? (
          <span style={{ color: status_object.color }}>
            {status_object.label}
          </span>
        ) : (
          <span>{status}</span>
        )}
      </div>
    </div>
  );
}

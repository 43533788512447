// style import
import "../../../styles/Configuration/PvSystDataEntry.css";
// library import
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Switch,
  Upload,
  Tabs,
  message,
  Flex,
  Row,
  Col,
  Skeleton,
} from "antd";
import { useEffect, useState, useContext } from "react";
// component import
import PVSystDataEntryInputGroup from "./PVSystDataEntryInputGroup";
// context import
import { IndexContext } from "../../../context/IndexContext";
// hook import
import useFetch from "../../../hooks/useFetch";
// config imports
import { FORM_REQUIRED } from "../../../configs/configs";

export default function PVSystDataEntryView(props) {
  const { siteID } = props;

  const { notificationApi, messageApi } = useContext(IndexContext);
  const [fetchWrapper] = useFetch();

  const [isLoading, setIsLoading] = useState(false); // loading state for pv syst data entry
  const [isSaveLoading, setIsSaveLoading] = useState(false); // loading state for Save button
  const [uploadLoading, setUploadLoading] = useState(false); // loading state for Upload button
  const [activeTab, setActiveTab] = useState("kWhTab"); // currently active tab
  const [fileList, setFileList] = useState([]); // backend uploaded pvsyst report
  const [pvSystID, setPvSystID] = useState(null); // pv syst id
  const [pvSystDataID, setPvSystDataID] = useState(null); // pv syst data id
  const [pvsystForm] = Form.useForm();

  const tabList = [
    {
      key: "kWhTab",
      label: "Global Horizontal Irradiation (kWh/m²)",
      children: (
        <PVSystDataEntryInputGroup
          label="Global Horizontal Irradiation (kWh/m²)"
          isLoading={isLoading}
        />
      ),
      forceRender: true,
    },
    {
      key: "MWhTab",
      label: "Effective Energy at the Output of the Array (MWh)",
      children: (
        <PVSystDataEntryInputGroup
          label="Effective Energy at the Output of the Array (MWh)"
          isLoading={isLoading}
        />
      ),
      forceRender: true,
    },
    {
      key: "ratioTab",
      label: "Performance Ratio",
      children: (
        <PVSystDataEntryInputGroup
          label="Performance Ratio"
          isLoading={isLoading}
        />
      ),
      forceRender: true,
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const handleSubmitForm = (value) => {
    setIsLoading(true);

    const categories = {
      pvSystID: pvSystID,
      pvSystDataID: pvSystDataID,
      data: {},
      pvSyst: {
        kWh: {},
        MWh: {},
        ratio: {},
      },
    };

    Object.entries(value).forEach(([key, value]) => {
      if (key.startsWith("kWh")) {
        categories.pvSyst.kWh[key.split("-")[1]] = value;
      } else if (key.startsWith("MWh")) {
        categories.pvSyst.MWh[key.split("-")[1]] = value;
      } else if (key.startsWith("ratio")) {
        categories.pvSyst.ratio[key.split("-")[1]] = value;
      } else {
        categories.data[key] = value;
      }
    });

    insertPVSystData(categories);
  };

  const handleSubmitFail = (value) => {
    message.error("Fail to Submit Form");
  };

  const beforeUpload = (file) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload pdf files!");
    }

    return isPdf || Upload.LIST_IGNORE;
  };

  /** API to insert PVSyst data */
  function insertPVSystData(categories) {
    setIsSaveLoading(true);

    fetchWrapper({
      endpoint_url: "pv_syst_data_entry/insertPVSystData",
      method: "POST",
      body: {
        data: categories,
        site_id: siteID,
      },

      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        getPVSystData();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setIsSaveLoading(false);
      },
    });
  }

  /** API to upload PVSyst report */
  function uploadPVSystFile(upload_detail) {
    // indicate uploading is performing
    setUploadLoading(true);

    const formData = new FormData();
    formData.append("uploaded-files", upload_detail.file);
    formData.append("site_id", siteID);

    fetchWrapper({
      endpoint_url: "pv_syst_data_entry/uploadFile",
      set_content_type_header: false,
      body: formData,
      onSuccess: (response) => {
        //upload success
        messageApi.open({
          type: "success",
          content: "File uploaded and processed!",
        });
        getBackendFileList();
        // getPVSystData();
      },
      onResponseError: (response) => {
        messageApi.open({
          type: "error",
          content: response.message,
        });
      },
      onFetchError: (response) => {
        messageApi.open({
          type: "error",
          content: response.message,
        });
      },
      onFinish: () => {
        setUploadLoading(false);
      },
    });
  }

  function getBackendFileList() {
    fetchWrapper({
      endpoint_url: "pv_syst_data_entry/getBackendFileList",
      method: "POST",
      body: {
        site_id: siteID,
      },
      onSuccess: (response) => {
        setFileList(response.data);
      },
      onResponseError: (response) => {
        messageApi.open({
          type: "error",
          content: response.message,
        });
      },
      onFetchError: (response) => {
        messageApi.open({
          type: "error",
          content: response.message,
        });
      },
    });
  }

  function deletePVSystFile(file) {
    const formData = new FormData();
    formData.append("ID", file.uid);

    fetchWrapper({
      endpoint_url: "pv_syst_data_entry/deleteFile",
      set_content_type_header: false,
      body: formData,
      onSuccess: (response) => {
        //delete success
        messageApi.open({
          type: "success",
          content: response.message,
        });
        getBackendFileList();
      },
      onFetchError: (response) => {
        messageApi.open({
          type: "error",
          content: response.message,
        });
      },
    });
  }

  function getPVSystData() {
    setIsLoading(true);

    fetchWrapper({
      endpoint_url: "pv_syst_data_entry/getPVSystData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteID,
      },

      onSuccess: (response) => {
        if (Object.keys(response.data.pv_syst_data).length > 0) {
          pvsystForm.setFieldsValue(response.data.month_data.kWh);
          pvsystForm.setFieldsValue(response.data.month_data.MWh);
          pvsystForm.setFieldsValue(response.data.month_data.ratio);
          pvsystForm.setFieldsValue(response.data.pv_syst_data);
          setPvSystID(response.data.pv_syst_data.ID);
          setPvSystDataID(response.data.month_data_id);
        }
      },
      onResponseError: (response) => {
        pvsystForm.setFieldValue({ PVSystResult: false });
      },
      onFinish: () => {
        setIsLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteID) {
      getPVSystData();
      getBackendFileList();
    }
  }, [siteID]);

  return (
    <Form
      layout="horizontal"
      labelAlign="left"
      requiredMark={false}
      style={{ width: "100%" }}
      onFinish={handleSubmitForm}
      onFinishFailed={handleSubmitFail}
      form={pvsystForm}
    >
      {!isLoading ? (
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Include PVSyst Results in Reporting"
              name="PVSystResult"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              valuePropName="checked"
            >
              <Switch defaultChecked={false} />
            </Form.Item>
            <Form.Item
              label="Project Location"
              name="ProjectLocation"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: FORM_REQUIRED,
                  message: "Project Location cannot be empty.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Total PV Power, Nominal(STC), in MWp"
              name="PVPower"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: FORM_REQUIRED,
                  message: "Total PV Power cannot be empty.",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Total Inverter Power, in MWac"
              name="InverterPower"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: FORM_REQUIRED,
                  message: "Total Inverter Power cannot be empty.",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Produced Energy, in MWh/year"
              name="ProducedEnergy"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: FORM_REQUIRED,
                  message: "Produced Energy cannot be empty.",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Specific Production, in kWh/kWp/year"
              name="SpecificProduction"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: FORM_REQUIRED,
                  message: "Specific Production cannot be empty.",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Performance Ratio"
              name="PerformanceRatio"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: FORM_REQUIRED,
                  message: "Performance Ratio cannot be empty.",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="PVSyst Report"
              name="PVSystReport"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
            >
              <Upload
                beforeUpload={beforeUpload}
                customRequest={uploadPVSystFile}
                onRemove={deletePVSystFile}
                fileList={fileList}
              >
                <Button
                  icon={<UploadOutlined />}
                  disabled={fileList.length > 0}
                  loading={uploadLoading}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Skeleton active />
      )}

      <Tabs
        defaultActiveKey={activeTab}
        onChange={onTabChange}
        items={tabList}
      />

      <div className="button-container">
        {isSaveLoading ? (
          <Button
            style={{ backgroundColor: "#000038", color: "white" }}
            loading
          >
            Saving
          </Button>
        ) : (
          <>
            <Button htmlType="reset">Cancel</Button>
            <Button
              htmlType="submit"
              style={{ backgroundColor: "#000038", color: "white" }}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </Form>
  );
}

import { Button, Form, Input, Flex, Select, Skeleton } from "antd";
import { FORM_REQUIRED } from "../../../configs/configs";
import { useEffect, useState, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function UserForm(props) {
  const {
    mode,
    setIsUserFormModalOpen,
    refreshTable,
    initialValues,
    organisationNameSelectionList,
  } = props;
  const [fetchWrapper] = useFetch();

  // indicate it's not first load so that site access selection will be disabled if site list is empty
  // (for first load, site list will not be empty if there is already user tied to it, otherwise, foreign key constraint is violated)
  // (for superuser only as for admin, site will not be empty if there is already user tied to it)
  const [firstLoad, setFirstLoad] = useState(true);

  const [loading, setLoading] = useState(false);
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);

  // selection for user type
  const [userTypeSelected, setUserTypeSelected] = useState(null);
  const [userTypeList, setUserTypeList] = useState([]);

  // selection for organisation name
  const [organisationNameSelected, setOrganisationNameSelected] =
    useState(null);

  // selection for site access
  const [siteSelected, setSiteSelected] = useState(null);
  const [siteList, setSiteList] = useState([]);

  const [userForm] = Form.useForm();

  // load index context
  const { messageApi, authenticationState } = useContext(IndexContext);

  /* API to get user type list, different user type list will be returned based on the role of the login user */
  function getUserTypeList() {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "user_management/getUserTypeList",
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          setUserTypeList(fetch_output.user_type_list);
        }
        setLoading(false);
      },
    });
  }

  /* API to check whether username is unique */
  function checkUniqueUsername(username, callback) {
    fetchWrapper({
      endpoint_url: "user_management/checkUniqueUsername",
      body: {
        user_account_id: initialValues ? initialValues[0].ID : null,
        username: username ? username : "",
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          if (fetch_output.is_unique === true) {
            callback(); // No error, so pass an empty callback
          } else {
            callback("Username already exists. Please input another username.");
          }
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
      },
    });
  }

  /* API to get site list for creating site access of admin and user, also take into consideration whether the login user is superuser or admin */
  function getSiteList() {
    fetchWrapper({
      endpoint_url: "user_management/getSiteList",
      body: {
        user_group_id: organisationNameSelected
          ? organisationNameSelected
          : null,
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          setSiteList(fetch_output.site_list);
        }
      },
    });
  }

  /* API to get site list if form in editing mode so that correct site list can be returned based on user id */
  function getEditingSiteList() {
    fetchWrapper({
      endpoint_url: "user_management/getEditingSiteList",
      body: {
        user_group_id: initialValues[0].UserGroupID
          ? initialValues[0].UserGroupID
          : null,
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          setSiteList(fetch_output.site_list);
        }
      },
    });
  }

  /* API to add user group or user and their site access */
  function addUser(user_inputs) {
    setLoadingSubmitButton(true);
    fetchWrapper({
      endpoint_url: "user_management/addUser",
      body: {
        username: user_inputs.Username,
        password: user_inputs.Password,
        user_type: user_inputs.RoleID,
        user_group_id: user_inputs.UserGroupID ? user_inputs.UserGroupID : null, // admin can't select user group, so need to pass null to backend,
        full_name: user_inputs.FullName,
        contact_number: user_inputs.ContactNumber,
        email: user_inputs.Email,
        site_access: user_inputs.SiteAccess ? user_inputs.SiteAccess : [],
        access_validity: user_inputs.AccessValidity
          ? user_inputs.AccessValidity
          : true,
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          messageApi.open({
            type: "success",
            content: fetch_output.message,
          });
          setIsUserFormModalOpen(false);
          userForm.resetFields();
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
        setLoadingSubmitButton(false);
        getSiteList();
        refreshTable();
        setOrganisationNameSelected(null); // Reset organisation name selection
        setUserTypeSelected(null); // Reset user type selection
      },
    });
  }

  /* API to edit user group or user and their site access */
  function editUser(user_inputs) {
    setLoadingSubmitButton(true);
    fetchWrapper({
      endpoint_url: "user_management/editUser",
      body: {
        user_account_id: initialValues[0].ID,
        username: user_inputs.Username,
        user_type: user_inputs.RoleID,
        user_group_id: user_inputs.UserGroupID ? user_inputs.UserGroupID : null, // admin can't select user group, so need to pass null to backend
        full_name: user_inputs.FullName,
        contact_number: user_inputs.ContactNumber,
        email: user_inputs.Email,
        site_access_list: user_inputs.SiteAccess ? user_inputs.SiteAccess : [],
        access_validity: user_inputs.AccessValidity,
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          messageApi.open({
            type: "success",
            content: fetch_output.message,
          });
          setIsUserFormModalOpen(false);
          userForm.resetFields();
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
        setLoadingSubmitButton(false);
        getSiteList();
        refreshTable();
        setOrganisationNameSelected(null); // Reset organisation name selection
        setUserTypeSelected(null); // Reset user type selection
      },
    });
  }

  useEffect(() => {
    getUserTypeList();
    getSiteList();
  }, []);

  useEffect(() => {
    if (organisationNameSelected) {
      getSiteList();
    }
  }, [organisationNameSelected]);

  useEffect(() => {
    if (mode === "edit") {
      setFirstLoad(false);
      if (initialValues.length) {
        getEditingSiteList(); // need to get it whenever mode is edit and initialValues changes (meaning different user is selected for editing purpose)
        userForm.setFieldsValue(initialValues[0]);
        setOrganisationNameSelected(initialValues[0].UserGroupID);
        setUserTypeSelected(initialValues[0].RoleID); // set it so that required mark and tooltip message for SiteAccess form item is shown correctly
      }
    }
  }, [mode, initialValues]);

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <Form
          form={userForm}
          name="user-form"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={(values) => {
            if (mode === "add") {
              addUser(values);
            } else {
              editUser(values);
            }
          }}
          autoComplete="off"
        >
          {authenticationState.user_type === 1 ? (
            <Form.Item
              label="Organisation Name"
              name="UserGroupID"
              rules={[
                {
                  required: FORM_REQUIRED,
                  message: "You must select organisation name.",
                },
              ]}
            >
              <Select
                value={organisationNameSelected}
                options={organisationNameSelectionList}
                onChange={(value) => {
                  setOrganisationNameSelected(value);
                }}
                disabled={mode === "edit"}
              />
            </Form.Item>
          ) : null}

          <Form.Item
            label="PIC Full Name"
            name="FullName"
            rules={[
              {
                required: FORM_REQUIRED,
                message: "PIC full name cannot be empty.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Contact Number"
            name="ContactNumber"
            rules={[
              {
                required: FORM_REQUIRED,
                message: "Contact number cannot be empty.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="Email"
            rules={[
              {
                required: FORM_REQUIRED,
                message: "Email cannot be empty.",
              },
              {
                type: "email",
                message: "Please input a valid E-mail.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Username"
            name="Username"
            rules={[
              {
                required: FORM_REQUIRED,
                message: "Username cannot be empty.",
              },
              {
                validator: (_, value) => {
                  const regex = /^[a-zA-Z0-9]*$/; // Regex for only digits and alphabets
                  if (!value || regex.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error(
                        "Username can only contain letters and digits, no spaces or symbols."
                      )
                    );
                  }
                },
              },
              {
                validator: (_, value, callback) => {
                  checkUniqueUsername(value, callback);
                },
              },
            ]}
            tooltip={mode === "edit" ? "Username cannot be changed." : ""}
            validateTrigger="onBlur"
          >
            <Input disabled={mode === "edit"} />
          </Form.Item>

          {/* in edit mode, no need to show password field */}
          {mode === "edit" ? null : (
            <Form.Item
              label="Password"
              name="Password"
              rules={[
                {
                  required: FORM_REQUIRED,
                  message: "Password cannot be empty.",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}

          {mode === "edit" ? null : (
            <Form.Item
              label="Confirm Password"
              name="ConfirmPassword"
              rules={[
                {
                  required: FORM_REQUIRED,
                  message: "You must confirm your password.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("Password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match."));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}

          <Form.Item
            label="User Type"
            name="RoleID"
            rules={[
              {
                required: FORM_REQUIRED,
                message: "You must select user type.",
              },
            ]}
          >
            <Select
              value={userTypeSelected}
              options={userTypeList}
              onChange={(value) => setUserTypeSelected(value)}
            />
          </Form.Item>

          <Form.Item
            label="Site Access"
            name="SiteAccess"
            rules={[
              {
                required: userTypeSelected === 2 ? false : FORM_REQUIRED,
                // if user added is of user type admin, it's not compulsory to select site access
                message: "You must select site access.",
              },
            ]}
            tooltip={
              authenticationState.user_type === 1 &&
              organisationNameSelected === null
                ? "Please select organisation name first."
                : siteList.length === 0 && userTypeSelected === 3
                ? "There is no site. Please add site first."
                : siteList.length === 0
                ? "There is no site."
                : null
            }
          >
            <Select
              mode="multiple"
              allowClear
              options={siteList}
              disabled={
                authenticationState.user_type === 1 &&
                organisationNameSelected === null
                  ? true
                  : false
              }
            />
          </Form.Item>

          {mode === "edit" ? (
            <Form.Item
              label="Access Validity"
              name="AccessValidity"
              rules={[
                {
                  required: FORM_REQUIRED,
                  message: "You must select access validility.",
                },
              ]}
            >
              <Select
                options={[
                  { value: true, label: "True" },
                  { value: false, label: "False" },
                ]}
              />
            </Form.Item>
          ) : (
            <></>
          )}

          <Flex justify="center">
            <Button
              className="mr-2"
              onClick={() => {
                setIsUserFormModalOpen(false);
                userForm.resetFields();
                setOrganisationNameSelected(null); // set organisation name back to null when superuser clicks cancel so that site access selection can be disabled accordingly
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingSubmitButton}
            >
              Submit
            </Button>
          </Flex>
        </Form>
      )}
    </>
  );
}

export default UserForm;

// library imports
import { useState, useEffect, useContext } from "react";
import { Tooltip, Card, Skeleton } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// style imports
import "../../styles/Dashboard/PVConditionAlarmContent.css";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";
// config imports
import { MQTT_REFRESH_RATE } from "../../configs/configs";

function AlarmContent({ setRefreshCheck }) {
  const navigate = useNavigate();

  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  const [loading, setLoading] = useState(true);

  const [online, setOnline] = useState(0);
  const [offline, setOffline] = useState(0);
  const [warning, setWarning] = useState(0);
  const [error, setError] = useState(0);

  function getAlarmData() {
    fetchWrapper({
      endpoint_url: "dashboard/getAlarmData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setOnline(response.data.online_count);
        setOffline(response.data.offline_count);
        setWarning(response.data.warning_count);
        setError(response.data.error_count);
        setRefreshCheck(true);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteSelected) {
      getAlarmData();

      const timer = setInterval(() => {
        getAlarmData();
      }, MQTT_REFRESH_RATE * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelected]);

  return (
    <>
      {loading === false ? (
        <div className="pvcondition-alarm-content-container">
          <span>
            Devices Offline: {offline}
            {offline !== "-" && offline !== 0 && (
              <Tooltip title="Click me to navigate to alarm page.">
                <InfoCircleOutlined
                  style={{ color: "#FF0000" }}
                  onClick={() => {
                    navigate("/Alarm/AlarmRecord");
                  }}
                />
              </Tooltip>
            )}
          </span>

          <span>
            Has Warning: {warning}
            {warning !== "-" && warning !== 0 && (
              <Tooltip title="Click me to navigate to alarm page.">
                <InfoCircleOutlined
                  style={{ color: "#E4D00A" }}
                  onClick={() => {
                    navigate("/Alarm/AlarmRecord");
                  }}
                />
              </Tooltip>
            )}
          </span>

          <span>
            Has Error: {error}
            {error !== "-" && error !== 0 && (
              <Tooltip title="Click me to navigate to alarm page.">
                <InfoCircleOutlined
                  style={{ color: "#E4D00A" }}
                  onClick={() => {
                    navigate("/Alarm/AlarmRecord");
                  }}
                />
              </Tooltip>
            )}
          </span>
        </div>
      ) : (
        <Card
          size="small"
          style={{
            width: "100%",
            height: "100%",
          }}
          className="alarm-card"
        >
          <Skeleton
            active={true}
            style={{ padding: "0.4rem", maxWidth: "70%", margin: "auto" }}
            paragraph={{ rows: 1 }}
          />
        </Card>
      )}
    </>
  );
}

export default AlarmContent;

// library imports
import { Button, Form, Flex, DatePicker, Select, InputNumber } from "antd";
import { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
// config imports
import { FORM_REQUIRED } from "../../../configs/configs";
// hook imports
import useFetch from "../../../hooks/useFetch";
// context imports
import { IndexContext } from "../../../context/IndexContext";

function SmpForm(props) {
  const {
    mode,
    setIsSmpFormModalOpen,
    refreshTable,
    initialValues,
    selectedYear,
  } = props;

  const [fetchWrapper] = useFetch();

  // loading states
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);
  const [loadingPvSchemeList, setLoadingPvSchemeList] = useState(false);

  // form
  const [smpForm] = Form.useForm();

  // load index context
  const { notificationApi } = useContext(IndexContext);

  // selection for pv scheme
  const [pvSchemeSelected, setPvSchemeSelected] = useState(null);
  const [pvSchemeList, setPvSchemeList] = useState([]);

  /* API to get pv scheme list */
  function getPvSchemeList() {
    setLoadingPvSchemeList(true);
    fetchWrapper({
      method: "GET",
      endpoint_url: "site_management/getPvSchemeList",
      set_content_type_header: false,
      onSuccess: (response) => {
        setLoadingPvSchemeList(false);
        setPvSchemeList(response.data);
      },
    });
  }

  /* API to add smp */
  function addSmp(user_inputs) {
    setLoadingSubmitButton(true);

    fetchWrapper({
      endpoint_url: "smp_management/addSmp",
      body: {
        pv_scheme_id: user_inputs.PVSchemeID,
        month: user_inputs.MonthYear.startOf("month").format("YYYY-MM-DD"),
        smp_value: user_inputs.Value,
      },

      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setIsSmpFormModalOpen(false);
        smpForm.resetFields();
        refreshTable();
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoadingSubmitButton(false);
      },
    });
  }

  /* API to edit smp  */
  function editSmp(user_inputs) {
    setLoadingSubmitButton(true);

    fetchWrapper({
      endpoint_url: "smp_management/editSmp",
      body: {
        pv_scheme_id: user_inputs.PVSchemeID,
        smp_id: initialValues.ID,
        month: user_inputs.MonthYear.format("YYYY-MM-DD"),
        smp_value: user_inputs.Value,
      },

      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setIsSmpFormModalOpen(false);
        smpForm.resetFields();
        refreshTable();
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoadingSubmitButton(false);
      },
    });
  }

  useEffect(() => {
    if (mode === "edit") {
      if (initialValues) {
        initialValues.MonthYear = dayjs(initialValues.MonthYear);
        smpForm.setFieldsValue(initialValues);
      }
    }
  }, [mode, initialValues]);

  useEffect(() => {
    getPvSchemeList();
  }, []);

  return (
    <>
      <Form
        form={smpForm}
        name="smp-form"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={(values) => {
          if (mode === "add") {
            addSmp(values);
          } else {
            editSmp(values);
          }
        }}
        autoComplete="off"
      >
        <Form.Item
          label="PV Scheme"
          name="PVSchemeID"
          rules={[
            {
              required: FORM_REQUIRED,
              message: "PV Scheme cannot be empty.",
            },
          ]}
        >
          <Select
            placeholder="Select PV Scheme"
            value={pvSchemeSelected}
            options={pvSchemeList}
            onChange={(value) => {
              setPvSchemeSelected(value);
            }}
            loading={loadingPvSchemeList}
          />
        </Form.Item>

        <Form.Item
          label="Month"
          name="MonthYear"
          rules={[
            {
              required: FORM_REQUIRED,
              message: "Month cannot be empty.",
            },
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            picker="month"
            minDate={dayjs(selectedYear.startOf("year"), "YYYY-MM-DD")}
            maxDate={dayjs(selectedYear.endOf("year"), "YYYY-MM-DD")}
          />
        </Form.Item>

        <Form.Item
          label="SMP Value"
          name="Value"
          rules={[
            {
              required: FORM_REQUIRED,
              message: "SMP Value cannot be empty.",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Input SMP Value"
          />
        </Form.Item>

        <Flex justify="center">
          <Button
            className="mr-2"
            onClick={() => {
              setIsSmpFormModalOpen(false);
              smpForm.resetFields();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingSubmitButton}
          >
            Submit
          </Button>
        </Flex>
      </Form>
    </>
  );
}

export default SmpForm;

// library imports
import { useEffect, useRef, useLayoutEffect } from "react";
import { theme } from "antd";
import { Chart } from "@antv/g2";
// style imports
import "../../styles/Dashboard/SocChartCard.css";
// config imports
import { LIGHT_BLUE } from "../../configs/configs";

export default function _SocChart(props) {
  const { container, data_line } = props;
  const chartRef = useRef(null);

  // theme token
  const { token } = theme.useToken();

  useEffect(() => {
    document.getElementById(container).innerHTML = "";
  }, []);

  useEffect(() => {
    const chart = new Chart({
      container: container,
      autoFit: true,
    });

    chart
      .line()
      .data(data_line)
      .encode("x", "Time")
      .encode("y", "SOC (%)")
      .axis("x", {
        line: true,
        lineStroke: token.colorTextBase,
        tickStroke: token.colorTextBase,
        title: "Time",
        titleFill: token.colorTextBase,
        labelFill: token.colorTextBase,
      })
      .axis("y", {
        line: true,
        lineStroke: token.colorTextBase,
        tickStroke: token.colorTextBase,
        title: "SOC (%)",
        titleFill: token.colorTextBase,
        labelFill: token.colorTextBase,
      })
      .encode("color", "name")
      .encode("shape", "smooth")
      .scale("color", { range: [LIGHT_BLUE] }) // Set the color scale to LIGHT_BLUE
      .style("lineWidth", 2)
      .interaction("elementHighlight", { background: true })
      .legend({
        color: {
          itemLabelFill: token.colorTextBase,
        },
      })
      .interaction("tooltip", {
        render: (event, { title, items }) => {
          return `<div style="width:150px;">
            <h3 style="padding:0;margin:0">${title}</h3>
            <ul style="padding: 0; margin: 0; list-style: none;">
              ${items
                .map((d) => {
                  // Format the value with commas and 2 decimal places
                  const formattedValue = d.value
                    ? Number(d.value).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : d.value === 0
                    ? Number(d.value).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : d.value;
                  return `
                    <li style="display: flex; justify-content: space-between; align-items: center; margin: 4px 0;">
                      <span style="display: inline-flex; align-items: center;">
                        <span style="width: 8px; height: 8px; background-color: ${d.color}; border-radius: 50%; margin-right: 8px;"></span>
                        <span>${d.name}</span>
                      </span>
                      <span>${formattedValue}</span>
                    </li>`;
                })
                .join("")}
            </ul>
          </div>`;
        },
      });

    document.getElementById(container).innerHTML = "";
    chartRef.current = chart;

    chart.render();
  }, [container, token]);

  function resizeChart() {
    if (chartRef.current !== null) {
      chartRef.current.forceFit(); // Adjusts the G2 chart to fit its container
    }
  }

  useLayoutEffect(() => {
    resizeChart(); // Ensure the chart is sized correctly on initial render

    window.addEventListener("resize", resizeChart); // Listen to window resize events

    return () => {
      window.removeEventListener("resize", resizeChart); // Cleanup the event listener
    };
  }, [chartRef]);

  return <div style={{ width: "100%", height: "100%" }} id={container} />;
}

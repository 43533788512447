// library imports
import { Skeleton } from "antd";
// component import
import { LabelValueUnitDisplayRow } from "../../common/WordDisplay";

export default function LoadUsageContent(props) {
  const { systemData, firstLoad, systemLastUpdate } = props;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          align: "center",
          marginBottom: "12px",
        }}
      >
        <h2 style={{ margin: "0px" }}>Load</h2>
        <span>Last Update: {systemLastUpdate}</span>
      </div>

      {firstLoad ? (
        <Skeleton active style={{ margin: "5px" }} />
      ) : (
        <div className="system-col">
          <div className="system-row">
            <div className="content-box">
              <LabelValueUnitDisplayRow
                label={"Real Power"}
                value={
                  systemData?.realPower !== undefined
                    ? systemData.realPower
                    : "-"
                }
                unit={"kW"}
              />
              <LabelValueUnitDisplayRow
                label={"Reactive Power"}
                value={
                  systemData?.reactivePower !== undefined
                    ? systemData.reactivePower
                    : "-"
                }
                unit={"kVAR"}
              />
              <LabelValueUnitDisplayRow
                label={"Apparent Power"}
                value={
                  systemData?.apparentPower !== undefined
                    ? systemData.apparentPower
                    : "-"
                }
                unit={"kVA"}
              />
              <LabelValueUnitDisplayRow
                label={"Load Factor"}
                value={
                  systemData?.loadFactor !== undefined
                    ? systemData.loadFactor
                    : "-"
                }
                unit={""}
              />
              <LabelValueUnitDisplayRow
                label={"Power Factor"}
                value={
                  systemData?.powerFactor !== undefined
                    ? systemData.powerFactor
                    : "-"
                }
                unit={""}
              />
            </div>
            <div className="content-box">
              <LabelValueUnitDisplayRow
                label={"Energy Consumption (Peak) - Month"}
                value={
                  systemData?.onPeakConsumption !== undefined
                    ? systemData.onPeakConsumption
                    : "-"
                }
                unit={"kWh"}
              />
              <LabelValueUnitDisplayRow
                label={"Energy Consumption (Off-Peak) - Month"}
                value={
                  systemData?.offPeakConsumption !== undefined
                    ? systemData.offPeakConsumption
                    : "-"
                }
                unit={"kWh"}
              />
              <LabelValueUnitDisplayRow
                label={"Energy Consumption - Month"}
                value={
                  systemData?.consumption !== undefined
                    ? systemData.consumption
                    : "-"
                }
                unit={"kWh"}
              />
              <LabelValueUnitDisplayRow
                label={"Reactive Energy Consumption - Month"}
                value={
                  systemData?.reactiveConsumption !== undefined
                    ? systemData.reactiveConsumption
                    : "-"
                }
                unit={"kVARh"}
              />
              <LabelValueUnitDisplayRow
                label={"Maximum Demand - Month"}
                value={
                  systemData?.maximumDemand !== undefined
                    ? systemData.maximumDemand
                    : "-"
                }
                unit={"kW"}
              />
            </div>
            <div className="content-box">{/* empty space */}</div>
          </div>
        </div>
      )}
    </>
  );
}


import { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { useNavigate } from "react-router-dom";

export default function useAuthCheck(){
  const [fetchWrapper] = useFetch();

  // for navigation
  const navigate = useNavigate();

  // for getting authentication state
  const [authenticationState, setAuthenticationState] = useState({});

  function getAuthenticationState({redirect_login = true}) {
    fetchWrapper({
      endpoint_url: "user_management/checkUser",

      onSuccess: (response) => {
        setAuthenticationState({ ...authenticationState, ...response });
      },

      onResponseError: (response) => {
        setAuthenticationState(response);
        if (redirect_login){
            navigate("/login", { replace: true });
        }
      },

      onFetchError: (response) => {
        setAuthenticationState({
          error: response
        });
        if (redirect_login){
            navigate("/login", { replace: true });
        }
      },
    });
  }

  useEffect(() => {
    getAuthenticationState({
        redirect_login:false
    });
  }, []);

  return [ authenticationState, getAuthenticationState ];
}
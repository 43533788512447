import { Form, Input, Flex, Button, Skeleton } from "antd";
import { FORM_REQUIRED } from "../../../configs/configs";

function TelegramForm(props) {
  const {
    setIsTelegramFormModalOpen,
    mode,
    addTelegramGroup,
    updateTelegramGroup,
    form,
    submitButtonLoading,
    telegramDetailsRetrievalLoading,
    checkUniqueName,
    selectedEditTelegramGroupID,
    siteID,
  } = props;

  return !telegramDetailsRetrievalLoading ? (
    <Form
      layout="vertical"
      onFinish={(values) => {
        if (mode === "add") {
          addTelegramGroup(values);
        } else {
          updateTelegramGroup(values);
        }
      }}
      form={form}
      autoComplete="off"
    >
      <Form.Item
        label="Telegram Group Name"
        name="Name"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Please input telegram name.",
          },
          {
            validator: (_, value, callback) => {
              checkUniqueName(
                siteID,
                value.trim(),
                mode === "add" ? null : selectedEditTelegramGroupID,
                callback
              ); // Check if telegram group name is unique
            },
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Chat ID"
        name="ChatID"
        rules={[{ required: FORM_REQUIRED, message: "Please input Chat ID." }]}
      >
        <Input />
      </Form.Item>

      <Flex justify="center" gap="8px">
        <Button
          onClick={() => {
            setIsTelegramFormModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={submitButtonLoading}>
          Submit
        </Button>
      </Flex>
    </Form>
  ) : (
    <Skeleton
      active={true}
      style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
      paragraph={{ rows: 5 }}
    />
  );
}

export default TelegramForm;

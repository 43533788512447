// library imports
import { useState } from "react";
// hook imports
import useFetch from "./useFetch";

export default function useHasInverterSelected() {
  const [fetchWrapper] = useFetch();

  // loading state
  const [getHasInverterSelectedLoading, setGetHasInverterSelectedLoading] =
    useState(false);

  // this state is to check whether the site has inverter
  const [hasInverterSelected, setHasInverterSelected] = useState(false);

  /* API to check whether site has inverter that is selected and not deleted */
  function getHasInverterSelected({ siteSelected }) {
    setGetHasInverterSelectedLoading(true);
    fetchWrapper({
      endpoint_url: "layout/getHasInverterSelected",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },
      onSuccess: (response) => {
        setGetHasInverterSelectedLoading(false);
        setHasInverterSelected(response.data);
      },
    });
  }

  return [
    hasInverterSelected,
    getHasInverterSelected,
    getHasInverterSelectedLoading,
  ];
}

// library imports
import { useEffect, useRef } from "react";
import { Chart } from "@antv/g2";
import { uniqueId } from "@antv/util";
import { theme } from "antd";
// config imports
import { DARK_GREEN } from "../../configs/configs";

export default function CustomBarChart({
  data = [],
  colorBar = DARK_GREEN,
  xAxis = "date",
  yAxis = "value",
  xAxisTitle = "",
  yAxisTitle = "",
  parentContainer = null, // this prop is used so that width and height of parent container can be retrieved
}) {
  // theme token
  const { token } = theme.useToken();

  const container = uniqueId("bar-");
  const chartRef = useRef(null);

  useEffect(() => {
    chartRef.current?.clear();

    const chartContainer = document.getElementById(container);
    const parentContainerId = document.getElementById(parentContainer);
    chartContainer.innerHTML = "";

    const chart = new Chart({
      container: container,
      width: parentContainerId?.clientWidth,
      height: parentContainerId?.clientHeight - 10,
      padding: "auto",
      autoFit: true,
    });

    chart
      .interval()
      .data(data)
      .encode("x", xAxis)
      .encode("y", yAxis)
      .encode("color", colorBar)
      .axis("x", {
        line: true,
        lineStroke: token.colorTextBase,
        tickStroke: token.colorTextBase,
        title: xAxisTitle,
        titleFill: token.colorTextBase,
        labelFill: token.colorTextBase,
      })
      .axis("y", {
        line: true,
        lineStroke: token.colorTextBase,
        tickStroke: token.colorTextBase,
        title: yAxisTitle,
        titleFill: token.colorTextBase,
        labelFill: token.colorTextBase,
      })
      .legend({
        color: {
          itemLabelFill: token.colorTextBase,
        },
      })
      .interaction("tooltip", {
        render: (event, { title, items }) => {
          return `<div style="width:150px;">
              <h3 style="padding:0;margin:0">${title}</h3>
              <ul style="padding: 0; margin: 0; list-style: none;">
                ${items
                  .map((d) => {
                    // Format the value with commas and 2 decimal places
                    const formattedValue = d.value
                      ? Number(d.value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : d.value === 0
                      ? Number(d.value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : d.value;
                    return `
                      <li style="display: flex; justify-content: space-between; align-items: center; margin: 4px 0;">
                        <span style="display: inline-flex; align-items: center;">
                          <span style="width: 8px; height: 8px; background-color: ${d.color}; border-radius: 50%; margin-right: 8px;"></span>
                          <span>${d.name}</span>
                        </span>
                        <span>${formattedValue}</span>
                      </li>`;
                  })
                  .join("")}
              </ul>
            </div>`;
        },
      });

    chart.render();

    chartRef.current = chart;

    return () => {
      chartRef.current?.destroy();
    };
  }, [
    token,
    data,
    document.getElementById(parentContainer)?.clientWidth,
    document.getElementById(parentContainer)?.clientHeight,
  ]);

  return <div style={{ width: "100%", height: "100%" }} id={container}></div>;
}

import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Tooltip, Modal, Divider, Flex, Popconfirm, Spin } from "antd";
import { useTableSearch } from "../../../functions/tableSearch";
import UserGroupForm from "./UserGroupForm";
import { useEffect, useState, useContext } from "react";
import { IndexContext } from "../../../context/IndexContext";
import useFetch from "../../../hooks/useFetch";
import DataTable from "../../../components/common/DataTable";

export default function UserGroupView(props) {
  const { refreshOrganisationNameFilter, refreshOrganisationNameSelection } =
    props;

  const [firstLoad, setFirstLoad] = useState(true);

  const { getColumnSearchProps } = useTableSearch();

  const [fetchWrapper] = useFetch();
  const [loading, setLoading] = useState(false);

  const [isAddUserGroupModalOpen, setIsAddUserGroupModalOpen] = useState(false);

  const [isEditUserGroupModalOpen, setIsEditUserGroupModalOpen] =
    useState(false);

  const [userGroupDetails, setUserGroupDetails] = useState([]);

  const [selectedUserGroup, setSelectedUserGroup] = useState({});

  // load index context
  const { messageApi } = useContext(IndexContext);

  const userGroupTableColumns = [
    {
      title: "Organisation Name",
      dataIndex: "OrganisationName",
      key: "OrganisationName",
      ...getColumnSearchProps("OrganisationName"),
      width: "39%",
    },
    {
      title: "Created At",
      dataIndex: "InsertDateTime",
      key: "InsertDateTime",
      width: "39%",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "22%",
      render: (_, record) => (
        <Flex justify="flex-start" align="center" gap={"5px"} wrap={true}>
          <Tooltip title="Edit">
            <Button
              type="text"
              icon={<EditOutlined style={{ color: "blue" }} />}
              onClick={() => {
                setIsEditUserGroupModalOpen(true);
                setSelectedUserGroup(record);
              }}
            />
          </Tooltip>

          {/* hide Delete button for now */}
          {/* <Tooltip title="Delete">
            <Popconfirm
              title="Delete User"
              description={"Are you sure to delete this user?"}
              onConfirm={() => {
                deleteUserGroup(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="text"
                icon={<DeleteOutlined style={{ color: "red" }} />}
              />
            </Popconfirm>
          </Tooltip> */}
        </Flex>
      ),
    },
  ];

  /* API to get user group details */
  function getUserGroupDetails() {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "user_group_management/getUserGroupDetails",
      onSuccess: (fetch_output) => {
        setLoading(false);
        setFirstLoad(false);
        if (fetch_output.status === true) {
          setUserGroupDetails(fetch_output.user_group_details);
        }
      },
    });
  }

  function deleteUserGroup(row_record) {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "user_group_management/deleteUserGroup",
      body: {
        user_group_id: row_record.ID,
      },
      onSuccess: (fetch_output) => {
        setLoading(false);
        if (fetch_output.status === true) {
          messageApi.open({
            type: "success",
            content: fetch_output.message,
          });
          getUserGroupDetails();
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
      },
    });
  }

  useEffect(() => {
    getUserGroupDetails();
  }, []);

  return (
    <>
      {firstLoad ? (
        <Flex justify="center" align="center" style={{ height: "50vh" }}>
          <Spin />
        </Flex>
      ) : (
        <>
          <Flex>
            <Button
              icon={<PlusOutlined />}
              className="mb-5"
              onClick={() => setIsAddUserGroupModalOpen(true)}
            >
              New User Group
            </Button>
          </Flex>

          {loading ? (
            <Flex justify="center" align="center" style={{ height: "50vh" }}>
              <Spin />
            </Flex>
          ) : (
            <DataTable
              table_column={userGroupTableColumns}
              table_data={userGroupDetails}
            />
          )}
        </>
      )}

      <Modal
        title="Add User Group"
        open={isAddUserGroupModalOpen}
        footer={null}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Divider className="mb-4 mt-0"></Divider>
        <UserGroupForm
          mode="add"
          setIsUserGroupFormModalOpen={setIsAddUserGroupModalOpen}
          refreshTable={getUserGroupDetails}
          refreshOrganisationNameFilter={refreshOrganisationNameFilter}
          refreshOrganisationNameSelection={refreshOrganisationNameSelection}
        />
      </Modal>

      <Modal
        title="Edit User Group"
        open={isEditUserGroupModalOpen}
        footer={null}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Divider className="mb-4 mt-0"></Divider>
        <UserGroupForm
          mode="edit"
          setIsUserGroupFormModalOpen={setIsEditUserGroupModalOpen}
          isUserGroupForm={false}
          refreshTable={getUserGroupDetails}
          initialValues={selectedUserGroup}
          refreshOrganisationNameFilter={refreshOrganisationNameFilter}
          refreshOrganisationNameSelection={refreshOrganisationNameSelection}
        />
      </Modal>
    </>
  );
}

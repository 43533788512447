// library imports
import { Skeleton, Flex, Divider, Tabs, Select } from "antd";
// component import
import BESSTable from "./BESSTable";
import { LabelValueUnitDisplayRow } from "../../common/WordDisplay";

export default function BESSContent({
  devicePhaseNumberList,
  deviceList,
  systemData,
  tableData,
  handleDeviceChange,
  bessSystemLoading,
  bessDeviceLoading,
  deviceSelectionLoading,
  selectedDeviceID,
  systemLastUpdate,
  deviceLastUpdate,
  setPhaseNumberSelected,
}) {
  return (
    <div className="system-col">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          align: "center",
          marginBottom: "12px",
        }}
      >
        <h2 style={{ margin: "0px" }}>BESS</h2>
        <span>Last Update: {systemLastUpdate}</span>
      </div>
      {bessSystemLoading ? (
        <Skeleton active style={{ margin: "5px" }} />
      ) : (
        <div className="system-row">
          <div className="content-box">
            <LabelValueUnitDisplayRow
              label={"Maximum Demand Reduced"}
              value={
                systemData?.maximumDemandReduced !== undefined
                  ? systemData.maximumDemandReduced
                  : "-"
              }
              unit={"kW"}
            />
            <LabelValueUnitDisplayRow
              label={"Status"}
              value={systemData?.status !== undefined ? systemData.status : "-"}
              unit={""}
            />
            <LabelValueUnitDisplayRow
              label={"Mode"}
              value={systemData?.mode !== undefined ? systemData.mode : "-"}
              unit={""}
            />
            <LabelValueUnitDisplayRow
              label={"Grid Mode"}
              value={
                systemData?.gridMode !== undefined ? systemData.gridMode : "-"
              }
              unit={""}
            />
          </div>
          <div className="content-box">
            <LabelValueUnitDisplayRow
              label={"Total Charging Power"}
              value={
                systemData?.totalChargingPower !== undefined
                  ? systemData.totalChargingPower
                  : "-"
              }
              unit={"kW"}
            />
            <LabelValueUnitDisplayRow
              label={"Total Discharging Power"}
              value={
                systemData?.totalDischargingPower !== undefined
                  ? systemData.totalDischargingPower
                  : "-"
              }
              unit={"kW"}
            />
            <LabelValueUnitDisplayRow
              label={"Dischargeable Energy"}
              value={
                systemData?.dischargeableEnergy !== undefined
                  ? systemData.dischargeableEnergy
                  : "-"
              }
              unit={"kWh"}
            />
            <LabelValueUnitDisplayRow
              label={"Chargeable Energy"}
              value={
                systemData?.chargeableEnergy !== undefined
                  ? systemData.chargeableEnergy
                  : "-"
              }
              unit={"kWh"}
            />
          </div>
          <div className="content-box">{/* empty space */}</div>
        </div>
      )}

      <div className="content-box">
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            align: "center",
          }}
        >
          <h2 style={{ margin: "0px" }}>BESS Main Parameters</h2>
          <span>Last Update: {deviceLastUpdate}</span>
        </div>
        <Tabs
          items={devicePhaseNumberList}
          onChange={(key) => setPhaseNumberSelected(key)}
        />
        <Flex justify="space-between" align="center">
          {deviceList.length > 0 && selectedDeviceID ? (
            <Select
              loading={deviceSelectionLoading}
              onChange={(value) => handleDeviceChange(value)}
              value={selectedDeviceID}
              options={deviceList}
              style={{ width: "100%", marginRight: "8px" }}
            />
          ) : (
            <></>
          )}
        </Flex>
        {bessDeviceLoading ? (
          <Skeleton active style={{ margin: "5px" }} />
        ) : (
          <div>
            <div className="system-row power-meter-phases">
              <BESSTable data={tableData} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

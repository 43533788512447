// library imports
import { useEffect, useState, useContext } from "react";
// hook imports
import useFetch from "../../../hooks/useFetch";
// context imports
import { IndexContext } from "../../../context/IndexContext";
// component import
import TNBContent from "./TNBContent";
import DeviceInformation from "../common/DeviceInformation";
// config imports
import { MQTT_REFRESH_RATE } from "../../../configs/configs";

export default function TNB(props) {
  const { utilityCard, handleChange, openModal, setOpenModal } = props;

  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  // device list for tnb system
  const [deviceList, setDeviceList] = useState([]);

  // currently selected device ID (to view the table / card data)
  const [selectedDeviceID, setSelectedDeviceID] = useState();

  // loading states
  const [utilitySystemLoading, setUtilitySystemLoading] = useState(true);
  const [utilityDeviceLoading, setUtilityDeviceLoading] = useState(true);
  const [deviceSelectionLoading, setDeviceSelectionLoading] = useState(false);

  // table, card and system data
  const [utilityTableData, setUtilityTableData] = useState([]);
  const [utilityCardData, setUtilityCardData] = useState([]);
  const [systemData, setSystemData] = useState({});

  // last update date and time
  const [systemLastUpdate, setSystemLastUpdate] = useState("-");
  const [deviceLastUpdate, setDeviceLastUpdate] = useState("-");

  /** API to get device name for the device selection */
  function getDeviceName() {
    setDeviceSelectionLoading(true);
    fetchWrapper({
      endpoint_url: "system/getDeviceName",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },
      onSuccess: (response) => {
        if (response.data === false) {
          notificationApi.warning({
            message: response.message,
          });
        } else {
          setDeviceList(response.data?.INCOMING ?? []);
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setDeviceSelectionLoading(false);
      },
    });
  }

  /** API to get utility device data */
  function getUtilityDeviceData() {
    fetchWrapper({
      endpoint_url: "system/getDeviceData",
      method: "GET",
      set_content_type_header: false,
      params: {
        device_id: selectedDeviceID,
      },

      onSuccess: (response) => {
        setUtilityTableData(response.data.table);
        setUtilityCardData(response.data.card);
        setDeviceLastUpdate(response.data.last_update);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setUtilityDeviceLoading(false);
      },
    });
  }

  /** API to get utility system data */
  function getSystemUtility() {
    fetchWrapper({
      endpoint_url: "system/getSystemUtility",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        if (response.data === false) {
          notificationApi.warning({
            message: response.message,
          });
        } else {
          setSystemData(response.data.system_data);
          setSystemLastUpdate(response.data.last_update);
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setUtilitySystemLoading(false);
      },
    });
  }

  // use effect to get system data and device name
  // and set card, table and system data to empty list when site changes
  useEffect(() => {
    setUtilityCardData([]);
    setUtilityTableData([]);
    setSystemData([]);

    if (siteSelected) {
      getDeviceName();
      getSystemUtility();

      const timer = setInterval(() => {
        getSystemUtility();
      }, MQTT_REFRESH_RATE * 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelected]);

  // use effect to get device data
  useEffect(() => {
    if (selectedDeviceID) {
      getUtilityDeviceData();
    }

    const timer = setInterval(() => {
      if (selectedDeviceID) {
        getUtilityDeviceData();
      }
    }, MQTT_REFRESH_RATE * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [selectedDeviceID]);

  // use effect to set selected device id if device list is not empty
  // else set table and card data to empty list
  useEffect(() => {
    if (deviceList && deviceList.length > 0) {
      setSelectedDeviceID(deviceList[0].value);
    } else {
      setUtilityTableData([]);
      setUtilityCardData([]);
    }
  }, [deviceList]);

  return (
    <>
      <TNBContent
        deviceList={deviceList}
        utilityCard={utilityCard}
        handleChange={handleChange}
        setOpenModal={setOpenModal}
        handleDeviceChange={(key) => {
          setSelectedDeviceID(key);
          setUtilityDeviceLoading(true);
        }}
        utilitySystemLoading={utilitySystemLoading}
        utilityDeviceLoading={utilityDeviceLoading}
        deviceSelectionLoading={deviceSelectionLoading}
        selectedDeviceID={selectedDeviceID}
        tableData={utilityTableData}
        systemData={systemData}
        utilityCardData={utilityCardData}
        systemLastUpdate={systemLastUpdate}
        deviceLastUpdate={deviceLastUpdate}
      />
      <DeviceInformation
        deviceCardData={utilityCardData}
        openModal={openModal}
        setOpenModal={setOpenModal}
        title="Utility Card"
      />
    </>
  );
}

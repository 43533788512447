import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Typography,
  Flex,
  Modal,
  Popconfirm,
  Form,
  message,
} from "antd";
import { useEffect, useState, useContext } from "react";
import { IndexContext } from "../../../context/IndexContext";
import useFetch from "../../../hooks/useFetch";
import DeviceAlarmForm from "./DeviceAlarmForm";
import DataTable from "../../common/DataTable";
import "../../../styles/Configuration/SiteView.css";

const { Title } = Typography;

export default function DeviceAlarmView(props) {
  const { siteID } = props;
  const [fetchWrapper] = useFetch();
  const { notificationApi } = useContext(IndexContext);

  const [deviceAlarmConfigForm] = Form.useForm();

  // modal states
  const [isAddDeviceAlarmFormModalOpen, setIsAddDeviceAlarmFormModalOpen] =
    useState(false);
  const [isEditDeviceAlarmFormModalOpen, setIsEditDeviceAlarmFormModalOpen] =
    useState(false);

  const [deviceAlarmTableData, setDeviceAlarmTableData] = useState([]);
  const [editingDeviceAlarmID, setEditingDeviceAlarmID] = useState(null);

  const [
    submitDeviceAlarmDetailsButtonLoading,
    setSubmitDeviceAlarmDetailsButtonLoading,
  ] = useState(false);
  const [
    deviceAlarmDetailsRetrievalLoading,
    setDeviceAlarmDetailsRetrievalLoading,
  ] = useState(false);

  const columns = [
    {
      title: "Device",
      dataIndex: "DeviceName",
      key: "DeviceName",
    },
    {
      title: "Alarm Code",
      dataIndex: "AlarmCode",
      key: "AlarmCode",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-start" }}
        >
          <Button
            type="text"
            icon={<EditOutlined style={{ color: "blue" }} />}
            onClick={() => {
              setIsEditDeviceAlarmFormModalOpen(true);
              getEditingDeviceAlarm(record.ID);
              setEditingDeviceAlarmID(record.ID);
            }}
          />
          <Popconfirm
            title="Delete Device Alarm"
            description={"Are you sure to delete this device alarm?"}
            onConfirm={() => {
              deleteDeviceAlarm(record.ID);
            }}
            onCancel={() => {
              message.success("Action is cancelled");
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  /** API to get all device alarm data*/
  function getAllDeviceAlarm() {
    fetchWrapper({
      endpoint_url: "device_alarm_management/getAllDeviceAlarm",
      method: "GET",
      params: {
        site_id: siteID,
      },
      set_content_type_header: false,
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setDeviceAlarmTableData(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFetchError: (error) => {},
    });
  }

  /** API to add device alarm data*/
  function addDeviceAlarm(value) {
    setSubmitDeviceAlarmDetailsButtonLoading(true);

    fetchWrapper({
      endpoint_url: "device_alarm_management/addDeviceAlarm",
      body: {
        site_id: siteID,
        device_id: value.DeviceID,
        alarm_code: value.AlarmCode,
        desc: value.Description,
      },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        deviceAlarmConfigForm.resetFields();
        setIsAddDeviceAlarmFormModalOpen(false);
        getAllDeviceAlarm();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setSubmitDeviceAlarmDetailsButtonLoading(false);
      },
    });
  }

  /** API to get device alarm that user choose to edit */
  function getEditingDeviceAlarm(device_alarm_id) {
    setDeviceAlarmDetailsRetrievalLoading(true);

    fetchWrapper({
      endpoint_url: "device_alarm_management/getEditingDeviceAlarm",
      method: "GET",
      params: {
        device_alarm_id: device_alarm_id,
      },
      set_content_type_header: false,

      onSuccess: (response) => {
        deviceAlarmConfigForm.setFieldsValue(response.data);
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFetchError: (error) => {},
      onFinish: () => {
        setDeviceAlarmDetailsRetrievalLoading(false);
      },
    });
  }

  /** API to update device alarm that user choose to edit */
  function updateDeviceAlarm(value) {
    setSubmitDeviceAlarmDetailsButtonLoading(true);

    fetchWrapper({
      endpoint_url: "device_alarm_management/updateDeviceAlarm",
      body: {
        device_alarm_id: editingDeviceAlarmID,
        device_id: value.DeviceID,
        alarm_code: value.AlarmCode,
        desc: value.Description,
      },

      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setIsEditDeviceAlarmFormModalOpen(false);
        deviceAlarmConfigForm.resetFields();
        getAllDeviceAlarm();
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFetchError: (error) => {},
      onFinish: () => {
        setSubmitDeviceAlarmDetailsButtonLoading(false);
      },
    });
  }

  /** API to delete device alarm that user choose to delete */
  function deleteDeviceAlarm(deletingDeviceAlarmID) {
    fetchWrapper({
      endpoint_url: "device_alarm_management/deleteDeviceAlarm",
      method: "DELETE",
      set_content_type_header: false,
      params: { device_alarm_id: deletingDeviceAlarmID },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        getAllDeviceAlarm();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  /* API to check whether device alarm name is unique */
  function checkUniqueName(SiteID, Name, ID, callback) {
    fetchWrapper({
      endpoint_url: "device_alarm_management/checkUniqueDeviceAlarmCode",
      body: {
        site_id: SiteID,
        name: Name,
        device_alarm_id: ID,
      },
      onSuccess: (response) => {
        if (!response.data) {
          callback(response.message);
        } else {
          callback();
        }
      },
    });
  }

  useEffect(() => {
    if (siteID) {
      getAllDeviceAlarm();
    }
  }, [siteID]);

  return (
    <>
      <div>
        <Title level={3} className="mt-0">
          Device Alarm
        </Title>
        <Flex justify="flex-start" align="center" wrap={true}>
          <Button
            icon={<PlusOutlined />}
            className="mb-5 mr-2"
            onClick={() => setIsAddDeviceAlarmFormModalOpen(true)}
          >
            New Device Alarm
          </Button>
        </Flex>

        <DataTable
          table_column={columns}
          table_data={deviceAlarmTableData}
          scroll_width={700}
        />

        <Modal
          centered
          title="Add New Device Alarm"
          open={isAddDeviceAlarmFormModalOpen}
          footer={null}
          closable={false}
          destroyOnClose={true}
          maskClosable={false}
        >
          <DeviceAlarmForm
            setIsDeviceAlarmFormModalOpen={setIsAddDeviceAlarmFormModalOpen}
            mode="add"
            addDeviceAlarm={addDeviceAlarm}
            form={deviceAlarmConfigForm}
            submitButtonLoading={submitDeviceAlarmDetailsButtonLoading}
            checkUniqueName={checkUniqueName}
            siteID={siteID}
          />
        </Modal>

        <Modal
          centered
          title="Edit Device Alarm"
          open={isEditDeviceAlarmFormModalOpen}
          footer={null}
          closable={false}
          destroyOnClose={true}
          maskClosable={false}
        >
          <DeviceAlarmForm
            setIsDeviceAlarmFormModalOpen={setIsEditDeviceAlarmFormModalOpen}
            mode="edit"
            updateDeviceAlarm={updateDeviceAlarm}
            form={deviceAlarmConfigForm}
            submitButtonLoading={submitDeviceAlarmDetailsButtonLoading}
            deviceAlarmDetailsRetrievalLoading={
              deviceAlarmDetailsRetrievalLoading
            }
            checkUniqueName={checkUniqueName}
            selectedEditDeviceAlarmID={editingDeviceAlarmID}
            siteID={siteID}
          />
        </Modal>
      </div>
    </>
  );
}

// library imports
import { Card, Divider, Select } from "antd";
import { useEffect, useState, useContext } from "react";
// style imports
import "../../styles/SiteList/SiteListPage.css";
// component imports
import { FigureDisplay } from "../../components/common/FigureDisplay";
import { _SiteTypeSummary } from "./_SiteTypeSummary";
import { _SiteInfo } from "./_SiteInfo";
// context imports
import { IndexContext } from "../../context/IndexContext";
// hook imports
import useFetch from "../../hooks/useFetch";
import { formatToTwoDecimalPlaces } from "../../functions/formatToTwoDecimalPlaces";

const STATUS_MENU = [
  {
    value: "All",
    label: "All",
    color: "black",
  },
  {
    value: "Normal",
    label: "Normal",
    color: "green",
  },
  {
    value: "Warning",
    label: "Warning",
    color: "yellow",
  },
  {
    value: "Error",
    label: "Error",
    color: "red",
  },
  {
    value: "Offline",
    label: "Offline",
    color: "grey",
  },
];

/**
 * Component: Site List Page
 */
export default function SiteListPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchWrapper] = useFetch();
  const { notificationApi } = useContext(IndexContext);

  const [siteSummary, setSiteSummary] = useState({
    totalSites: 0,
    normalSites: 0,
    warningSites: 0,
    errorSites: 0,
    offlineSites: 0,
    totalCapacity: 0,
    totalEnergyGeneration: 0,
    totalIncome: 0,
  });

  const [siteData, setSiteData] = useState([]);
  const [statusSelection, setStatusSelection] = useState("All");

  function getSiteList() {
    fetchWrapper({
      endpoint_url: "site_list/getSiteList",
      method: "GET",
      set_content_type_header: false,

      onSuccess: (response) => {
        setSiteData(response.data.all_site_lists);
        setSiteSummary({
          totalSites: response.data.site_summary.total_site,
          normalSites: response.data.site_summary.normal_site,
          warningSites: response.data.site_summary.warning_site,
          errorSites: response.data.site_summary.error_site,
          offlineSites: response.data.site_summary.offline_site,
          totalCapacity: response.data.site_summary.total_capacity,
          totalEnergyGeneration:
            response.data.site_summary.total_energy_generation,
          totalIncome: response.data.site_summary.total_revenue,
        });
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setIsLoading(false);
      },
    });
  }

  useEffect(() => {
    getSiteList();
  }, []);

  let filteredSiteData =
    statusSelection === "All"
      ? siteData
      : siteData.filter((site) => site.Status === statusSelection);

  return (
    <>
      <Card
        title={"Site List"}
        style={{ overflowY: "hidden", margin: "10px", height: "95%" }}
        loading={isLoading}
      >
        <div className="flexbox-container-col">
          <div className="grid-container">
            <div className="flexbox-container-row summary-box-margin center-alignment-both-axis">
              <FigureDisplay
                figure={siteSummary.totalSites}
                unit={""}
                caption={"Sites"}
              />
              <_SiteTypeSummary
                normal={siteSummary.normalSites}
                warning={siteSummary.warningSites}
                offline={siteSummary.offlineSites}
                error={siteSummary.errorSites}
              />
            </div>

            <FigureDisplay
              figure={formatToTwoDecimalPlaces(siteSummary.totalCapacity)}
              unit={"kWp"}
              caption={"Total Capacity"}
            />
            <FigureDisplay
              figure={formatToTwoDecimalPlaces(
                siteSummary.totalEnergyGeneration
              )}
              unit={"kWh"}
              caption={"Generated This Month"}
            />
            <FigureDisplay
              figure={formatToTwoDecimalPlaces(siteSummary.totalIncome)}
              unit={"MYR"}
              caption={"Income/Savings"}
            />
          </div>

          <Divider style={{ marginBottom: "0px" }} />

          <div
            id="status-selection-bar"
            className="flexbox-container-row center-alignment-cross-axis"
          >
            <span id="status-label"> Status: </span>
            <Select
              defaultValue={statusSelection}
              style={{
                width: 120,
              }}
              onChange={(value) => setStatusSelection(value)}
              options={STATUS_MENU}
            />
          </div>

          <div
            id="site-container"
            className="flexbox-container-row wrap-container"
          >
            {filteredSiteData.map((data) => (
              <_SiteInfo
                key={data.Name}
                id={data.ID}
                name={data.Name}
                capacity={formatToTwoDecimalPlaces(data.CapacityInkWp)}
                power={formatToTwoDecimalPlaces(data.Energy)}
                status={data.Status}
              />
            ))}
          </div>
        </div>
      </Card>
    </>
  );
}

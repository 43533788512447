import { Chart } from "@antv/g2";
import * as _ from "@antv/util";
import React, { useEffect, useRef } from "react";
import { theme } from "antd";

export default function CustomLineAreaChart({
  dataLine = [],
  dataArea = [],
  yLine = "value",
  xLine = "time",
  colorLine = "series",
  yArea = "value",
  xArea = "time",
  colorArea = "series",
  xAxisTitleLine = false,
  yAxisTitleLine = false,
  xAxisTitleArea = false,
  yAxisTitleArea = false,
  chartDomainLine = [],
  chartRangeLine = [],
}) {
  const container = _.uniqueId("lineareachart-");
  const chartRef = useRef(null);
  const { token } = theme.useToken();

  useEffect(() => {
    chartRef.current?.clear();
    const chartContainer = document.getElementById(container);
    chartContainer.innerHTML = "";

    const chart = new Chart({
      container: container,
      width: container.offsetWidth,
      height: container.offsetHeight,
      padding: "auto",
      autoFit: true,
    });

    chart
      .line()
      .data(dataLine)
      .encode("x", xLine)
      .encode("y", yLine)
      .encode("color", colorLine)
      .encode("series", "type1") // to show legend per series
      .encode("shape", "smooth")
      .scale("y", { independent: true })
      .scale("color", {
        domain: chartDomainLine,
        range: chartRangeLine,
      })
      .axis("x", {
        title: xAxisTitleLine,
        lineStroke: token?.colorTextBase,
        tickStroke: token?.colorTextBase,
        titleFill: token?.colorTextBase,
        labelFill: token?.colorTextBase,
      })
      .axis("y", {
        grid: null,
        title: yAxisTitleLine,
        lineStroke: token?.colorTextBase,
        tickStroke: token?.colorTextBase,
        titleFill: token?.colorTextBase,
        labelFill: token?.colorTextBase,
      })
      .legend({
        color: {
          itemLabelFill: token.colorTextBase,
        },
      })
      .tooltip({ channel: "y", valueFormatter: ",.2f" });

    chart
      .area()
      .data(dataArea)
      .encode("x", xArea)
      .encode("y", yArea)
      .encode("color", colorArea)
      .scale("y", { nice: true })
      .axis("x", {
        title: xAxisTitleArea,
        lineStroke: token?.colorTextBase,
        tickStroke: token?.colorTextBase,
        titleFill: token?.colorTextBase,
        labelFill: token?.colorTextBase,
      })
      .axis("y", {
        title: yAxisTitleArea,
        position: "right",
        titleSpacing: 20,
        lineStroke: token?.colorTextBase,
        tickStroke: token?.colorTextBase,
        titleFill: token?.colorTextBase,
        labelFill: token?.colorTextBase,
      })
      .style("fill", "gray")
      .style("fillOpacity", 0.5)
      .tooltip({ channel: "y", valueFormatter: ",.2f" });

    chart.render();

    chartRef.current = chart;

    return () => {
      chartRef.current?.destroy();
    };
  }, [token, dataLine, dataArea]);

  useEffect(() => {
    const chartContainer = document.getElementById(container);
    const resizeObserver = new ResizeObserver(() => {});

    resizeObserver.observe(chartContainer);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <div id={container} style={{ height: "100%", width: "100%" }}></div>
    </>
  );
}

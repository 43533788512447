// library imports
import { Card, Skeleton } from "antd";
import { useState, useEffect, useContext } from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// style imports
import "../../styles/Dashboard/PVConditionAlarmContent.css";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";
// config imports
import { MQTT_REFRESH_RATE } from "../../configs/configs";

function PVConditionContent({ setRefreshCheck }) {
  const navigate = useNavigate();

  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  const [loading, setLoading] = useState(true);

  const [noOutage, setNoOutage] = useState(true);
  const [noShading, setNoShading] = useState(true);
  const [noFuseBlow, setNoFuseBlow] = useState(true);
  const [noSoiling, setNoSoiling] = useState(true);

  const onCardClick = () => {
    navigate("/Alarm/PVCondition");
  };

  const colorSetting = (condition) => {
    return condition ? { color: "green" } : { color: "red" };
  };

  const pvConditionIcon = (condition) => {
    return condition ? (
      <CheckCircleOutlined style={{ color: "green" }} />
    ) : (
      <CloseCircleOutlined style={{ color: "red" }} />
    );
  };

  function getPvConditionData() {
    fetchWrapper({
      endpoint_url: "dashboard/getPvConditionData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        if (response.data.length != 0) {
          setNoOutage(response.data[0]["NoOutage"]);
          setNoShading(response.data[0]["NoShading"]);
          setNoFuseBlow(response.data[0]["NoFuseBlow"]);
          setNoSoiling(response.data[0]["NoSoiling"]);
          setRefreshCheck(true);
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteSelected) {
      getPvConditionData();

      const timer = setInterval(() => {
        getPvConditionData();
      }, MQTT_REFRESH_RATE * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelected]);

  return (
    <>
      <div
        onClick={() => {
          onCardClick();
        }}
      >
        {loading === false ? (
          <div className="pvcondition-alarm-content-container">
            <span>No Outage {pvConditionIcon(noOutage)}</span>
            <span>No Shading {pvConditionIcon(noShading)}</span>
            <span>No Fuse Blow {pvConditionIcon(noFuseBlow)}</span>
            <span>No Soiling {pvConditionIcon(noSoiling)}</span>
          </div>
        ) : (
          <Card
            size="small"
            style={{
              width: "100%",
              height: "100%",
            }}
            className="pv-condition-card"
          >
            <Skeleton
              active={true}
              style={{ padding: "0.4rem", maxWidth: "70%", margin: "auto" }}
              paragraph={{ rows: 1 }}
            />
          </Card>
        )}
      </div>
    </>
  );
}

export default PVConditionContent;

// library imports
import { Card } from "antd";
import { useState, useContext } from "react";
// component imports
import InverterReportContent from "../../components/system_report/InverterReportContent";
import DailyReportContent from "../../components/system_report/DailyReportContent";
import OverallReportContent from "../../components/system_report/OverallReportContent";
import AnnualReportContent from "../../components/system_report/AnnualReportContent";
// context imports
import { IndexContext } from "../../context/IndexContext";

export default function SystemReportPage() {
  const { hasInverter } = useContext(IndexContext);

  const tabList = hasInverter
    ? [
        {
          key: "dailyReportTab",
          tab: "Daily Report",
        },
        {
          key: "annualReportTab",
          tab: "Annual Report",
        },
        {
          key: "overallReportTab",
          tab: "Overall Report",
        },
        {
          key: "interverReportTab",
          tab: "Inverter Report",
        },
      ]
    : [
        {
          key: "dailyReportTab",
          tab: "Daily Report",
        },
        {
          key: "annualReportTab",
          tab: "Annual Report",
        },
        {
          key: "overallReportTab",
          tab: "Overall Report",
        },
      ];

  const [activeTabKey, setActiveTabKey] = useState(tabList[0].key);
  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  const contentList = {
    dailyReportTab: <DailyReportContent />,
    overallReportTab: <OverallReportContent />,
    annualReportTab: <AnnualReportContent />,
    interverReportTab: <InverterReportContent />,
  };

  return (
    <Card
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      style={{
        overflowY: "hidden",
        marginLeft: "10px",
        marginRight: "10px",
        height: "100%",
      }}
    >
      {contentList[activeTabKey]}
    </Card>
  );
}

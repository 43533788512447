// library imports
import { useState } from "react";
// hook imports
import useFetch from "../hooks/useFetch";

export default function useSiteSubscription() {
  const [fetchWrapper] = useFetch();

  const [getFeatureSubscriptionLoading, setGetFeatureSubscriptionLoading] =
    useState(false);

  const [subscribePpaBilling, setSubscribePpaBilling] = useState(false);
  const [subscribeAiPvDiagnosis, setSubscribeAiPvDiagnosis] = useState(false);
  const [subscribeCarbonManagement, setSubscribeCarbonManagement] =
    useState(false);
  const [subscribeAiBess, setSubscribeAiBess] = useState(false);

  /** API to get features that the site subscribes */
  function getFeatureSubscription({ siteSelected }) {
    setGetFeatureSubscriptionLoading(true);
    fetchWrapper({
      endpoint_url: "layout/getFeatureSubscription",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },
      onSuccess: (response) => {
        setGetFeatureSubscriptionLoading(false);
        setSubscribePpaBilling(response.data.ppa_billing);
        setSubscribeAiPvDiagnosis(response.data.ai_pv_diagnosis);
        setSubscribeCarbonManagement(response.data.carbon_management);
        setSubscribeAiBess(response.data.ai_bess);
      },
    });
  }

  return [
    getFeatureSubscriptionLoading,
    subscribePpaBilling,
    subscribeAiPvDiagnosis,
    subscribeCarbonManagement,
    subscribeAiBess,
    getFeatureSubscription,
  ];
}

// library imports
import { Card, Segmented, Carousel, Skeleton, Empty } from "antd";
import { useEffect, useState, useRef, useContext } from "react";
import dayjs from "dayjs";
// style imports
import "../../styles/Dashboard/DashboardPage.css";
// component imports
import WeatherCard from "../../components/dashboard/WeatherCard";
import SystemInformationCard from "../../components/dashboard/SystemInformationCard";
import SummaryCard from "../../components/dashboard/SummaryCard";
import StatisticGraph from "../../components/dashboard/StatisticGraph";
import PowerEnergyCard from "../../components/dashboard/PowerEnergyCard";
import PVConditionContent from "../../components/dashboard/PVConditionContent";
import AlarmContent from "../../components/dashboard/AlarmContent";
import HeatmapCard from "../../components/dashboard/HeatmapCard";
import SocChartCard from "../../components/dashboard/SocChartCard";
import CustomBarChart from "../../components/common/CustomBarChart";
// context imports
import { IndexContext } from "../../context/IndexContext";
// hook imports
import useFetch from "../../hooks/useFetch";

const powerEnergyDateTypeOptions = [
  { label: "Day", value: "day" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

export default function DashboardPage() {
  const {
    hasInverterSelected,
    subscribeAiBess,
    subscribeAiPvDiagnosis,
    getHasInverterSelectedLoading,
    getFeatureSubscriptionLoading,
    siteSelected,
    notificationApi,
  } = useContext(IndexContext);

  const heightRef = useRef();

  const [fetchWrapper] = useFetch();

  // date selection for power and energy demand chart
  const [powerEnergyDateSelection, setPowerEnergyDateSelection] = useState(
    dayjs()
  );
  // date type selection for power and energy demand chart
  const [powerEnergyDateTypeSelection, setPowerEnergyDateType] = useState(
    powerEnergyDateTypeOptions[0].value
  );

  // pv condition alarm option
  const pvConditionAlarmRadioOptions = [
    { label: "Alarm", value: "alarm" },
    { label: "PV Condition", value: "pvcondition" },
  ];
  const [pvConditionAlarmRadioSelection, setPvConditionAlarmRadioSelection] =
    useState(pvConditionAlarmRadioOptions[0].value);

  // sun hour data
  const [sunHourData, setSunHourData] = useState([]);
  const [loadingSunHourData, setLoadingSunHourData] = useState(false);

  const [refreshCheck, setRefreshCheck] = useState(false);
  const [checkDate, setCheckDate] = useState(
    dayjs().format("YYYY-MM-DD HH:mm")
  );

  /** API to get the sun hour data */
  function getSunHourData() {
    setLoadingSunHourData(true);

    fetchWrapper({
      endpoint_url: "dashboard/getSunHourData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setSunHourData(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoadingSunHourData(false);
      },
    });
  }

  useEffect(() => {
    if (refreshCheck) {
      setCheckDate(dayjs().format("YYYY-MM-DD HH:mm"));
      setRefreshCheck(false);
    }
  }, [refreshCheck]);

  useEffect(() => {
    if (siteSelected) {
      getSunHourData();
    }
  }, [siteSelected]);

  return (
    <div className="dashboard-container">
      <div className="weather-sysinfo-summary">
        <WeatherCard className={"weather-card"} />
        <SystemInformationCard
          className={"sys-info-card"}
          containerID={"dashboard-system-information-graph"}
        />

        <SummaryCard className={"summary-card"} />
      </div>

      <div className="all-graphs">
        <StatisticGraph
          className={"statistic-graphs"}
          cardClassName={"statistic-graph-card"}
        />

        <div className="other-graphs">
          <PowerEnergyCard
            className={"power-energy"}
            powerEnergyDateSelection={powerEnergyDateSelection}
            setPowerEnergyDateSelection={setPowerEnergyDateSelection}
            powerEnergyDateTypeSelection={powerEnergyDateTypeSelection}
            setPowerEnergyDateType={setPowerEnergyDateType}
          />

          <div className="condition-heatmap">
            <Card
              className="condition-alarm-card"
              title={
                <div className="condition-alarm-card-title">
                  {subscribeAiPvDiagnosis ? (
                    <Segmented
                      options={pvConditionAlarmRadioOptions}
                      onChange={(value) => {
                        setPvConditionAlarmRadioSelection(value);
                      }}
                      value={pvConditionAlarmRadioSelection}
                    />
                  ) : (
                    <div>Alarm</div>
                  )}

                  <span className="condition-alarm-card-title-span">
                    Last Check:{" "}
                    <span>
                      <b>{checkDate}</b>
                    </span>
                  </span>
                </div>
              }
            >
              {pvConditionAlarmRadioSelection == "pvcondition" ? (
                <PVConditionContent setRefreshCheck={setRefreshCheck} />
              ) : (
                <AlarmContent setRefreshCheck={setRefreshCheck} />
              )}
            </Card>

            <Card id="carousel-card" ref={heightRef} style={{ height: "100%" }}>
              {!getHasInverterSelectedLoading &&
              !getFeatureSubscriptionLoading &&
              !loadingSunHourData ? (
                <Carousel
                  style={{ height: "100%" }}
                  autoplay={false}
                  dotPosition="bottom"
                  dots={{ className: "bgPrimary" }}
                  infinite={false}
                >
                  {sunHourData.length === 0 ? (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Empty className="dashboard-empty" />
                    </div>
                  ) : (
                    <CustomBarChart
                      data={sunHourData}
                      xAxis="date"
                      yAxis="value"
                      xAxisTitle="Date"
                      yAxisTitle="Sun Hour (h)"
                      parentContainer={"carousel-card"}
                    />
                  )}
                  {hasInverterSelected ? (
                    <div>
                      <HeatmapCard heightRef={heightRef} />
                    </div>
                  ) : null}
                  {subscribeAiBess ? (
                    <div>
                      <SocChartCard
                        heightRef={heightRef}
                        powerEnergyDateSelection={powerEnergyDateSelection}
                        powerEnergyDateTypeSelection={
                          powerEnergyDateTypeSelection
                        }
                      />
                    </div>
                  ) : null}
                </Carousel>
              ) : (
                <div>
                  <Skeleton
                    active={true}
                    style={{
                      padding: "1rem",
                      maxWidth: "70%",
                      margin: "auto",
                    }}
                    paragraph={{ rows: 10 }}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
  StarFilled,
} from "@ant-design/icons";
import {
  Button,
  Flex,
  Modal,
  Typography,
  Tooltip,
  Popconfirm,
  Skeleton,
  Tag,
  Badge,
  Space,
} from "antd";
import { useMemo, useEffect, useState, useContext } from "react";
import DeviceForm from "./DeviceForm";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";
import DataTable from "../../common/DataTable";
import { useTableSearch } from "../../../functions/tableSearch";
import { render } from "@antv/g2";

const { Title } = Typography;

export default function DeviceView(props) {
  const { siteID } = props;

  const { messageApi } = useContext(IndexContext);
  const [fetchWrapper] = useFetch();

  const [loading, setLoading] = useState(false);

  const [isAddNewDeviceModalOpen, setIsAddNewDeviceModalOpen] = useState(false);
  const [isEditNewDeviceModalOpen, setIsEditNewDeviceModalOpen] =
    useState(false);

  const [deviceDetails, setDeviceDetails] = useState([]);
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState(null);

  const { getColumnSearchProps } = useTableSearch();

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      ...getColumnSearchProps("Name"),
    },
    {
      title: "Device Type",
      dataIndex: "TypeName",
      key: "TypeName",
    },
    {
      title: "Key",
      dataIndex: "Key",
      key: "Key",
    },
    {
      title: "Brand",
      dataIndex: "Brand",
      key: "Brand",
    },
    {
      title: "Model",
      dataIndex: "Model",
      key: "Model",
    },
    {
      title: "Protocol Type",
      dataIndex: "ProtocolType",
      key: "ProtocolType",
    },
    {
      title: "Rating in kWp",
      dataIndex: "RatingInkWp",
      key: "RatingInkWp",
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 100,
      render: (_, record) => (
        <Flex>
          <div className="mr-2">
            <Tooltip title="Edit">
              <EditOutlined
                style={{ color: "blue" }}
                onClick={() => {
                  setIsEditNewDeviceModalOpen(true);
                  setSelectedDeviceDetails(record);
                }}
              />
            </Tooltip>
          </div>

          <div>
            <Tooltip title="Delete">
              <Popconfirm
                title="Delete Device"
                description={
                  "Are you sure to delete this power meter together with its parameters?"
                }
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  deleteDevice(record.ID);
                }}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
            </Tooltip>
          </div>
        </Flex>
      ),
    },
  ];

  /* API to get power meter details */
  function getDeviceDetails() {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "device_management/getDeviceDetails",
      body: {
        site_id: siteID,
      },
      onSuccess: (fetch_output) => {
        setLoading(false);
        if (fetch_output.status === true) {
          setDeviceDetails(fetch_output.device_details);
        }
      },
    });
  }

  /* API to delete power meter data */
  function deleteDevice(device_id) {
    setLoading(true);

    fetchWrapper({
      endpoint_url: "device_management/deleteDevice",
      body: {
        device_id: device_id,
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          messageApi.open({
            type: "success",
            content: fetch_output.message,
          });
          getDeviceDetails();
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteID) {
      getDeviceDetails();
    }
  }, [siteID]);

  return (
    <>
      <div>
        <Title level={3} className="mt-0">
          Device
        </Title>
        <Flex justify="flex-start" align="center" wrap={true}>
          <Button
            icon={<PlusOutlined />}
            className="mb-5 mr-2"
            onClick={() => setIsAddNewDeviceModalOpen(true)}
          >
            New Device
          </Button>
        </Flex>
        {loading ? (
          <Skeleton />
        ) : (
          <DataTable
            table_column={columns}
            table_data={deviceDetails}
            scroll_width={700}
          />
        )}

        <Modal
          centered
          open={isAddNewDeviceModalOpen}
          title="Add Device"
          width={900}
          footer={false}
          style={{ width: "900px", maxWidth: "90vw" }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => setIsAddNewDeviceModalOpen(false)}
        >
          <div style={{ height: "75vh" }}>
            <DeviceForm
              siteID={siteID}
              mode="add"
              setIsDeviceFormModalOpen={setIsAddNewDeviceModalOpen}
              refreshTable={getDeviceDetails}
            />
          </div>
        </Modal>

        <Modal
          centered
          open={isEditNewDeviceModalOpen}
          title="Edit Device"
          footer={false}
          width={900}
          style={{ width: "900px", maxWidth: "90vw" }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => setIsEditNewDeviceModalOpen(false)}
        >
          <div style={{ height: "75vh" }}>
            <DeviceForm
              siteID={siteID}
              mode="edit"
              setIsDeviceFormModalOpen={setIsEditNewDeviceModalOpen}
              refreshTable={getDeviceDetails}
              initialValues={selectedDeviceDetails}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}

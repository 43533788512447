// library imports
import { Empty, Tree, message } from "antd";
import { useEffect, useState, useContext } from "react";
import { Line } from "@ant-design/plots";
import dayjs from "dayjs";
// component imports
import Analytics from "../../components/analytics/Analytics";
import _OverallEnergyChart from "../../components/dashboard/_OverallEnergyChart";
import { VerticalDateFilter } from "../../components/common/DateFilter";
// context imports
import { IndexContext } from "../../context/IndexContext";
// hook imports
import useFetch from "../../hooks/useFetch";

function DeviceParameterFilter({ treeData, checkedKeys, setCheckedKeys }) {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  };

  const onCheck = (checkedKeysValue) => {
    const filteredData = checkedKeysValue.filter((item) => item.includes(":"));
    setCheckedKeys(filteredData);
  };

  return (
    <Tree
      checkable
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={true}
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      treeData={treeData}
      selectable={false}
      height={"calc(100vh - 600px)"}
      style={{ overflowY: "scroll" }}
    />
  );
}
export default function ParameterTrendingPage() {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  const [isParameterDataLoading, setIsParameterDataLoading] = useState(false);
  const [isTrendingDataLoading, setIsTrendingDataLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [startDateSelection, setStartDateSelection] = useState(
    dayjs().subtract(6, "day")
  );
  const [endDateSelection, setEndDateSelection] = useState(dayjs());

  const [parameterTrendingData, setParameterTrendingData] = useState([]);
  const [trendingDownloadData, setTrendingDownloadData] = useState([]);

  const [treeData, setTreeData] = useState([]);
  const [
    deviceParameterFiltercheckedKeys, // the checked key will be in the format <DeviceKey>:<ParameterKey>
    setDeviceParameterFilterCheckedKeys,
  ] = useState([]);

  const onFilterClick = () => {
    getTrendingData();
  };

  const onExportClick = () => {
    if (trendingDownloadData.length < 1) {
      message.error("Please get the data first");
    } else {
      downloadTrendingData();
    }
  };

  const lineChartConfig = {
    autoFit: true,
    data: parameterTrendingData,
    xField: "date",
    yField: "value",
    seriesField: "deviceParameter",
    smooth: true,
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    point: true,
    xAxis: {
      title: {
        text: "Date",
        style: {
          fontSize: 16,
        },
      },
    },
    yAxis: {
      title: {
        text: "Value",
        style: {
          fontSize: 16,
        },
      },
    },
    meta: {
      value: {
        formatter: (value) =>
          value
            ? value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : value === 0
            ? value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : value, // format value to 2 decimal places
      },
    },
  };

  /** API to get the device parameter */
  function getDeviceParameter() {
    setIsParameterDataLoading(true);
    fetchWrapper({
      endpoint_url: "parameter_trending/getDeviceParameter",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setTreeData(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setIsParameterDataLoading(false);
      },
    });
  }

  /** API to get the trending data */
  function getTrendingData() {
    setIsTrendingDataLoading(true);
    fetchWrapper({
      endpoint_url: "parameter_trending/getTrendingData",
      method: "GET",
      set_content_type_header: false,
      params: {
        table_names: deviceParameterFiltercheckedKeys,
        start_date: startDateSelection
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: endDateSelection.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        site_id: siteSelected,
      },
      onSuccess: (response) => {
        if (response.data === false) {
          notificationApi.error({
            message: response.message,
          });
        } else {
          setParameterTrendingData(response.data.graph_data);
          setTrendingDownloadData(response.data.download_data);
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setIsTrendingDataLoading(false);
      },
    });
  }

  /** API to download parameter trending data */
  function downloadTrendingData() {
    setSaveLoading(true);

    fetchWrapper({
      endpoint_url: "parameter_trending/downloadParameterTrendingData",
      method: "POST",
      json_output: false,
      body: {
        data: trendingDownloadData,
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        if (response.headers.get("content-type") !== "application/json") {
          // no issue with the response. returned a spreadsheet
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Parameter_Data_${dayjs().format(
              "YYYYMMDD_HHmmss"
            )}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          });
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: "Failed to download file",
        });
      },

      onFinish: () => {
        setSaveLoading(false);
      },
    });
  }

  useEffect(() => {
    setParameterTrendingData([]);
    setDeviceParameterFilterCheckedKeys([]);
    if (siteSelected) {
      getDeviceParameter();
    }
  }, [siteSelected]);

  return (
    <Analytics
      title={"Trending"}
      contentComponent={
        parameterTrendingData.length > 0 ? (
          <Line {...lineChartConfig} />
        ) : (
          <Empty />
        )
      }
      isTrendingDataLoading={isTrendingDataLoading}
      isParameterDataLoading={isParameterDataLoading}
      filterComponent={
        <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <VerticalDateFilter
            startDateSelection={startDateSelection}
            setStartDateSelection={setStartDateSelection}
            endDateSelection={endDateSelection}
            setEndDateSelection={setEndDateSelection}
            saveLoading={saveLoading}
            onFilterClick={onFilterClick}
            onExportClick={onExportClick}
          />
          <span style={{ fontWeight: "bold" }}>Parameter Lists</span>
          <DeviceParameterFilter
            treeData={treeData}
            checkedKeys={deviceParameterFiltercheckedKeys}
            setCheckedKeys={setDeviceParameterFilterCheckedKeys}
          />
        </div>
      }
    />
  );
}

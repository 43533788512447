// library imports
import { useState } from "react";
// hook imports
import useFetch from "../hooks/useFetch";

export default function useHasWeatherStation() {
  const [fetchWrapper] = useFetch();

  // loading state
  const [getHasWeatherStationLoading, setGetHasWeatherStationLoading] =
    useState(false);

  // this state is to check whether the site has weather station
  const [hasWeatherStation, setHasWeatherStation] = useState(false);

  /* API to check whether site has weather station that is selected and not deleted */
  function getHasWeatherStation({ siteSelected }) {
    setGetHasWeatherStationLoading(true);
    fetchWrapper({
      endpoint_url: "layout/getHasWeatherStation",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },
      onSuccess: (response) => {
        setGetHasWeatherStationLoading(false);
        setHasWeatherStation(response.data);
      },
    });
  }

  return [hasWeatherStation, getHasWeatherStation, getHasWeatherStationLoading];
}

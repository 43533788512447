// library imports
import { useContext } from "react";
import { Flex, Form, Button } from "antd";
import TextArea from "antd/es/input/TextArea";
// context imports
import { IndexContext } from "../../context/IndexContext";
// hook imports
import useFetch from "../../hooks/useFetch";

const layout = {
  labelCol: {
    span: 24,
    padding: 0,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function AcknowledgeAlarmForm(props) {
  const {
    selectedAlarmIDs,
    setIsAcknowledgementModalOpen,
    setSelectedAlarmIDs,
    selectedAlarmView,
    getSelectedDeviceActiveAlarm,
    getSelectedDeviceHistoricalAlarm,
  } = props;

  const [form] = Form.useForm();

  const { notificationApi } = useContext(IndexContext);
  const [fetchWrapper] = useFetch();

  /* API to acknowledge alarm */
  function acknowledgeAlarm(user_inputs) {
    fetchWrapper({
      endpoint_url: "alarm/acknowledgeAlarm",
      body: {
        alarm_ids: selectedAlarmIDs,
        acknowledge_mark: user_inputs.remark,
      },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setIsAcknowledgementModalOpen(false);
        setSelectedAlarmIDs([]);
        if (selectedAlarmView === "Active") {
          getSelectedDeviceActiveAlarm();
        } else {
          getSelectedDeviceHistoricalAlarm();
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
    });
  }

  return (
    <Form
      form={form}
      {...layout}
      onFinish={(value) => {
        acknowledgeAlarm(value);
      }}
      clearOnDestroy
      autoComplete="off"
    >
      <Form.Item name={"remark"} label={"Remark"}>
        <TextArea autoSize={{ minRows: 5, maxRows: 10 }} allowClear />
      </Form.Item>

      <Flex justify="end" align="center" gap="middle">
        <Button
          type="default"
          onClick={() => setIsAcknowledgementModalOpen(false)}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit">
          Acknowledge
        </Button>
      </Flex>
    </Form>
  );
}

// library imports
import {
  Card,
  Image,
  Layout,
  Button,
  Form,
  Input,
  Space,
  theme,
  Spin,
  Flex,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ParticleEffect from "../../components/login/ParticleEffect";
import getBrowserFingerprint from "get-browser-fingerprint";
// context imports
import { IndexContext } from "../../context/IndexContext";
// hook imports
import useFetch from "../../hooks/useFetch";
import useAuthCheck from "../../hooks/useAuthCheck";
// asset imports
import reveaiLightTheme from "../../assets/reveai_light_theme.png";
import reveaiDarkTheme from "../../assets/reveai_dark_theme.png";

export default function LoginPage() {
  let navigate = useNavigate(); //for navigating
  const [login_processing, setLoginProcessing] = useState(false);

  // browser fingerprint
  const fingerprint = getBrowserFingerprint({
    hardwareOnly: true,
    enableScreen: false,
  });

  // load index context
  const { messageApi, notificationApi, isLightTheme } =
    useContext(IndexContext);

  // load auth checker
  const [authenticationState] = useAuthCheck();
  const [authChecking, setAuthChecking] = useState(true);

  const [fetchWrapper] = useFetch();

  // load the design token
  const { token } = theme.useToken();

  const onFinish = (values) => {
    login(values);
  };

  const onFinishFailed = (errorInfo) => {
    setLoginProcessing(false);
    console.log("Failed:", errorInfo);
  };

  const showLoading = () => {
    setLoginProcessing(true);
  };

  // for login in after trigger from the login button
  function login(user_inputs) {
    fetchWrapper({
      endpoint_url: "user_management/loginUser",
      params: {
        username: user_inputs.username,
        password: user_inputs.password,
        fingerprint: fingerprint,
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.login_status === true) {
          // login success
          messageApi.open({
            type: "success",
            content: "Login Success! Redirecting...",
          });

          setTimeout(() => {
            setLoginProcessing(false);
            navigate("/dashboard", { replace: true }); //replace this so back wont go back to login page
          }, 2000);
        } else {
          // login failed. display error message
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
          setLoginProcessing(false);
        }
      },
    });
  }

  // trigger on load
  useEffect(() => {
    // if auth status is true, redirect to dashboard instead of showing login page
    if (authenticationState?.status === true) {
      navigate("/Dashboard");
    } else if (authenticationState?.status === false) {
      // done checking, stop the loading, and show login page.
      setAuthChecking(false);
    } else if (authenticationState?.error) {
      // done checking but something wrong.
      setAuthChecking(false);
      notificationApi["error"]({
        message: "Server Error",
        description: "Unauthorized Access",
      });
    }
  }, [authenticationState]);

  return (
    <div>
      <Layout style={{ height: "100vh" }}>
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ParticleEffect particle_color={token.colorText} />
          {authChecking ? (
            <Flex align="center" justify="center" vertical={true} gap={80}>
              <Image
                src={isLightTheme ? reveaiLightTheme : reveaiDarkTheme}
                preview={false}
                width={"80%"}
                style={{
                  maxWidth: "450px",
                }}
              ></Image>
              <Spin
                indicator={<LoadingOutlined spin style={{ fontSize: 96 }} />}
              ></Spin>
            </Flex>
          ) : (
            <>
              <Card
                style={{
                  width: "95%",
                  maxWidth: "500px",
                  padding: "1rem 1.5rem",
                }}
              >
                <Form
                  name="basic"
                  requiredMark={false}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  loading={login_processing}
                  onSubmitCapture={showLoading}
                >
                  <Space size={"large"} direction={"vertical"}>
                    <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
                      <Image
                        src={isLightTheme ? reveaiLightTheme : reveaiDarkTheme}
                        preview={false}
                        width={"80%"}
                      ></Image>
                    </div>
                    <Form.Item
                      label="Username"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password size="large" />
                    </Form.Item>
                    <Form.Item style={{ paddingBottom: "0px" }}>
                      <Button
                        id="login-button"
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={login_processing}
                        style={{ width: "100%" }}
                      >
                        Log In
                      </Button>
                    </Form.Item>
                  </Space>
                </Form>
              </Card>
            </>
          )}
        </Layout.Content>
      </Layout>
    </div>
  );
}

// library imports
import { Divider } from "antd";
// style imports
import "../../styles/STReport/STReportPage.css";

export function _STReportRow({ title, value, additional, lastRow }) {
  const lastRowCSS = lastRow ? "st-report-last-row" : "";

  return (
    <div className={`st-report-row ${lastRowCSS}`}>
      <div className="st-report-col-1 align-center">
        <span className="st-report-row-title">{title}</span>
        {value === false ? (
          <></>
        ) : (
          <Divider className="st-report-divider" type="vertical" />
        )}
      </div>

      <div className="st-report-col-2">
        {value}
        {additional ?? <></>}
      </div>
    </div>
  );
}

import { Col, Flex, Grid, Row, Skeleton, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { ArrowLeftOutlined } from "@ant-design/icons";
import DeviceView from "../../../components/configuration/device/DeviceView";
import TelegramView from "../../../components/configuration/telegram_group/TelegramView";
import EmailView from "../../../components/configuration/email/EmailView";
import DeviceAlarmSettingsView from "../../../components/configuration/device_alarm_settings/DeviceAlarmSettingsView";
import DeviceAlarmView from "../../../components/configuration/device_alarm/DeviceAlarmView";
import DataReferenceSourceView from "../../../components/configuration/data_reference_source/DataReferenceSourceView";
import PVSystDataEntryView from "../../../components/configuration/pv_syst_data_entry/PVSystDataEntryView";

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

export default function ConfigurationSitePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();
  const screenSize = useBreakpoint();
  const [key, setKey] = useState(searchParams.get("Key") ?? "Device");
  let { siteID } = useParams();
  const [site, setSite] = useState(undefined);

  const tabItems = [
    {
      key: "Device",
      children: <DeviceView siteID={siteID}></DeviceView>,
      label: <div className="font-lg">Device</div>,
    },
    // {
    //   key: "Alarm",
    //   children: <DeviceAlarmView siteID={siteID}></DeviceAlarmView>,
    //   label: <div className="font-lg">Device Alarm</div>,
    // },
    {
      key: "AlarmSettings",
      children: (
        <DeviceAlarmSettingsView siteID={siteID}></DeviceAlarmSettingsView>
      ),
      label: <div className="font-lg">Device Alarm Settings</div>,
    },
    {
      key: "Telegram",
      children: <TelegramView siteID={siteID}></TelegramView>,
      label: <div className="font-lg">Telegram Group</div>,
    },
    {
      key: "Email",
      children: <EmailView siteID={siteID}></EmailView>,
      label: <div className="font-lg">Email</div>,
    },
    {
      key: "DataReferenceSource",
      children: (
        <DataReferenceSourceView siteID={siteID}></DataReferenceSourceView>
      ),
      label: <div className="font-lg">Data Reference Source</div>,
    },
    {
      key: "PVSystDataEntry",
      children: <PVSystDataEntryView siteID={siteID}></PVSystDataEntryView>,
      label: <div className="font-lg">PV Syst Data Entry</div>,
    },
  ];

  function getSite() {
    fetchWrapper({
      endpoint_url: "site_management/getSiteByID",
      method: "GET",
      set_content_type_header: false,
      params: { SiteID: siteID },
      onSuccess: (value) => {
        setSite(value.site);
      },
      onResponseError: (error) => {
        setSite(false);
      },
    });
  }

  useEffect(() => {
    if (key != null) {
      setSearchParams({ Key: key }, true);
    }
  }, [key]);

  useEffect(() => {
    getSite();
  }, []);

  if (siteID == null) {
    return <>Invalid Site ID</>;
  }

  if (site == false) {
    return <>Failed to get site information</>;
  }

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Title level={3} className="mt-2 mb-5 ml-5">
            <Flex align="center">
              <ArrowLeftOutlined
                className="mr-3"
                onClick={() => {
                  navigate("..?Key=Site");
                }}
              />
              {site == undefined ? (
                <Skeleton
                  active
                  className="mt-0 mb-0"
                  title={false}
                  paragraph={{ rows: 1, width: 200 }}
                ></Skeleton>
              ) : (
                <>Configure {site.Name}</>
              )}
            </Flex>
          </Title>
        </Col>
      </Row>
      <Row gutter={[12, 12]} style={{ height: "calc(100% - 60px)" }}>
        <Col span={24} style={{ minHeight: "100%" }}>
          <Tabs
            tabPosition={
              screenSize["lg"] || screenSize["xl"] || screenSize["xl"]
                ? "left"
                : "top"
            }
            destroyInactiveTabPane
            items={tabItems}
            activeKey={key}
            onChange={(key) => {
              setKey(key);
            }}
            tabBarStyle={{ minWidth: "250px" }}
            style={{ height: "100%" }}
          ></Tabs>
        </Col>
      </Row>
    </>
  );
}

// library imports
import { Input, Space } from "antd";

export function _CompactInput({ value, setValue, unit }) {
  return (
    <Space.Compact>
      <Input
        size="small"
        suffix={unit}
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        min={0}
      />
    </Space.Compact>
  );
}

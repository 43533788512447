// library imports
import { Empty, message, Segmented, Flex } from "antd";
import { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
// component imports
import Analytics from "../../components/analytics/Analytics";
import _OverallPowerDemandChart from "../../components/dashboard/_OverallPowerDemandChart";
import { VerticalDateFilter } from "../../components/common/DateFilter";
// context imports
import { IndexContext } from "../../context/IndexContext";
// hook imports
import useFetch from "../../hooks/useFetch";

const dateTypeOptions = [
  { label: "Day", value: "day" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

export default function PowerDemandPage() {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  const [isTrendingDataLoading, setIsTrendingDataLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [startDateSelection, setStartDateSelection] = useState(
    dayjs().subtract(6, "day")
  );
  const [endDateSelection, setEndDateSelection] = useState(dayjs());

  const [dateTypeSelection, setDateType] = useState(dateTypeOptions[0].value);

  const [powerDemandData, setPowerDemandData] = useState([]);
  const [loadData, setLoadData] = useState([]);

  const onFilterClick = () => {
    if (startDateSelection > endDateSelection) {
      message.error("End date must be later than or same as start date.");
    } else {
      getPowerDemandData();
    }
  };

  const onExportClick = () => {
    if (powerDemandData.length < 1) {
      message.error("Please get the data first");
    } else {
      if (powerDemandData.length < 1 && loadData.length < 1) {
        message.error("No data to export");
      } else {
        downloadPowerDemandData();
      }
    }
  };

  /** API to get the power demand data */
  function getPowerDemandData() {
    setIsTrendingDataLoading(true);
    fetchWrapper({
      endpoint_url: "power_demand/getPowerDemandData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        start_date: startDateSelection.format("YYYY-MM-DD HH:mm:ss"),
        end_date: endDateSelection.format("YYYY-MM-DD HH:mm:ss"),
        date_selection: dateTypeSelection,
      },

      onSuccess: (response) => {
        setPowerDemandData(response.data.power_demand_trending_data);
        setLoadData(response.data.load_data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setIsTrendingDataLoading(false);
      },
    });
  }

  /** API to download power demand data as excel */
  function downloadPowerDemandData() {
    setSaveLoading(true);

    fetchWrapper({
      endpoint_url: "power_demand/downloadPowerDemandData",
      method: "POST",
      json_output: false,
      body: {
        power_demand_data: powerDemandData,
        load_data: loadData,
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        if (response.headers.get("content-type") !== "application/json") {
          // no issue with the response. returned a spreadsheet
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Power_Demand_Data_${dayjs().format(
              "YYYYMMDD_HHmmss"
            )}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          });
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: "Failed to download file",
        });
      },

      onFinish: () => {
        setSaveLoading(false);
      },
    });
  }

  useEffect(() => {
    setPowerDemandData([]);
    setLoadData([]);
  }, [siteSelected]);

  return (
    <Analytics
      title={"Power Demand Distribution"}
      contentComponent={
        powerDemandData.length > 0 ? (
          <_OverallPowerDemandChart
            data_chart={powerDemandData}
            data_line={loadData}
          />
        ) : (
          <Empty />
        )
      }
      isTrendingDataLoading={isTrendingDataLoading}
      filterComponent={
        <div className="analytics-picker-type-segmented">
          <Flex vertical={true} gap={"8px"}>
            <span>Graph Type Selection</span>
            <Segmented
              style={{ marginBottom: "12px" }}
              options={dateTypeOptions}
              onChange={(value) => {
                setDateType(value);
              }}
              value={dateTypeSelection}
            />
          </Flex>
          <VerticalDateFilter
            startDateSelection={startDateSelection}
            setStartDateSelection={setStartDateSelection}
            endDateSelection={endDateSelection}
            setEndDateSelection={setEndDateSelection}
            saveLoading={saveLoading}
            onFilterClick={onFilterClick}
            onExportClick={onExportClick}
            pickerType={dateTypeSelection}
          />
        </div>
      }
    />
  );
}

import { Button, Form, Input, Flex } from "antd";
import { FORM_REQUIRED } from "../../../configs/configs";
import { useEffect, useState, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function UserGroupForm(props) {
  const {
    mode,
    setIsUserGroupFormModalOpen,
    refreshTable,
    initialValues,
    refreshOrganisationNameFilter,
    refreshOrganisationNameSelection,
  } = props;
  const [fetchWrapper] = useFetch();

  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);

  const [userGroupForm] = Form.useForm();

  // load index context
  const { messageApi } = useContext(IndexContext);

  /* API to check whether organisation name is unique */
  function checkUniqueOrganisationName(organisation_name, callback) {
    fetchWrapper({
      endpoint_url: "user_group_management/checkUniqueOrganisationName",
      body: {
        user_group_id: initialValues ? initialValues.ID : null,
        organisation_name: organisation_name ? organisation_name : "",
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          if (fetch_output.is_unique === true) {
            callback(); // No error, so pass an empty callback
          } else {
            callback(
              "Organisation name already exists. Please input another organisation name."
            );
          }
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
      },
    });
  }

  /* API to add user group */
  function addUserGroup(user_inputs) {
    setLoadingSubmitButton(true);
    fetchWrapper({
      endpoint_url: "user_group_management/addUserGroup",
      body: {
        user_group_data: user_inputs,
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          messageApi.open({
            type: "success",
            content: fetch_output.message,
          });
          setIsUserGroupFormModalOpen(false);
          userGroupForm.resetFields();
          refreshOrganisationNameFilter();
          refreshOrganisationNameSelection();
          refreshTable();
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
        setLoadingSubmitButton(false);
      },
    });
  }

  /* API to edit user group  */
  function editUserGroup(user_inputs) {
    setLoadingSubmitButton(true);
    fetchWrapper({
      endpoint_url: "user_group_management/editUserGroup",
      body: {
        user_group_id: initialValues.ID,
        user_group_data: user_inputs,
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          messageApi.open({
            type: "success",
            content: fetch_output.message,
          });
          setIsUserGroupFormModalOpen(false);
          userGroupForm.resetFields();
          refreshOrganisationNameFilter();
          refreshOrganisationNameSelection();
          refreshTable();
        } else {
          messageApi.open({
            type: "error",
            content: fetch_output.error,
          });
        }
        setLoadingSubmitButton(false);
      },
    });
  }

  useEffect(() => {
    if (initialValues) {
      if (Object.values(initialValues).length > 0) {
        userGroupForm.setFieldsValue(initialValues);
      }
    }
  }, [initialValues]);

  return (
    <>
      <Form
        form={userGroupForm}
        name="user-group-form"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={(values) => {
          if (mode === "add") {
            addUserGroup(values);
          } else {
            editUserGroup(values);
          }
        }}
        autoComplete="off"
      >
        <Form.Item
          label="Organisation Name"
          name="OrganisationName"
          rules={[
            {
              required: FORM_REQUIRED,
              message: "Organisation name cannot be empty.",
            },
            {
              validator: (_, value, callback) => {
                checkUniqueOrganisationName(value, callback);
              },
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input />
        </Form.Item>

        <Flex justify="center">
          <Button
            className="mr-2"
            onClick={() => {
              setIsUserGroupFormModalOpen(false);
              userGroupForm.resetFields();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingSubmitButton}
          >
            Submit
          </Button>
        </Flex>
      </Form>
    </>
  );
}

export default UserGroupForm;

// library imports
import { useState, useEffect, useContext } from "react";
import { Card, Empty, Skeleton } from "antd";
import dayjs from "dayjs";
// style imports
import "../../styles/Dashboard/SocChartCard.css";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";
// component imports
import _SocChart from "./_SocChart";
// config imports
import { MQTT_REFRESH_RATE } from "../../configs/configs";

export default function SocChartCard(props) {
  const { heightRef, powerEnergyDateSelection, powerEnergyDateTypeSelection } =
    props;

  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  const [loading, setLoading] = useState(true);

  const [socChartData, setSocChartData] = useState([]);
  const [chartDate, setChartDate] = useState(dayjs().format("YYYY-MM-DD"));

  /** API to get SOC chart data*/
  function getSocChartData() {
    fetchWrapper({
      endpoint_url: "dashboard/getSocChartData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
        date_selected: powerEnergyDateSelection
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        power_energy_chart_date_type: powerEnergyDateTypeSelection,
      },

      onSuccess: (response) => {
        setSocChartData(response.data.soc_chart_data);
        setChartDate(response.data.chart_date);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    setLoading(true);
    if (siteSelected && powerEnergyDateSelection) {
      getSocChartData();

      const timer = setInterval(() => {
        getSocChartData();
      }, MQTT_REFRESH_RATE * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelected, powerEnergyDateSelection, powerEnergyDateTypeSelection]);

  return (
    <>
      {loading === false ? (
        <Card
          size="small"
          className="soc-chart-card"
          title={"State of Charge (" + chartDate + ")"}
          style={{
            height: heightRef.current?.clientHeight,
          }}
        >
          <div className="soc-chart-card-content">
            {socChartData.length > 0 ? (
              <_SocChart data_line={socChartData} container={"soc-chart"} />
            ) : (
              <Empty
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              />
            )}
          </div>
        </Card>
      ) : (
        <Card
          size="small"
          className="soc-chart-card"
          style={{
            height: heightRef.current?.clientHeight,
          }}
        >
          <div className="soc-chart-card-content">
            <Skeleton
              active={true}
              style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
              paragraph={{ rows: 10 }}
            />
          </div>
        </Card>
      )}
    </>
  );
}

// library imports
import { Progress, Tag, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useContext } from "react";
// style imports
import "../../styles/Dashboard/SummaryCard.css";
// config imports
import {
  DARK_BLUE,
  DARK_GREEN,
  DARK_THEME_DARK_BLUE,
} from "../../configs/configs";
// context imports
import { IndexContext } from "../../context/IndexContext";

export default function _SingleContentMdNoBess({
  title,
  var1label,
  var2label,
  var1value,
  var2value,
  unit,
  tnbMDDateTime,
}) {
  const { isLightTheme } = useContext(IndexContext);

  let percentage1 = 100 * (var1value / (var1value + var2value));
  let percentage2 = 100 - percentage1;

  return (
    <div className="summary-content-content-container">
      <span>
        {title}:
        <span className="summary-content-value">
          {var1value !== "-" && var2value !== "-"
            ? `${parseFloat(var1value + var2value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${unit}`
            : "-"}
        </span>
      </span>

      <Progress
        percent={percentage1}
        strokeColor={DARK_GREEN}
        trailColor={isLightTheme ? DARK_BLUE : DARK_THEME_DARK_BLUE}
        showInfo={false}
        size={["100%", 20]}
      />

      <div className="summary-content-detail">
        <Tag color={DARK_GREEN}></Tag>

        <span>{var1label}:</span>

        <span className="summary-content-percentage">
          {var1value !== "-"
            ? `${parseFloat(var1value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${unit}`
            : `${var1value}`}
        </span>
      </div>

      <div className="summary-content-detail">
        <Tag color={isLightTheme ? DARK_BLUE : DARK_THEME_DARK_BLUE}></Tag>

        <span>{var2label}:</span>

        <span className="summary-content-percentage">
          {var2value !== "-" ? (
            <>
              {parseFloat(var2value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {unit}{" "}
              {title === "Max Demand" && (
                <Tooltip title={"Maximum demand occurs on: " + tnbMDDateTime}>
                  <InfoCircleOutlined />
                </Tooltip>
              )}
            </>
          ) : (
            `${var2value}`
          )}
        </span>
      </div>
    </div>
  );
}

// library imports
import { useEffect, useRef } from "react";
import { Chart } from "@antv/g2";
import { uniqueId } from "@antv/util";
import { theme } from "antd";
// config imports
import { DARK_GREEN, LIGHT_GREEN } from "../../configs/configs";

// pv energy demand chart config
export default function _PvEnergyChart(props) {
  const { data_chart } = props;

  // theme token
  const { token } = theme.useToken();

  const container = uniqueId("energy-");
  const chartRef = useRef(null);

  let chart_domain = ["PV (Self-Consumption)", "PV (Export)"];
  let chart_range = [DARK_GREEN, LIGHT_GREEN];

  const plotChart = () => {
    chartRef.current.clear();

    chartRef.current
      .interval()
      .data(data_chart)
      .encode("x", "date")
      .encode("y", "value")
      .encode("color", "type")
      .scale("color", {
        domain: chart_domain,
        range: chart_range,
      })
      .transform({ type: "stackY" })
      .interaction("elementHighlight", { background: true })
      .axis("x", {
        line: true,
        lineStroke: token.colorTextBase,
        tickStroke: token.colorTextBase,
        title: "Time",
        titleFill: token.colorTextBase,
        labelFill: token.colorTextBase,
      })
      .axis("y", {
        line: true,
        lineStroke: token.colorTextBase,
        tickStroke: token.colorTextBase,
        title: "Energy (kWh)",
        titleFill: token.colorTextBase,
        labelFill: token.colorTextBase,
      })
      .legend({
        color: {
          itemLabelFill: token.colorTextBase,
        },
      })
      .interaction("tooltip", {
        render: (event, { title, items }) => {
          return `<div style="width:150px;">
              <h3 style="padding:0;margin:0">${title}</h3>
              <ul style="padding: 0; margin: 0; list-style: none;">
                ${items
                  .map((d) => {
                    // Format the value with commas and 2 decimal places
                    const formattedValue = d.value
                      ? Number(d.value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : d.value === 0
                      ? Number(d.value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : d.value;
                    return `
                      <li style="display: flex; justify-content: space-between; align-items: center; margin: 4px 0;">
                        <span style="display: inline-flex; align-items: center;">
                          <span style="width: 8px; height: 8px; background-color: ${d.color}; border-radius: 50%; margin-right: 8px;"></span>
                          <span>${d.name}</span>
                        </span>
                        <span>${formattedValue}</span>
                      </li>`;
                  })
                  .join("")}
              </ul>
            </div>`;
        },
      });

    // put this so that tooltip label will work correctly
    chartRef.current.line().data([]).encode("x", "date").encode("y", "value");

    chartRef.current.render();
  };

  useEffect(() => {
    chartRef.current = new Chart({
      container: container,
      autoFit: true,
    });

    plotChart();

    return () => {
      chartRef?.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (data_chart?.length !== 0) {
      plotChart();
    }
  }, [data_chart, token]);

  return <div style={{ width: "100%", height: "100%" }} id={container}></div>;
}

import { Button, Flex } from "antd";
import { useContext } from "react";
import { IndexContext } from "../../../context/IndexContext";
import dayjs from "dayjs";

function SiteInfo(props) {
  const { setIsModalOpen, data } = props;

  // load index context
  const { authenticationState } = useContext(IndexContext);

  return (
    <>
      <p>Latitude: {data.Latitude}</p>
      <p>Longitude: {data.Longitude}</p>
      <p>
        Site Commissioning Date:{" "}
        {dayjs(data.SiteCommissioningDate).format("YYYY-MM-DD")}
      </p>
      {authenticationState.user_type === 1 ? (
        <p>Organisation Name: {data.OrganisationName}</p>
      ) : (
        <></>
      )}
      <p>Site Billing Date: {data.BillingDate}</p>
      <p>Site Offset Hour: {data.OffsetHour}</p>
      <p>Site Capacity in kWp: {data.CapacityInkWp}</p>
      <p>Site Connection Type: {data.ConnectionTypeText}</p>
      <p>Site PV Scheme: {data.PVSchemeText}</p>
      <p>Site Tariff Type: {data.TariffTypeText}</p>
      <p>PIC Name: {data.PicName}</p>
      <p>PIC Contact Number: {data.PicContact}</p>
      <p>PIC Email: {data.PicEmail}</p>
      <p>Site Configuration Status: {data.SiteManagementStatus}</p>
      <p>
        Site Configuration Status description: {data.SiteManagementDescription}
      </p>

      <Flex justify="center">
        <Button
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          Close
        </Button>
      </Flex>
    </>
  );
}

export default SiteInfo;

import { Form, InputNumber, Skeleton } from "antd";

export default function PVSystDataEntryInputGroup({ label, isLoading }) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "Year",
  ];

  const shortLabel = (label) => {
    let result = "";
    if (label === "Effective Energy at the Output of the Array (MWh)") {
      result = "MWh";
    } else if (label === "Global Horizontal Irradiation (kWh/m²)") {
      result = "kWh";
    } else if (label === "Performance Ratio") {
      result = "ratio";
    }
    return result;
  };

  return (
    <>
      {!isLoading ? (
        <div>
          {months.map((month, index) => (
            <div style={{ marginLeft: "5px", marginRight: "5px" }} key={index}>
              <Form.Item
                name={`${shortLabel(label)}-${month}`}
                label={month}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  aria-label={`${shortLabel(label)} ${month}`}
                />
              </Form.Item>
            </div>
          ))}
        </div>
      ) : (
        <Skeleton active />
      )}
    </>
  );
}

import { Col, Row, Tabs, Typography } from "antd";
import { useEffect, useState, useContext } from "react";
import SiteView from "../../components/configuration/site/SiteView";
import UserView from "../../components/configuration/user/UserView";
import UserGroupView from "../../components/configuration/user_group/UserGroupView";
import SmpView from "../../components/configuration/smp/SmpView";
import IcptView from "../../components/configuration/icpt/IcptView";
import { IndexContext } from "../../context/IndexContext";
import useFetch from "../../hooks/useFetch";
import { useSearchParams } from "react-router-dom";

const { Title } = Typography;

export default function ConfigurationPage() {
  const [tabPosition, setTabPosition] = useState(
    window.innerWidth <= 768 ? "top" : "left"
  );
  const [tabType, setTabType] = useState(
    window.innerWidth <= 768 ? "card" : "line"
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState(searchParams.get("Key") ?? "User");

  const [fetchWrapper] = useFetch();

  // load index context
  const { authenticationState } = useContext(IndexContext);

  const [organisationNameFilterList, setOrganisationNameFilterList] = useState(
    []
  );

  const [organisationNameSelectionList, setOrganisationNameSelectionList] =
    useState([]);

  const tabItems = [
    // ... is conditional spread operator that includes the User Group tab only if authenticationState.user_type === 1.
    ...(authenticationState.user_type === 1
      ? [
          {
            key: "Group",
            children: (
              <UserGroupView
                refreshOrganisationNameFilter={getOrganisationNameFilter}
                refreshOrganisationNameSelection={getOrganisationNameSelection}
              />
            ),
            label: <div className="font-lg">User Group</div>,
          },
        ]
      : []),
    {
      key: "User",
      children: (
        <UserView
          organisationNameSelectionList={organisationNameSelectionList}
        />
      ),
      label: <div className="font-lg">User</div>,
    },
    {
      key: "Site",
      children: (
        <SiteView
          organisationNameFilterList={organisationNameFilterList}
          organisationNameSelectionList={organisationNameSelectionList}
        />
      ),
      label: <div className="font-lg">Site</div>,
    },
    ...(authenticationState.user_type === 1
      ? [
          {
            key: "SMP",
            children: <SmpView />,
            label: <div className="font-lg">SMP</div>,
          },
        ]
      : []),
    ...(authenticationState.user_type === 1
      ? [
          {
            key: "ICPT",
            children: <IcptView />,
            label: <div className="font-lg">ICPT</div>,
          },
        ]
      : []),
  ];

  /* API to get organisation name filter for site selection (for superuser only) */
  function getOrganisationNameFilter() {
    fetchWrapper({
      endpoint_url: "site_management/getOrganisationNameFilter",
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          setOrganisationNameFilterList(fetch_output.organisation_name_list);
        }
      },
    });
  }

  /* API to get organisation name list if superuser is adding user */
  function getOrganisationNameSelection() {
    fetchWrapper({
      endpoint_url: "user_management/getOrganisationNameSelection",
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          setOrganisationNameSelectionList(fetch_output.organisation_name_list);
        }
      },
    });
  }

  useEffect(() => {
    if (authenticationState) {
      if (authenticationState.user_type === 1) {
        getOrganisationNameFilter();
        getOrganisationNameSelection();
      }
    }
  }, [authenticationState]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setTabPosition("top");
        setTabType("card");
      } else {
        setTabPosition("left");
        setTabType("line");
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Title level={3} className="mt-2 mb-5 ml-5">
            Configuration
          </Title>
        </Col>
      </Row>
      <Row gutter={[12, 12]} style={{ height: "calc(100% - 60px)" }}>
        <Col span={24} style={{ height: "100%" }}>
          <Tabs
            destroyInactiveTabPane
            items={tabItems}
            size="small"
            tabPosition={tabPosition}
            type={tabType}
            tabBarStyle={{ minWidth: "250px" }}
            style={{ height: "100%" }}
            activeKey={activeKey}
            onChange={(key) => {
              setSearchParams({ Key: key }, true);
              setActiveKey(key);
            }}
          ></Tabs>
        </Col>
      </Row>
    </>
  );
}

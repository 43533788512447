// library imports
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Tooltip,
  Modal,
  Divider,
  Flex,
  Popconfirm,
  Spin,
  DatePicker,
} from "antd";
import { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
// function imports
import { useTableSearch } from "../../../functions/tableSearch";
// component imports
import DataTable from "../../../components/common/DataTable";
import IcptForm from "./IcptForm";
// context imports
import { IndexContext } from "../../../context/IndexContext";
// hook imports
import useFetch from "../../../hooks/useFetch";

export default function IcptView() {
  const [fetchWrapper] = useFetch();

  const { getColumnSearchProps } = useTableSearch();

  // index context
  const { notificationApi } = useContext(IndexContext);

  // loading states
  const [firstLoad, setFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false); // loading for table

  // modal states
  const [isAddIcptModalOpen, setIsAddIcptModalOpen] = useState(false);
  const [isEditIcptModalOpen, setIsEditIcptModalOpen] = useState(false);
  const [selectedIcpt, setSelectedIcpt] = useState({});

  // table data
  const [icptDetails, setIcptDetails] = useState([]);

  // selection for year
  const [selectedYear, setSelectedYear] = useState(dayjs());

  const icptTableColumns = [
    {
      title: "Tariff Category",
      dataIndex: "TariffCategory",
      key: "TariffCategory",
      width: "20%",
      ...getColumnSearchProps("TariffCategory"),
    },
    {
      title: "Start Date",
      dataIndex: "StartDateTable",
      key: "StartDateTable",
      width: "20%",
    },
    {
      title: "End Date",
      dataIndex: "EndDateTable",
      key: "EndDateTable",
      width: "20%",
    },
    {
      title: "ICPT Value",
      dataIndex: "Value",
      key: "Value",
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "20%",
      render: (_, record) => (
        <Flex justify="flex-start" align="center" gap={"5px"} wrap={true}>
          <Tooltip title="Edit">
            <Button
              type="text"
              icon={<EditOutlined style={{ color: "blue" }} />}
              onClick={() => {
                setIsEditIcptModalOpen(true);
                setSelectedIcpt(record);
              }}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              title="Delete ICPT"
              description={"Are you sure to delete this ICPT?"}
              onConfirm={() => {
                deleteIcpt(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="text"
                icon={<DeleteOutlined style={{ color: "red" }} />}
              />
            </Popconfirm>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  /* API to get icpt details */
  function getIcptDetails() {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "icpt_management/getIcptDetails",
      method: "GET",
      set_content_type_header: false,
      params: {
        selected_year: selectedYear.startOf("year").format("YYYY-MM-DD"),
      },

      onSuccess: (response) => {
        setIcptDetails(response.data);
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
        setFirstLoad(false);
      },
    });
  }

  function deleteIcpt(row_record) {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "icpt_management/deleteIcpt",
      body: {
        icpt_id: row_record.ID,
      },
      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        getIcptDetails();
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    getIcptDetails();
  }, [selectedYear]);

  return (
    <>
      {firstLoad ? (
        <Flex justify="center" align="center" style={{ height: "50vh" }}>
          <Spin />
        </Flex>
      ) : (
        <>
          <Flex>
            <DatePicker
              className="mb-5 mr-3"
              picker="year"
              value={selectedYear}
              onChange={(date) => {
                setSelectedYear(date);
              }}
            />

            <Button
              icon={<PlusOutlined />}
              className="mb-5"
              onClick={() => setIsAddIcptModalOpen(true)}
            >
              New ICPT
            </Button>
          </Flex>

          {loading ? (
            <Flex justify="center" align="center" style={{ height: "50vh" }}>
              <Spin />
            </Flex>
          ) : (
            <DataTable
              table_column={icptTableColumns}
              table_data={icptDetails}
            />
          )}
        </>
      )}

      <Modal
        title="Add ICPT"
        open={isAddIcptModalOpen}
        footer={null}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Divider className="mb-4 mt-0"></Divider>
        <IcptForm
          mode="add"
          setIsIcptFormModalOpen={setIsAddIcptModalOpen}
          refreshTable={getIcptDetails}
          selectedYear={selectedYear}
        />
      </Modal>

      <Modal
        title="Edit ICPT"
        open={isEditIcptModalOpen}
        footer={null}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Divider className="mb-4 mt-0"></Divider>
        <IcptForm
          mode="edit"
          setIsIcptFormModalOpen={setIsEditIcptModalOpen}
          isIcptForm={false}
          refreshTable={getIcptDetails}
          initialValues={selectedIcpt}
          selectedYear={selectedYear}
        />
      </Modal>
    </>
  );
}

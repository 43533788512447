// library imports
import { Segmented, Card, Flex, Tooltip, Skeleton } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useState, useEffect, useContext } from "react";
// style imports
import "../../styles/Dashboard/SummaryCard.css";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";
// component imports
import _SingleContent from "./_SingleContent";
import _SingleContentMd from "./_SingleContentMd";
import _SingleContentMdNoBess from "./_SingleContentMdNoBess";

function SummaryCard({ className }) {
  const [fetchWrapper] = useFetch();
  const { siteSelected, subscribeAiBess, notificationApi } =
    useContext(IndexContext);

  const [loading, setLoading] = useState(false);

  const summaryDateTypeOptions = [
    { label: "Day", value: "day" },
    { label: "Month", value: "month" },
    { label: "Year", value: "year" },
  ];

  const [summaryDateSelection, setSummaryDateSelection] = useState(
    summaryDateTypeOptions[1].value
  );

  const [pvSelfConsumption, setPvSelfConsumption] = useState(0);
  const [tnbGridExport, setTnbGridExport] = useState(0);
  const [tnbGridSupplied, setTnbGridSupplied] = useState(0);
  const [MDReducedByPv, setMDReducedByPv] = useState(0);
  const [MDReducedByBess, setMDReducedByBess] = useState(0);
  const [tnbMD, setTnbMD] = useState(0);
  const [tnbMDDateTime, setTnbMDDateTime] = useState("");

  function getDaySummary() {
    setLoading(true);

    fetchWrapper({
      endpoint_url: "dashboard/getDaySummary",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setPvSelfConsumption(response.data.pv_self_consumption);
        setTnbGridExport(response.data.tnb_grid_export);
        setTnbGridSupplied(response.data.tnb_grid_supplied);
        setTnbMD(response.data.tnb_day_MD);
        setMDReducedByPv(response.data.MD_reduced_by_pv);
        setMDReducedByBess(response.data.MD_reduced_by_bess);
        setTnbMDDateTime(response.data.tnb_day_MD_datetime);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  function getMonthSummary() {
    setLoading(true);

    fetchWrapper({
      endpoint_url: "dashboard/getMonthSummary",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setPvSelfConsumption(response.data.pv_self_consumption);
        setTnbGridExport(response.data.tnb_grid_export);
        setTnbGridSupplied(response.data.tnb_grid_supplied);
        setTnbMD(response.data.tnb_month_MD);
        setMDReducedByPv(response.data.MD_reduced_by_pv);
        setMDReducedByBess(response.data.MD_reduced_by_bess);
        setTnbMDDateTime(response.data.tnb_month_MD_datetime);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  function getYearSummary() {
    setLoading(true);

    fetchWrapper({
      endpoint_url: "dashboard/getYearSummary",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setPvSelfConsumption(response.data.pv_self_consumption);
        setTnbGridExport(response.data.tnb_grid_export);
        setTnbGridSupplied(response.data.tnb_grid_supplied);
        setTnbMD(response.data.tnb_year_MD);
        setMDReducedByPv(response.data.MD_reduced_by_pv);
        setMDReducedByBess(response.data.MD_reduced_by_bess);
        setTnbMDDateTime(response.data.tnb_year_MD_datetime);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteSelected) {
      if (summaryDateSelection === "day") {
        getDaySummary();

        const timer = setInterval(() => {
          getDaySummary();
        }, 30 * 60 * 1000);

        return () => {
          clearInterval(timer);
        };
      } else if (summaryDateSelection === "month") {
        getMonthSummary();

        const timer = setInterval(() => {
          getMonthSummary();
        }, 30 * 60 * 1000);

        return () => {
          clearInterval(timer);
        };
      } else if (summaryDateSelection === "year") {
        getYearSummary();

        const timer = setInterval(() => {
          getYearSummary();
        }, 30 * 60 * 1000);

        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [summaryDateSelection, siteSelected]);

  return (
    <>
      {loading === false ? (
        <Card
          size="small"
          className={className}
          title={
            <Flex justify="space-between">
              <span>Summary</span>
              <span>
                <Tooltip title={"The values are updated every 30 minutes."}>
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            </Flex>
          }
          styles={{
            body: {
              height: loading ? "100%" : "auto",
              display: loading ? "flex" : "block",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
        >
          <Segmented
            className="summary-content-segmented"
            block
            options={summaryDateTypeOptions}
            onChange={(value) => {
              setSummaryDateSelection(value);
            }}
            value={summaryDateSelection}
          />
          <div className="summary-content-container">
            <_SingleContent
              title={"System production (AC)"}
              var1label={"Self-Consumption"}
              var2label={"Grid Export"}
              var1value={pvSelfConsumption}
              var2value={tnbGridExport}
              unit={"kWh"}
              tnbMDDateTime={tnbMDDateTime}
            />
            <_SingleContent
              title={"Load Consumption"}
              var1label={"PV System"}
              var2label={"Grid Supplied"}
              var1value={pvSelfConsumption}
              var2value={tnbGridSupplied}
              unit={"kWh"}
              tnbMDDateTime={tnbMDDateTime}
            />
            {subscribeAiBess ? (
              <_SingleContentMd
                title={"Max Demand"}
                var1label={"Reduced by PV"}
                var2label={"Reduced by BESS"}
                var3label={"Grid Supplied"}
                var1value={MDReducedByPv}
                var2value={MDReducedByBess}
                var3value={tnbMD}
                unit={"kW"}
                tnbMDDateTime={tnbMDDateTime}
              />
            ) : (
              <_SingleContentMdNoBess
                title={"Max Demand"}
                var1label={"Reduced by PV"}
                var2label={"Grid Supplied"}
                var1value={MDReducedByPv}
                var2value={tnbMD}
                unit={"kW"}
                tnbMDDateTime={tnbMDDateTime}
              />
            )}
          </div>
        </Card>
      ) : (
        <Card style={{ width: "100%", height: "50%" }}>
          <Skeleton
            active={true}
            style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
            paragraph={{ rows: 10 }}
          />
        </Card>
      )}
    </>
  );
}

export default SummaryCard;

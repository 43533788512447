// library imports
import { Table, Button } from "antd";
import { useState, useEffect, useContext } from "react";
import {
  LoadingOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
// style imports
import "../../styles/SystemReport/OverallReportContent.css";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";

const { Column, ColumnGroup } = Table;

function OverallReportContent() {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [dateSelection, setDateSelection] = useState(dayjs());
  const [data, setData] = useState([]);

  const [includePvSyst, setIncludePvSyst] = useState(false);

  const formatToTwoDecimalPlaces = (value) => {
    if (value === undefined || value === null || value === "-") {
      return value;
    }
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  /** API to get overall report */
  function getOverallReportData() {
    setLoading(true);
    fetchWrapper({
      endpoint_url: "overall_report/getOverallReportData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setData(response.data.data);
        setIncludePvSyst(response.data.include_pv_syst);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },
      onFinish: () => {
        setLoading(false);
      },
    });
  }

  /** API to download overall report */
  function downloadOverallReport() {
    setSaveLoading(true);

    fetchWrapper({
      endpoint_url: "overall_report/downloadOverallReport",
      method: "POST",
      json_output: false,
      body: {
        table_data: data,
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        if (response.headers.get("content-type") !== "application/json") {
          // no issue with the response. returned a spreadsheet
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `AnnualReport_${dayjs().format(
              "YYYYMMDD_HHmmss"
            )}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          });
        }
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: "Failed to download file",
        });
      },

      onFinish: () => {
        setSaveLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteSelected) {
      getOverallReportData();
    }
  }, [siteSelected, dateSelection]);

  return (
    <>
      <div className="overall-report-date-selection">
        <Button
          className="overall-report-download-button"
          disabled={loading}
          onClick={() => {
            downloadOverallReport();
          }}
        >
          {saveLoading ? <LoadingOutlined /> : <VerticalAlignBottomOutlined />}
        </Button>
      </div>
      {includePvSyst ? (
        <Table bordered dataSource={data} scroll={{ x: 800 }} loading={loading}>
          <Column title="Year" dataIndex="year" key="year" />
          <ColumnGroup title="Energy Generation">
            <Column
              title="Actual"
              dataIndex="actualEnergyGeneration (kWh)"
              key="actualEnergyGeneration"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PVSyst (Earray)"
              dataIndex="pvSystEnergyGeneration (kWh)"
              key="pvSystEnergyGeneration"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Deviation"
              dataIndex="deviationEnergyGeneration (kWh)"
              key="deviationEnergyGeneration"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
          <ColumnGroup title="Energy Utilization">
            <Column
              title="PV Self Consumption (kWh)"
              dataIndex="pvSelfConsumption"
              key="pvSelfConsumption"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Self Consumption Ratio"
              dataIndex="selfConsumptionRatio"
              key="selfConsumptionRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PV Export (kWh)"
              dataIndex="pvExport"
              key="pvExport"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PV Energy Export Ratio"
              dataIndex="pvEnergyExportRatio"
              key="pvEnergyExportRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Import From Grid (kWh)"
              dataIndex="importFromGrid"
              key="importFromGrid"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
          <ColumnGroup title="Solar Irradiation (kWh/m²)">
            <Column
              title="Actual"
              dataIndex="actualSolarIrradiation"
              key="actualSolarIrradiation"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PVSyst GlobHor"
              dataIndex="pvSystGlobHor"
              key="pvSystGlobHor"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Deviation (%)"
              dataIndex="deviationSolarIrradiation"
              key="deviationSolarIrradiation"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
          <ColumnGroup title="Performance Ratio">
            <Column
              title="Actual"
              dataIndex="actualPerformanceRatio"
              key="actualPerformanceRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PVSyst GlobHor"
              dataIndex="pvSystGlobHorPerformanceRatio"
              key="pvSystGlobHorPerformanceRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Deviation"
              dataIndex="deviationPerformanceRatio"
              key="deviationPerformanceRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
          <ColumnGroup title="Specific Yield (kWh/kWp)">
            <Column
              title="Actual"
              dataIndex="actualSpecificYield"
              key="actualSpecificYield"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PVSyst"
              dataIndex="pvSystSpecificYield"
              key="pvSystSpecificYield"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Deviation (%)"
              dataIndex="deviationSpecificYield"
              key="deviationSpecificYield"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
        </Table>
      ) : (
        <Table bordered dataSource={data} scroll={{ x: 800 }} loading={loading}>
          <Column title="Year" dataIndex="year" key="year" />
          <Column
            title="Energy Generation (kWh)"
            dataIndex="actualEnergyGeneration"
            key="actualEnergyGeneration"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />

          <ColumnGroup title="Energy Utilization (kWh)">
            <Column
              title="PV Self Consumption (kWh)"
              dataIndex="pvSelfConsumption"
              key="pvSelfConsumption"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Self Consumption Ratio"
              dataIndex="selfConsumptionRatio"
              key="selfConsumptionRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PV Export (kWh)"
              dataIndex="pvExport"
              key="pvExport"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PV Energy Export Ratio"
              dataIndex="pvEnergyExportRatio"
              key="pvEnergyExportRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Import From Grid (kWh)"
              dataIndex="importFromGrid"
              key="importFromGrid"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
          <Column
            title="Solar Irradiation (kWh/m²)"
            dataIndex="actualSolarIrradiation"
            key="actualSolarIrradiation"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />

          <Column
            title="Performance Ratio"
            dataIndex="actualPerformanceRatio"
            key="actualPerformanceRatio"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />

          <Column
            title="Specific Yield (kWh/kWp)"
            dataIndex="actualSpecificYield"
            key="actualSpecificYield"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
        </Table>
      )}
    </>
  );
}

export default OverallReportContent;

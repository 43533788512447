// library imports
import { useState, useEffect, useContext } from "react";
import { Heatmap } from "@ant-design/plots";
import { Card, Empty, Skeleton } from "antd";
// style imports
import "../../styles/Dashboard/HeatmapCard.css";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";

export default function HeatmapCard({ heightRef }) {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  const [loading, setLoading] = useState(false);

  const [heatmapData, setHeatmapData] = useState([]);

  function getPowerHeatmapData() {
    setLoading(true);

    fetchWrapper({
      endpoint_url: "dashboard/getHeatmapData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setHeatmapData(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteSelected) {
      getPowerHeatmapData();
    }
  }, [siteSelected]);

  const config = {
    autoFit: true,
    data: heatmapData,
    xField: "Date",
    yField: "Device",
    colorField: "Performance Ratio",
    color: ["#ccece6", "#99d8c9", "#66c2a4", "#2ca25f", "#004a1e"], //['#d9d9d9', '#bdbdbd', '#969696', '#737373'],
    legend: {
      layout: "horizontal",
      position: "bottom",
      title: {
        spacing: 5,
      },
      slidable: false,
    },
    meta: {
      Date: {
        type: "cat", // specify that a particular field should be treated as a categorical (or discrete) variable
      },
      "Performance Ratio": {
        formatter: (value) =>
          value.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }), // format Performance Ratio to 2 decimal places
      },
    },
    style: {
      height: "100%",
    },
  };

  return (
    <>
      {loading === false ? (
        <Card
          size="small"
          className="heatmap-card"
          style={{
            height: heightRef.current?.clientHeight,
          }}
          title="Power Heatmap"
        >
          <div className="heatmap-content">
            {heatmapData.length > 0 ? (
              <Heatmap {...config} />
            ) : (
              <Empty
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              />
            )}
          </div>
        </Card>
      ) : (
        <Card
          size="small"
          className="heatmap-card"
          style={{
            height: heightRef.current?.clientHeight,
          }}
        >
          <div className="heatmap-content">
            <Skeleton
              active={true}
              style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
              paragraph={{ rows: 10 }}
            />
          </div>
        </Card>
      )}
    </>
  );
}

// library imports
import { Button, Form, Flex, DatePicker, Select, InputNumber } from "antd";
import { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
// config imports
import { FORM_REQUIRED } from "../../../configs/configs";
// hook imports
import useFetch from "../../../hooks/useFetch";
// context imports
import { IndexContext } from "../../../context/IndexContext";

function IcptForm(props) {
  const {
    mode,
    setIsIcptFormModalOpen,
    refreshTable,
    initialValues,
    selectedYear,
  } = props;

  const [fetchWrapper] = useFetch();

  // loading states
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);
  const [loadingTariffCategoryList, setLoadingTariffCategoryList] =
    useState(false);

  // form
  const [icptForm] = Form.useForm();

  // load index context
  const { notificationApi } = useContext(IndexContext);

  // selection for tariff category
  const [tariffCategorySelected, setTariffCategorySelected] = useState(null);
  const [tariffCategoryList, setTariffCategoryList] = useState([]);

  /* API to get tariff category list */
  function getTariffCategoryList() {
    setLoadingTariffCategoryList(true);
    fetchWrapper({
      method: "GET",
      endpoint_url: "site_management/getTariffTypeList",
      set_content_type_header: false,
      onSuccess: (response) => {
        setLoadingTariffCategoryList(false);
        setTariffCategoryList(response.data);
      },
    });
  }

  /* API to add icpt */
  function addIcpt(user_inputs) {
    setLoadingSubmitButton(true);

    fetchWrapper({
      endpoint_url: "icpt_management/addIcpt",
      body: {
        tariff_category_id: user_inputs.TariffCategoryID,
        start_date: user_inputs.StartDate.startOf("month").format("YYYY-MM-DD"),
        end_date: user_inputs.EndDate.endOf("month").format("YYYY-MM-DD"),
        icpt_value: user_inputs.Value,
      },

      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setIsIcptFormModalOpen(false);
        icptForm.resetFields();
        refreshTable();
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoadingSubmitButton(false);
      },
    });
  }

  /* API to edit icpt  */
  function editIcpt(user_inputs) {
    setLoadingSubmitButton(true);

    fetchWrapper({
      endpoint_url: "icpt_management/editIcpt",
      body: {
        tariff_category_id: user_inputs.TariffCategoryID,
        icpt_id: initialValues.ID,
        start_date: user_inputs.StartDate.startOf("month").format("YYYY-MM-DD"),
        end_date: user_inputs.EndDate.endOf("month").format("YYYY-MM-DD"),
        icpt_value: user_inputs.Value,
      },

      onSuccess: (response) => {
        notificationApi.success({
          message: response.message,
        });
        setIsIcptFormModalOpen(false);
        icptForm.resetFields();
        refreshTable();
      },

      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoadingSubmitButton(false);
      },
    });
  }

  useEffect(() => {
    if (mode === "edit") {
      if (initialValues) {
        initialValues.StartDate = dayjs(initialValues.StartDate);
        initialValues.EndDate = dayjs(initialValues.EndDate);
        icptForm.setFieldsValue(initialValues);
      }
    }
  }, [mode, initialValues]);

  useEffect(() => {
    getTariffCategoryList();
  }, []);

  return (
    <>
      <Form
        form={icptForm}
        name="icpt-form"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={(values) => {
          if (mode === "add") {
            addIcpt(values);
          } else {
            editIcpt(values);
          }
        }}
        autoComplete="off"
      >
        <Form.Item
          label="Tariff Category"
          name="TariffCategoryID"
          rules={[
            {
              required: FORM_REQUIRED,
              message: "Tariff category cannot be empty.",
            },
          ]}
        >
          <Select
            placeholder="Select Tariff Category"
            value={tariffCategorySelected}
            options={tariffCategoryList}
            onChange={(value) => {
              setTariffCategorySelected(value);
            }}
            loading={loadingTariffCategoryList}
          />
        </Form.Item>

        <Form.Item
          label="Start Month"
          name="StartDate"
          rules={[
            {
              required: FORM_REQUIRED,
              message: "Start month cannot be empty.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue("EndDate") <= value) {
                  return Promise.reject(
                    new Error("Start month must be earlier than end month.")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            picker="month"
            minDate={dayjs(selectedYear.startOf("year"), "YYYY-MM-DD")}
            maxDate={dayjs(selectedYear.endOf("year"), "YYYY-MM-DD")}
          />
        </Form.Item>

        <Form.Item
          label="End Month"
          name="EndDate"
          rules={[
            {
              required: FORM_REQUIRED,
              message: "End month cannot be empty.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue("StartDate") >= value) {
                  return Promise.reject(
                    new Error("End month must be later than start month.")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            picker="month"
            minDate={dayjs(selectedYear.startOf("year"), "YYYY-MM-DD")}
            maxDate={dayjs(selectedYear.endOf("year"), "YYYY-MM-DD")}
          />
        </Form.Item>

        <Form.Item
          label="ICPT Value"
          name="Value"
          rules={[
            {
              required: FORM_REQUIRED,
              message: "ICPT Value cannot be empty.",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Input ICPT Value"
          />
        </Form.Item>

        <Flex justify="center">
          <Button
            className="mr-2"
            onClick={() => {
              setIsIcptFormModalOpen(false);
              icptForm.resetFields();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingSubmitButton}
          >
            Submit
          </Button>
        </Flex>
      </Form>
    </>
  );
}

export default IcptForm;

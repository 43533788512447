import { Table } from "antd";
import { useState, useLayoutEffect } from "react";

/**
 * A common table that cater for most needs.
 * Auto resizable, loading indicator, selectable pagination
 *
 * @export
 * @param {{ table_column: ColumnType[]; table_data: object[]; tableWrapperViewportHeight?: number; layoutRef: React.Ref; filterRef: React.Ref; }} param0
 * @param {*} param0.table_column
 * @param {*} param0.table_data
 * @param {number} [param0.tableWrapperViewportHeight=1]
 * @param {*} param0.layoutRef
 * @param {*} param0.filterRef
 * @returns
 */
export default function DataTable({
  table_column,
  table_data,
  tableWrapperViewportHeight = 1,
  layoutRef,
  filterRef,
}) {
  // for resizeable table
  const [tableHeight, setTableHeight] = useState(660);

  function resizeTable() {
    if (!layoutRef || !filterRef) {
      return;
    }
    const layout_node = layoutRef.current;
    const filter_node = filterRef.current;
    if (!filter_node || !layout_node) {
      return;
    }
    const layout_height = layout_node.scrollHeight;
    const height = filter_node.scrollHeight;

    setTableHeight(layout_height * tableWrapperViewportHeight - height - 258);
  }

  useLayoutEffect(() => {
    resizeTable();
    window.addEventListener("resize", resizeTable);
    // observe table resize
    let filter_resize_observer = null;
    if (!filterRef || !filterRef.current){
      return;
    }
    else if (filterRef.current) {
      filter_resize_observer = new ResizeObserver(resizeTable);
      filter_resize_observer.observe(filterRef.current);
    }

    return () => {
      window.removeEventListener("resize", resizeTable);
      //dispose observer
      if (filterRef.current && filter_resize_observer) {
        filter_resize_observer.unobserve(filterRef.current);
      }
    };
  }, [filterRef]);

  return (
    <Table
      sticky="true"
      //size="small"
      showHeader
      scroll={{ scrollToFirstRowOnChange: true, x: true, y: tableHeight }}
      tableLayout="fixed"
      loading={table_data && table_column ? false : true}
      pagination={{
        responsive: true,
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: [3, 5, 10, 20, 50, 100],
        //position: ["topRight", "bottomRight"],
        position: "bottomRight",
        //size: "small",
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
      columns={table_column}
      dataSource={table_data}
    ></Table>
  );
}

// library imports
import { Button, Col, Flex, Row, Skeleton, Divider, Select } from "antd";
// component import
import TNBTable from "./TNBTable";
import DeviceCard from "../common/DeviceCard";
import { LabelValueUnitDisplayRow } from "../../common/WordDisplay";

export default function TNBContent({
  deviceList,
  utilityCard,
  handleChange,
  setOpenModal,
  handleDeviceChange,
  utilitySystemLoading,
  utilityDeviceLoading,
  deviceSelectionLoading,
  selectedDeviceID,
  tableData,
  systemData,
  utilityCardData,
  systemLastUpdate,
  deviceLastUpdate,
}) {
  return (
    <div className="system-col">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          align: "center",
          marginBottom: "12px",
        }}
      >
        <h2 style={{ margin: "0px" }}>TNB System</h2>
        <span>Last Update: {systemLastUpdate}</span>
      </div>
      {utilitySystemLoading ? (
        <Skeleton active style={{ margin: "5px" }} />
      ) : (
        <div className="system-row">
          <div className="content-box">
            <LabelValueUnitDisplayRow
              label={"Real Power"}
              value={
                systemData?.realPower !== undefined ? systemData.realPower : "-"
              }
              unit={"kW"}
            />
            <LabelValueUnitDisplayRow
              label={"Reactive Power"}
              value={
                systemData?.reactivePower !== undefined
                  ? systemData.reactivePower
                  : "-"
              }
              unit={"kVAR"}
            />
            <LabelValueUnitDisplayRow
              label={"Apparent Power"}
              value={
                systemData?.apparentPower !== undefined
                  ? systemData.apparentPower
                  : "-"
              }
              unit={"kVA"}
            />
            <LabelValueUnitDisplayRow
              label={"Frequency value"}
              value={
                systemData?.frequency !== undefined ? systemData.frequency : "-"
              }
              unit={"Hz"}
            />
            <LabelValueUnitDisplayRow
              label={"Load Factor"}
              value={
                systemData?.loadFactor !== undefined
                  ? systemData.loadFactor
                  : "-"
              }
              unit={""}
            />
            <LabelValueUnitDisplayRow
              label={"Power Factor"}
              value={
                systemData?.powerFactor !== undefined
                  ? systemData.powerFactor
                  : "-"
              }
              unit={""}
            />
          </div>
          <div className="content-box">
            <LabelValueUnitDisplayRow
              label={"Energy Consumption (Peak) - Month"}
              value={
                systemData?.onPeakImport !== undefined
                  ? systemData.onPeakImport
                  : "-"
              }
              unit={"kWh"}
            />
            <LabelValueUnitDisplayRow
              label={"Energy Consumption (Off-Peak) - Month"}
              value={
                systemData?.offPeakImport !== undefined
                  ? systemData.offPeakImport
                  : "-"
              }
              unit={"kWh"}
            />
            <LabelValueUnitDisplayRow
              label={"Energy Consumption - Month"}
              value={systemData?.import !== undefined ? systemData.import : "-"}
              unit={"kWh"}
            />
            <LabelValueUnitDisplayRow
              label={"Energy Export - Month"}
              value={systemData?.export !== undefined ? systemData.export : "-"}
              unit={"kWh"}
            />
          </div>
          <div className="content-box">
            <LabelValueUnitDisplayRow
              label={"Reactive Energy Import - Month"}
              value={
                systemData?.reactiveImport !== undefined
                  ? systemData.reactiveImport
                  : "-"
              }
              unit={"kVARh"}
            />
            <LabelValueUnitDisplayRow
              label={"Maximum Demand - Month"}
              value={
                systemData?.maximumDemand !== undefined
                  ? systemData.maximumDemand
                  : "-"
              }
              unit={"kW"}
            />
          </div>
          <div className="content-box">{/* empty space */}</div>
        </div>
      )}

      <div className="content-box">
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            align: "center",
            marginBottom: "12px",
          }}
        >
          <h2 style={{ margin: "0px" }}>TNB Device Main Parameters</h2>
          <span>Last Update: {deviceLastUpdate}</span>
        </div>

        <Flex justify="space-between" align="center">
          {deviceList.length > 0 && selectedDeviceID ? (
            <>
              <Select
                loading={deviceSelectionLoading}
                value={selectedDeviceID}
                options={deviceList}
                onChange={(value) => handleDeviceChange(value)}
                style={{ width: "100%", marginRight: "8px" }}
              />
              <Flex gap={"8px"}>
                <Button
                  disabled={
                    utilityCardData.length === 0 || utilityDeviceLoading
                  }
                  onClick={() => setOpenModal(true)}
                >
                  View Device Details
                </Button>
                <Button onClick={handleChange}>Change View</Button>
              </Flex>
            </>
          ) : (
            <></>
          )}
        </Flex>

        {utilityDeviceLoading ? (
          <Skeleton active style={{ margin: "5px" }} />
        ) : (
          <div>
            <div className="system-row power-meter-phases">
              {utilityCard == true ? (
                <Col span={24}>
                  <Row gutter={[18, 18]}>
                    {tableData?.map((d, index) => {
                      const key = `col-${index}`;
                      return (
                        <Col
                          key={key}
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                          xl={{ span: 8 }}
                          xxl={{ span: 6 }}
                        >
                          <DeviceCard data={d} setOpenModal={setOpenModal} />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              ) : (
                <TNBTable data={tableData} />
              )}
              <div className="content-box">{/* empty space */}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

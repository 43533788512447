// custom node example: https://g6.antv.antgroup.com/en/examples/item/customNode#jsxNode
// line animation: https://g6.antv.antgroup.com/en/examples/scatter/edge#edge
// custom node documentation: https://antv-g6.gitee.io/en/docs/manual/middle/elements/nodes/jsx-node
// library imports
import G6 from "@antv/g6";
import { useEffect, useState, useContext, useRef } from "react";
import { Card, Skeleton, theme } from "antd";
import * as _ from "@antv/util";
// image imports
import image_consumption from "../../assets/Load.png";
import image_grid from "../../assets/Grid.png";
import image_pv from "../../assets/PV.png";
import image_bess from "../../assets/BESS.png";
// style imports
import "../../styles/Dashboard/SystemInformationCard.css";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";
// config imports
import { MQTT_REFRESH_RATE } from "../../configs/configs";

const FONT_SIZE = 13;

/**
 * prereq: state object has variables:
 *  - pv_value
 *  - load_value
 *  - grid_value
 *  - bess_value (if subscribe AI-BESS)
 */
export default function SystemInformationCard({ className, containerID }) {
  // theme token
  const { token } = theme.useToken();

  // fetch wrapper
  const [fetchWrapper] = useFetch();

  // index context
  const { siteSelected, subscribeAiBess, notificationApi } =
    useContext(IndexContext);

  // loading state
  const [loading, setLoading] = useState(true);

  // graph related variables
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [graph, setGraph] = useState(null);
  const [systemInformationData, setSystemInformationData] = useState({
    pv_value: 0,
    load_value: 0,
    grid_value: 0,
    bess_value: 0,
  });

  // register node
  G6.registerNode(
    "label",
    (cfg) => `
      <group>
        <text style={{ fill: ${token.colorTextBase}, fontSize: ${FONT_SIZE}, fontWeight: 600}}>{{title1}}</text>
        <text style={{ fill: ${token.colorTextBase}, fontSize: ${FONT_SIZE}, fontWeight: 600, marginTop: -3}}>{{title2}}</text>
        <text style={{ fill: ${token.colorTextBase}, fontSize: ${FONT_SIZE}, fontWeight: 600, marginTop: 0}}>{{value}} {{unit}}</text>
      </group>
      `
  );

  // register edge
  G6.registerEdge(
    "polyline-dash",
    {
      afterDraw(cfg, group) {
        const shape = group.get("children")[0];
        let index = cfg.speed;
        shape.animate(
          () => {
            index += cfg.speed;
            if (index > 1000) {
              index = 0;
            }

            const res = {
              lineDash: [8, 2, 2, 4],
              lineDashOffset: -index,
            };
            return res;
          },
          {
            repeat: true,
            duration: 1000,
          }
        );
      },
    },
    "line" // extend the built-in edge
  );

  /** Function to render graph */
  function renderGraph(graphData) {
    const container = document.getElementById(containerID);
    cleanCharting(containerID);
    const width = container.scrollWidth || 500;
    const height = container.scrollHeight || 500;

    const temp = new G6.Graph({
      container: containerID,
      width,
      height,
      linkCenter: true,
      fitCenter: false,
      defaultNode: {
        type: "image",
        size: [70, 70],
      },
      defaultEdge: {
        type: "polyline-dash",
        style: { lineWidth: 4 },
      },
    });
    temp.data(graphData);
    temp.render();

    setGraph(temp);
  }

  /** Function to clean graph */
  function cleanCharting(container) {
    document.getElementById(container).innerHTML = "";
  }

  /** API to get system info card data */
  function getSystemInfo() {
    fetchWrapper({
      endpoint_url: "dashboard/getSystemInfo",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setSystemInformationData(response.data);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    // UPDATE NODE
    const graphData = {
      nodes: subscribeAiBess
        ? [
            {
              id: "pvimage",
              img: image_pv,
              x: 155,
              y: 30,
            },
            {
              x: 195,
              y: 15,
              title1: "PV",
              title2:
                systemInformationData.pv_value === "-"
                  ? "DPM Offline"
                  : systemInformationData.pv_value > 0
                  ? "Producing"
                  : systemInformationData.pv_value < 0
                  ? "Consuming"
                  : "Idle",

              value:
                systemInformationData.pv_value !== "-"
                  ? `${systemInformationData.pv_value.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : "-",
              unit: systemInformationData.pv_value !== "-" ? "kW" : "",
              id: "pvnode",
              type: "label",
            },
            {
              id: "bessimage",
              img: image_bess,
              x: 280,
              y: 98,
            },
            {
              x: 320,
              y: 83,
              title1: "BESS",
              title2:
                systemInformationData.bess_value == 0
                  ? "Idle"
                  : systemInformationData.bess_value === "-"
                  ? "Offline"
                  : systemInformationData.bess_value < 0
                  ? "Charging"
                  : "Discharging",
              image: image_bess,

              value:
                systemInformationData.bess_value !== "-"
                  ? `${systemInformationData.bess_value.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : "-",
              unit: systemInformationData.bess_value !== "-" ? "kW" : "",
              id: "bessnode",

              type: "label",
            },
            {
              id: "loadimage",
              img: image_consumption,
              x: 155,
              y: 140,
            },
            {
              x: 195,
              y: 125,
              title1: "Load",
              title2:
                systemInformationData.load_value === "-"
                  ? "DPM Offline"
                  : systemInformationData.load_value === 0
                  ? "Idle"
                  : "Consuming",
              image: image_consumption,

              value:
                systemInformationData.load_value !== "-"
                  ? `${systemInformationData.load_value.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : "-",
              unit: systemInformationData.load_value !== "-" ? "kW" : "",
              id: "loadnode",
              type: "label",
            },
            {
              id: "gridimage",
              img: image_grid,
              x: 38,
              y: 98,
            },
            {
              x: 70,
              y: 50,
              title1: "Grid",
              title2:
                systemInformationData.grid_value == "-"
                  ? "DPM Offline"
                  : systemInformationData.grid_value > 0
                  ? "Importing"
                  : systemInformationData.grid_value < 0
                  ? "Exporting"
                  : "Idle",
              labelRight: false,
              image: image_grid,

              value:
                systemInformationData.grid_value !== "-"
                  ? `${systemInformationData.grid_value.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : "-",
              unit: systemInformationData.grid_value !== "-" ? "kW" : "",
              id: "gridnode",
              type: "label",
            },
          ]
        : [
            {
              id: "pvimage",
              img: image_pv,
              x: 160,
              y: 30,
            },
            {
              x: 200,
              y: 10,
              title1: "PV",
              title2:
                systemInformationData.pv_value === "-"
                  ? "DPM Offline"
                  : systemInformationData.pv_value > 0
                  ? "Producing"
                  : "Consuming",

              value:
                systemInformationData.pv_value !== "-"
                  ? `${systemInformationData.pv_value.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : "-",
              unit: systemInformationData.pv_value !== "-" ? "kW" : "",
              id: "pvnode",
              type: "label",
            },
            {
              id: "loadimage",
              img: image_consumption,
              x: 90,
              y: 80,
            },
            {
              x: 10,
              y: 100,
              title1: "Load",
              title2:
                systemInformationData.load_value === "-"
                  ? "DPM Offline"
                  : "Consuming",
              image: image_consumption,

              value:
                systemInformationData.load_value !== "-"
                  ? `${systemInformationData.load_value.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : "-",
              unit: systemInformationData.load_value !== "-" ? "kW" : "",
              id: "loadnode",
              type: "label",
            },
            {
              id: "gridimage",
              img: image_grid,
              x: 170,
              y: 130,
            },
            {
              x: 210,
              y: 110,
              title1: "Grid",
              title2:
                systemInformationData.grid_value == "-"
                  ? "DPM Offline"
                  : systemInformationData.grid_value > 0
                  ? "Importing"
                  : "Exporting",
              labelRight: false,
              image: image_grid,
              value:
                systemInformationData.grid_value !== "-"
                  ? `${systemInformationData.grid_value.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : "-",
              unit: systemInformationData.grid_value !== "-" ? "kW" : "",
              id: "gridnode",
              type: "label",
            },
          ],
      edges: [],
    };

    // BUILD EDGES
    // Load consuming = PV + Grid (+ BESS)
    if (systemInformationData.pv_value > 0) {
      if (systemInformationData.load_value > 0) {
        // consuming from PV
        graphData.edges.push({
          source: "pvimage",
          target: "loadimage",
          speed: 1,
          color: "#696969",
        });
      }
      if (systemInformationData.grid_value < 0) {
        // exporting PV to Grid
        graphData.edges.push({
          source: "pvimage",
          target: "gridimage",
          speed: 0.4,
          color: "#696969",
        });
      }
    }

    if (systemInformationData.bess_value > 0) {
      if (systemInformationData.load_value > 0) {
        // BESS discharging
        graphData.edges.push({
          source: "bessimage",
          target: "loadimage",
          speed: 1,
          color: "#696969",
        });
      }
    } else if (systemInformationData.bess_value < 0) {
      // BESS charging from PV
      if (
        systemInformationData.grid_value <
        Math.abs(systemInformationData.bess_value)
      ) {
        graphData.edges.push({
          source: "pvimage",
          target: "bessimage",
          speed: 1,
          color: "#696969",
        });
      }
      // BESS charging from TNB
      else {
        graphData.edges.push({
          source: "gridimage",
          target: "bessimage",
          speed: 1,
          color: "#696969",
        });
      }
    }

    if (systemInformationData.grid_value > 0) {
      // consuming from Grid
      graphData.edges.push({
        source: "gridimage",
        target: "loadimage",
        speed: 0.4,
        color: "#666666",
      });
    }

    // RENDER GRAPH
    if (isFirstRender) {
      setIsFirstRender(false);
      if (subscribeAiBess !== null && !loading) {
        renderGraph(graphData);
      }
    } else {
      if (subscribeAiBess !== null && !loading) {
        if (graph) {
          graph.changeData(graphData);
        }
        renderGraph(graphData);
      }
    }
  }, [token, systemInformationData, subscribeAiBess, loading]);

  useEffect(() => {
    if (siteSelected) {
      getSystemInfo();

      const timer = setInterval(() => {
        getSystemInfo();
      }, MQTT_REFRESH_RATE * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelected]);

  return (
    <>
      <Card size="small" title={"System Information"} className={className}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {loading || subscribeAiBess === null ? (
            <Skeleton
              active={true}
              style={{ padding: "1rem", margin: "auto" }}
            />
          ) : (
            <div
              id={containerID}
              style={{
                width: "400px",
                height: "180px",
                display: loading ? "none" : "flex",
              }}
            />
          )}
        </div>
      </Card>
    </>
  );
}

// library imports
import { Card, Skeleton, Row, Col } from "antd";
import { useEffect, useState, useContext } from "react";
// component import
import _StatisticGraphContent from "./_StatisticGraphContent";
// hook imports
import useFetch from "../../hooks/useFetch";
// context imports
import { IndexContext } from "../../context/IndexContext";
// style imports
import "../../styles/Dashboard/StatisticGraph.css";

export default function StatisticGraph() {
  const [fetchWrapper] = useFetch();
  const { siteSelected, notificationApi } = useContext(IndexContext);

  // Loading
  const [statisticDataLoading, setStatisticDataLoading] = useState(true);
  const [statisticGraphDataLoading, setStatisticGraphDataLoading] =
    useState(true);

  // Pv Yield
  const [pvYieldGraphData, setPvYieldGraphData] = useState([]);
  const [pvYield, setPvYield] = useState(0);

  // Load Consumption
  const [loadConsumptionGraphData, setLoadConsumptionGraphData] = useState([]);
  const [loadConsumption, setLoadConsumption] = useState(0);

  // Revenue
  const [revenueGraphData, setRevenueGraphData] = useState([]);
  const [revenue, setRevenue] = useState(0);

  // CO2 Emission
  const [co2EmissionGraphData, setCo2EmissionGraphData] = useState([]);
  const [co2Emission, setCo2Emission] = useState(0);

  function getStatisticData() {
    fetchWrapper({
      endpoint_url: "dashboard/getMonthSummary",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setPvYield(response.data.pv_system_production);
        setLoadConsumption(response.data.load_consumption);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setStatisticDataLoading(false);
      },
    });
  }

  function getStatisticGraphData() {
    fetchWrapper({
      endpoint_url: "dashboard/getStatisticsGraphData",
      method: "GET",
      set_content_type_header: false,
      params: {
        site_id: siteSelected,
      },

      onSuccess: (response) => {
        setPvYieldGraphData(response.data.pv_kWh_I);
        setLoadConsumptionGraphData(response.data.load_consumption_graph_data);
        setRevenueGraphData(response.data.daily_revenue_list);
        setRevenue(response.data.sum_revenue);
        setCo2EmissionGraphData(response.data.co2_emission_list);
      },
      onResponseError: (response) => {
        notificationApi.error({
          message: response.message,
        });
      },

      onFinish: () => {
        setStatisticGraphDataLoading(false);
      },
    });
  }

  useEffect(() => {
    if (siteSelected) {
      getStatisticData();
      getStatisticGraphData();

      const timer = setInterval(() => {
        getStatisticData();
        getStatisticGraphData();
      }, 30 * 60 * 1000); // refresh every 30 mins

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelected]);

  return (
    <>
      {statisticDataLoading === false && statisticGraphDataLoading === false ? (
        <Row gutter={[4, 4]}>
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Card
              size="small"
              className="statistic-graph"
              loading={statisticDataLoading || statisticGraphDataLoading}
            >
              <_StatisticGraphContent
                data={pvYieldGraphData}
                value={
                  pvYield === "-"
                    ? `-`
                    : `${pvYield.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} kWh`
                }
                title={"PV Yield"}
                fillColor={"#03C19C"}
                fillColorGradient={"#9ce29c"}
              />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Card
              size="small"
              className="statistic-graph"
              loading={statisticDataLoading || statisticGraphDataLoading}
            >
              <_StatisticGraphContent
                data={loadConsumptionGraphData}
                value={
                  loadConsumption === "-"
                    ? `-`
                    : `${loadConsumption.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} kWh`
                }
                title={"Consumption"}
                fillColor={"#000038"}
                fillColorGradient={"rgba(184,186,200,1)"}
              />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Card
              size="small"
              className="statistic-graph"
              loading={statisticDataLoading || statisticGraphDataLoading}
            >
              <_StatisticGraphContent
                data={revenueGraphData}
                value={
                  pvYield === "-"
                    ? "-"
                    : revenue === "-"
                    ? `-`
                    : `RM ${revenue.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                }
                title={"PV Revenue"}
                fillColor={"rgba(184,186,200,1)"}
                fillColorGradient={"#dadada"}
              />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Card
              size="small"
              className="statistic-graph"
              loading={statisticDataLoading || statisticGraphDataLoading}
            >
              <_StatisticGraphContent
                data={co2EmissionGraphData}
                value={
                  pvYield === "-"
                    ? `-`
                    : `${((pvYield * 0.758) / 1000).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} Tons`
                }
                title={"Total CO\u2082 Avoidance"} //\u2082 is the unicode for subscript 2
                fillColor={"#03C19C"}
                fillColorGradient={"#9ce29c"}
              />
            </Card>
          </Col>
        </Row>
      ) : (
        <Row gutter={[4, 4]}>
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Card size="small" className="statistic-graph">
              <Skeleton
                active={true}
                style={{ padding: "0.5rem", maxWidth: "70%", margin: "auto" }}
                paragraph={{ rows: 1 }}
              />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Card size="small" className="statistic-graph">
              <Skeleton
                active={true}
                style={{ padding: "0.5rem", maxWidth: "70%", margin: "auto" }}
                paragraph={{ rows: 1 }}
              />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Card size="small" className="statistic-graph">
              <Skeleton
                active={true}
                style={{ padding: "0.5rem", maxWidth: "70%", margin: "auto" }}
                paragraph={{ rows: 1 }}
              />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Card size="small" className="statistic-graph">
              <Skeleton
                active={true}
                style={{ padding: "0.5rem", maxWidth: "70%", margin: "auto" }}
                paragraph={{ rows: 1 }}
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

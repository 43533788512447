import { Checkbox, Col, Empty, Row, Flex } from "antd";

export default function DataReferenceSourceCheckbox({
  data,
  checkedValues,
  onChange,
}) {
  const handleChange = (values) => {
    onChange(values);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        padding: "5px 10px",
      }}
    >
      <Checkbox.Group
        style={{ width: "100%" }}
        value={checkedValues}
        onChange={handleChange}
      >
        <Row>
          {data.length > 0 ? (
            data.map((item) => {
              return (
                <Col key={item.ID} span={24} style={{ padding: "5px 0px" }}>
                  <Checkbox value={`${item.ID}:${item.DeviceTypeID}`}>
                    {item.Name}
                  </Checkbox>
                </Col>
              );
            })
          ) : (
            <Flex justify="center" align="center" style={{ width: "100%" }}>
              <Empty
                style={{
                  paddingTop: "50px",
                }}
              />
            </Flex>
          )}
        </Row>
      </Checkbox.Group>
    </div>
  );
}

import { Flex } from "antd";
import {
  AppstoreOutlined,
  DesktopOutlined,
  PieChartOutlined,
  DashboardOutlined,
  LineChartOutlined,
  AlertOutlined,
  ReconciliationOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

const header_height = 58;

export default function useNavMenu(props) {
  const {
    authenticationState,
    subscribePpaBilling,
    subscribeCarbonManagement,
    subscribeAiPvDiagnosis,
    subscribeAiBess,
  } = props;

  const NavMenu = [
    authenticationState.user_type < 3 // only superuser and admin will be able to see site list
      ? {
          label: (
            <Flex
              align="center"
              justify="start"
              style={{ paddingInline: 4, height: header_height }}
            >
              <UnorderedListOutlined style={{ marginRight: 8 }} />
              Site List
            </Flex>
          ),
          key: "SiteList",
        }
      : null,
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 4, height: header_height }}
        >
          <DashboardOutlined style={{ marginRight: 8 }} />
          Dashboard
        </Flex>
      ),
      key: "Dashboard",
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 4, height: header_height }}
        >
          <LineChartOutlined style={{ marginRight: 8 }} />
          Analytics
        </Flex>
      ),
      key: "Analytics",
      children: [
        {
          label: "Power Demand",
          key: "PowerDemand",
        },
        {
          label: "Energy Demand",
          key: "EnergyDemand",
        },
        {
          label: "Parameter",
          key: "Parameter",
        },
      ],
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 4, height: header_height }}
        >
          <DesktopOutlined style={{ marginRight: 8 }} />
          System
        </Flex>
      ),
      key: "System",
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 4, height: header_height }}
        >
          <AlertOutlined style={{ marginRight: 8 }} />
          Alarm
        </Flex>
      ),
      key: "Alarm",
      children: [
        {
          label: "Alarm Record",
          key: "AlarmRecord",
        },
        subscribeAiPvDiagnosis
          ? {
              label: "PV Condition",
              key: "PVCondition",
            }
          : null,
      ],
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 4, height: header_height }}
        >
          <ReconciliationOutlined style={{ marginRight: 8 }} />
          Reports
        </Flex>
      ),
      key: "Reports",
      children: [
        {
          label: "System Report",
          key: "SystemReport",
        },
        {
          label: "ST Report",
          key: "STReport",
        },
        subscribeAiBess
          ? {
              label: "Saving Report",
              key: "SavingReport",
            }
          : null,
      ],
    },
    authenticationState.user_type < 3 // only superuser and admin will be able to see site list
      ? {
          label: (
            <Flex
              align="center"
              justify="start"
              style={{ paddingInline: 4, height: header_height }}
            >
              <PieChartOutlined style={{ marginRight: 8 }} />
              Management
            </Flex>
          ),
          key: "Management",
          children: [
            {
              label: "Maintenance",
              key: "Maintenance",
            },
            {
              label: "Configuration",
              key: "Configuration",
            },
          ],
        }
      : null,
    subscribePpaBilling ||
    subscribeCarbonManagement ||
    subscribeAiPvDiagnosis ||
    subscribeAiBess
      ? {
          label: (
            <Flex
              align="center"
              justify="start"
              style={{
                paddingInline: 4,
                height: header_height,
              }}
            >
              <AppstoreOutlined style={{ marginRight: 8 }} />
              Features
            </Flex>
          ),
          key: "Features",
          children: [
            subscribePpaBilling
              ? {
                  label: "PPA Billing",
                  key: "PPABilling",
                }
              : null,
            subscribeCarbonManagement
              ? {
                  label: "Carbon Management",
                  key: "CarbonManagement",
                }
              : null,
            subscribeAiPvDiagnosis
              ? {
                  label: "AI-PV Diagnosis",
                  key: "AIPVDiagnosis",
                }
              : null,
            subscribeAiBess
              ? {
                  label: "AI-BESS",
                  key: "AIBESS",
                }
              : null,
          ],
        }
      : null,
  ];

  return [NavMenu, header_height];
}

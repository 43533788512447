// style imports
import "../../styles/SiteList/SiteListPage.css";

/**
 * Component: Figure to display summary on total number sites with different status
 */
export function _SiteTypeSummary({ normal, warning, offline, error }) {
  return (
    <>
      <div className="flexbox-container-col">
        <div>{`Normal`}</div>
        <div>{`Warning`}</div>
        <div>{`Error`}</div>
        <div>{`Offline`}</div>
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <div>{`:`}</div>
        <div>{`:`}</div>
        <div>{`:`}</div>
        <div>{`:`}</div>
      </div>
      <div
        className="flexbox-container-col"
        style={{ flexShrink: "0", flexGrow: "0" }}
      >
        <div>{`${normal} site(s)`}</div>
        <div>{`${warning} site(s)`}</div>
        <div>{`${error} site(s)`}</div>
        <div>{`${offline} site(s)`}</div>
      </div>
    </>
  );
}

// library imports
import { useState, useEffect, useContext, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Layout,
  Flex,
  Card,
  Popover,
  Avatar,
  Menu,
  Button,
  Row,
  Col,
  theme,
  Skeleton,
} from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
// context imports
import { IndexContext } from "../context/IndexContext";
// hook imports
import useNavMenu from "./NavMenu";
import useAuthCheck from "../hooks/useAuthCheck";
import useSiteSelection from "../hooks/useSiteSelection";
import useSiteSubscription from "../hooks/useSiteSubscription";
import useHasInverter from "../hooks/useHasInverter";
import useHasWeatherStation from "../hooks/useHasWeatherStation";
import useHasInverterSelected from "../hooks/useHasInverterSelected";
import useHasWeatherStationSelected from "../hooks/useHasWeatherStationSelected";
// asset imports
import reveaiLightTheme from "../assets/reveai_light_theme.png";
import reveaiDarkTheme from "../assets/reveai_dark_theme.png";
// component imports
import ProfileMenu from "./ProfileMenu";
import SiteSelection from "./SiteSelection";

/**
 * TO-DO: when sider menu is collapsed, transition is not smooth (SiteSelection component is shown very fast but content is very slow)
 */
function MainLayout() {
  // create a ref to point to the layout DOM
  const layoutRef = useRef();

  // load auth checker
  const [authenticationState, getAuthenticationState] = useAuthCheck();

  // load site selection
  const [siteSelected, setSiteSelected, siteList, getSiteSelectionLoading] =
    useSiteSelection();

  // load has inverter
  const [hasInverter, getHasInverter, getHasInverterLoading] = useHasInverter();

  // load has weather station
  const [hasWeatherStation, getHasWeatherStation, getHasWeatherStationLoading] =
    useHasWeatherStation();

  // load has inverter
  const [
    hasInverterSelected,
    getHasInverterSelected,
    getHasInverterSelectedLoading,
  ] = useHasInverterSelected();

  // load has weather station
  const [
    hasWeatherStationSelected,
    getHasWeatherStationSelected,
    getHasWeatherStationSelectedLoading,
  ] = useHasWeatherStationSelected();

  // load site subscription
  const [
    getFeatureSubscriptionLoading,
    subscribePpaBilling,
    subscribeAiPvDiagnosis,
    subscribeCarbonManagement,
    subscribeAiBess,
    getFeatureSubscription,
  ] = useSiteSubscription();

  // get nav menu, pass necessary arguments to useNavMenu function so that NavMenu will be changed according to site subscription
  const [NavMenu, header_height] = useNavMenu({
    authenticationState: authenticationState,
    subscribePpaBilling: subscribePpaBilling,
    subscribeAiPvDiagnosis: subscribeAiPvDiagnosis,
    subscribeCarbonManagement: subscribeCarbonManagement,
    subscribeAiBess: subscribeAiBess,
  });

  // use context
  const index_context = useContext(IndexContext);

  // get the token of current theme
  const { token } = theme.useToken();

  // for navigation
  const navigate = useNavigate();
  const location = useLocation();
  // for top menu collapse to sidebar
  const [collapsed, setCollapsed] = useState(true);
  // for getting current selected menu key
  const [menuSelectedKey, setMenuSelectedKey] = useState("Dashboard");

  // styles
  const headerStyle = {
    textAlign: "center",
    height: header_height,
    paddingInline: 0,
    lineHeight: header_height,
    position: "sticky",
    top: 0,
    zIndex: 1,
  };

  const contentStyle = {
    height: "calc(100% -" + header_height + " )",
    width: "100vw",
    minWidth: "100vw",
    overflow: "auto",
    padding: 10,
    marginTop: collapsed && !location.pathname.includes("Management") ? 40 : 0, // this margin is for site selection
  };

  const layoutStyle = {
    overflow: "hidden",
    padding: 0,
    margin: 0,
    width: "100%",
    height: "100vh",
  };

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setMenuSelectedKey(path.charAt(0).toUpperCase() + path.slice(1));
  }, [location]);

  useEffect(() => {
    if (siteSelected) {
      getFeatureSubscription({ siteSelected: siteSelected });
      getHasInverter({ siteSelected: siteSelected });
      getHasWeatherStation({ siteSelected: siteSelected });
      getHasInverterSelected({ siteSelected: siteSelected });
      getHasWeatherStationSelected({ siteSelected: siteSelected });
    }
  }, [siteSelected]);

  return (
    <Layout style={layoutStyle} ref={layoutRef}>
      <Layout.Header style={headerStyle}>
        <Card
          style={{
            height: "100%",
            width: "100%",
            borderRadius: 0,
            margin: 0,
          }}
          styles={{
            body: {
              height: "100%",
              width: "100%",
              borderRadius: 0,
              paddingBlock: 0,
              paddingLeft: 12,
            },
          }}
          bordered={false}
        >
          <Row style={{ height: "100%" }}>
            <Col xl={0} flex="52px">
              <Flex align="center" style={{ height: "100%", width: "100%" }}>
                <Button
                  icon={<UnorderedListOutlined />}
                  type="text"
                  size="large"
                  onClick={() => setCollapsed(!collapsed)}
                />
              </Flex>
            </Col>
            <Col flex="auto" style={{ height: "100%", paddingLeft: 0 }}>
              <Flex
                justify="space-between"
                align="center"
                style={{ height: "100%", width: "100%" }}
              >
                <img
                  src={
                    index_context.isLightTheme
                      ? reveaiLightTheme
                      : reveaiDarkTheme
                  }
                  alt="Reveai"
                  style={{
                    height: "20px",
                  }}
                />
                <Row style={{ height: "100%" }}>
                  <Col
                    style={{ height: "100%" }}
                    xs={0}
                    sm={0}
                    md={0}
                    lg={0}
                    xl={24}
                  >
                    <Menu
                      style={{
                        height: "100%",
                        width: 1200,
                        borderBottom: 0,
                      }}
                      mode="horizontal"
                      items={NavMenu}
                      onClick={(item) => {
                        // check login user before navigate
                        getAuthenticationState({});

                        let path = "";
                        item.keyPath.reverse().map((value) => {
                          path = path + "/" + value;
                        });
                        navigate(path);
                      }}
                      onSelect={(item) => {
                        setMenuSelectedKey(item.key);
                      }}
                      selectedKeys={menuSelectedKey}
                    ></Menu>
                  </Col>
                </Row>
                <Popover
                  placement="bottom"
                  contentStyle={{ marginTop: "50px" }}
                  content={<ProfileMenu />}
                  arrow={false}
                  trigger="hover"
                  overlayInnerStyle={{
                    borderRadius: 0,
                    marginTop: 10,
                    padding: 0,
                  }}
                >
                  <Avatar style={{ backgroundColor: token["blue-6"] }}>
                    DM
                  </Avatar>
                </Popover>
              </Flex>
            </Col>
          </Row>
          <Row
            style={{
              display:
                !collapsed || location.pathname.includes("Management") // hide site selection when collapsed or when route contains 'Management'
                  ? "none"
                  : "block",
              paddingLeft: !collapsed ? 310 : 0,
            }}
          >
            <SiteSelection
              siteSelected={siteSelected}
              setSiteSelected={setSiteSelected}
              siteList={siteList}
              loading={getSiteSelectionLoading}
              menuSelectedKey={menuSelectedKey}
            />
          </Row>
        </Card>
      </Layout.Header>
      <Layout>
        <Layout.Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="300"
          collapsible
          trigger={null}
          collapsed={collapsed}
          onBreakpoint={() => setCollapsed(true)}
        >
          <Menu
            mode="inline"
            inlineIndent={32}
            style={{
              height: "100%",
            }}
            items={NavMenu}
            onClick={(item) => {
              // check login user before navigate
              getAuthenticationState({});
              setCollapsed(true);

              let path = "";
              item.keyPath.reverse().map((value) => {
                path = path + "/" + value;
              });
              navigate(path);
            }}
            onSelect={(item) => setMenuSelectedKey(item.key)}
            selectedKeys={menuSelectedKey}
          ></Menu>
        </Layout.Sider>
        <Layout.Content style={contentStyle}>
          {/* add layoutRef into the IndexContext */}
          {collapsed === true ? (
            <IndexContext.Provider
              value={{
                ...index_context,
                layoutRef: layoutRef,
                authenticationState: authenticationState,

                siteSelected: siteSelected,
                setSiteSelected: setSiteSelected,

                subscribePpaBilling: subscribePpaBilling,
                subscribeCarbonManagement: subscribeCarbonManagement,
                subscribeAiPvDiagnosis: subscribeAiPvDiagnosis,
                subscribeAiBess: subscribeAiBess,
                getFeatureSubscriptionLoading: getFeatureSubscriptionLoading,

                hasInverter: hasInverter,
                getHasInverterLoading: getHasInverterLoading,
                getHasInverter: getHasInverter,

                hasInverterSelected: hasInverterSelected,
                getHasInverterSelectedLoading: getHasInverterSelectedLoading,
                getHasInverterSelected: getHasInverterSelected,

                hasWeatherStation: hasWeatherStation,
                getHasWeatherStationLoading: getHasWeatherStationLoading,
                getHasWeatherStation: getHasWeatherStation,

                hasWeatherStationSelected: hasWeatherStationSelected,
                getHasWeatherStationSelectedLoading:
                  getHasWeatherStationSelectedLoading,
                getHasWeatherStationSelected: getHasWeatherStationSelected,
              }}
            >
              <Outlet></Outlet>
            </IndexContext.Provider>
          ) : (
            <Skeleton active={true} paragraph={{ rows: 5 }} /> // when sider menu is shown, display loading
          )}
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
